import React, { Component } from 'react';
import ManpowerNavBar from './ManpowerNavBar';
import ManpowerFooterComponent from './ManpowerFooterComponent';


class ManpowerAboutUsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>
        <div>
          <meta charSet="utf-8" />
          <title>CONSULT - Consultancy Website Template</title>
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <meta content="Free HTML Templates" name="keywords" />
          <meta content="Free HTML Templates" name="description" />
          {/* Favicon */}
          <link href="img/favicon.ico" rel="icon" />
          {/* Google Web Fonts */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" />
          {/* Icon Font Stylesheet */}
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
          <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
          {/* Libraries Stylesheet */}
          <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
          {/* Customized Bootstrap Stylesheet */}
          <link href="css/bootstrap.min.css" rel="stylesheet" />
          {/* Template Stylesheet */}
          <link href="css/style.css" rel="stylesheet" />
          <div>
            {/* Topbar Start */}
           <ManpowerNavBar></ManpowerNavBar>
            {/* Navbar End */}
            {/* Page Header Start */}
            <div className="container-fluid bg-dark p-5">
              <div className="row">
                <div className="col-12 text-center">
                  <h1 className="display-4 text-white">About Us</h1>
                  <a href>Home</a>
                  <i className="far fa-square text-primary px-2" />
                  <a href>About</a>
                </div>
              </div>
            </div>
            {/* Page Header End */}
            {/* About Start */}
            <div className="container-fluid bg-secondary p-0">
              <div className="row g-0">
                <div className="col-lg-6 py-6 px-5"><br />
                  <h1 className="display-5 mb-4">Welcome To Providence International:<span className="text-primary">The Biggest Manpower Suppliers.</span></h1><br />
                  <p className="mb-4">
                    Welcome to Providence International,<span style={{ color: 'black' }}> Pune's Biggest Manpower Suppliers</span> and your trusted partner in delivering unparalleled manpower solutions for a wide range of events in the hospitality industry.We take pride in having conducted some of the largest events in the industry, including the<span style={{ color: 'black' }}> record-breaking event of hosting 10,000 people at the prestigious Hyatt Hotel.</span> Our ability to manage such colossal gatherings reflects our commitment to scale, precision, and a customer-centric approach.
                  </p></div>
                <div className="col-lg-6" style={{ marginTop: '20px' }}>
                  <div className="h-100 d-flex flex-column justify-content-center p-5" style={{ backgroundColor: 'rgba(225, 72, 108, 0.781)' }}>
                    <div className="d-flex text-white mb-5">
                      <div className="ps-4">
                        <h3>✨ Excellence in Catering Manpower:</h3>
                        <p className="mb-0"> </p><ul className="mb-0" /><li> A dedicated team of skilled professionals.</li>
                        <li> Specialized in providing manpower to hotels.</li>
                        <li>Ensuring seamless execution of events.</li> <p />
                      </div>
                    </div>
                    <div className="d-flex text-white mb-5">
                      <div className="ps-4">
                        <h3>🍽️ Culinary Mastery:</h3>
                        <p className="mb-0"> </p><ul className="mb-0" /><li> Expertise in crafting exquisite culinary experiences.
                        </li><li>Precision in handling diverse culinary demands.</li><li>Elevating the gastronomic aspect of every occasion.</li><p />
                      </div>
                    </div>
                    <div className="d-flex text-white">
                      <div className="ps-4">
                        <h3>🤝 Tailored Hospitality Solutions:</h3>
                        <p className="mb-0"> </p><ul className="mb-0" /><li> Committed to meeting the unique needs of each venue.
                        </li><li>Providing top-notch service with attention to detail.</li><li>Flexible and adaptable to various event requirements.</li><p />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* About End */}
            {/* Team Start */}
            <div className="container-fluid py-6 px-5">
              <div className="text-center mx-auto mb-5" style={{ maxWidth: '600px' }}>
                <h1 className="display-5 mb-0">Why Choose Us!!!</h1>
                <hr className="w-25 mx-auto bg-primary" />
              </div>
              <div className="row g-5">
                <div className="col-lg-4">
                  <div className="row g-5">
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>
                        🌐 Skilled Manpower: </h3>
                      <p className="mb-0" style={{color:'black'}}>Our team comprises highly trained professionals, each excelling in their respective roles, ensuring the success of every event.</p>
                    </div>
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>⚖️ Scalability: </h3>
                      <p className="mb-0" style={{color:'black'}}>Whether your event is intimate or grand, Providence International has the resources and expertise to scale our services according to your needs.
                      </p></div>
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>🔒 Reliability:</h3>
                      <p className="mb-0" style={{color:'black'}}>We understand the importance of reliability in event management. Our track record speaks for itself, with countless successful events delivered with precision./p&gt;
                      </p></div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="d-block bg-primary h-100 text-center">
                    <img className="img-fluid" src="allimage/CEO.webp" style={{ paddingTop: '20px' }} alt="" />
                    <div className="p-4">
                      <p className="text-white mb-4"> At Providence International, we stand out as your premier catering partner for several compelling reasons. Our commitment to culinary excellence is at the forefront of our service, with a team of skilled professionals dedicated to crafting delectable and innovative dishes.
                        From weddings to corporate gatherings, we bring a perfect blend of creativity and culinary expertise to every occasion, promising a delightful and memorable dining experience for you and your guests.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="row g-5">
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>✨ Innovation:</h3>
                      <p className="mb-0" style={{color:'black'}}>In an ever-evolving industry, we stay ahead by embracing innovation. Our approach is dynamic, ensuring that your event is not just successful but also memorable.
                      </p>
                    </div>
                    <div className="col-12">
                      <div className=" d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>🏆 Proudest Achievement:</h3>
                      <p className="mb-0" style={{color:'black'}}>The Hyatt Hotel event, hosting a staggering 10,000 people, stands as a testament to our capabilities and commitment. It exemplifies the heights we can reach when it comes to delivering top-notch manpower solutions.</p>
                    </div>
                    <div className="col-12">
                      <div className="d-flex align-items-center justify-content-center mb-3" style={{ width: '60px', height: '60px' }}>
                      </div>
                      <h3>🌟 Exceptional Quality:</h3>
                      <p className="mb-0" style={{color:'black'}}>We pride ourselves on delivering exceptional quality services, ensuring that each event is executed with precision, attention to detail, and a commitment to excellence.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Features Start */}
          </div>
          {/* Team End */}
          {/* Footer Start */}
          <ManpowerFooterComponent></ManpowerFooterComponent>
          {/* Footer End */}
          {/* Back to Top */}
          <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up" /></a>
          {/* JavaScript Libraries */}
          {/* Template Javascript */}
        </div>
      </div>
    );
  }
}

export default ManpowerAboutUsComponent;
