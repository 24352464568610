import React, { Component } from 'react';
import NavBar from './NavBar';

class TeamComponent  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
<div>
<NavBar></NavBar>
<div>
        
        {/* Spinner End */}
        {/* Navbar start */}
        
        {/* Navbar End */}
        {/* Modal Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Search End */}
        {/* Hero Start */}
       
        {/* Hero End */}
        {/* Team Start */}<style dangerouslySetInnerHTML={{__html: "\n    .team-item img {\n        width: 100%; /* Make sure the image takes the full width of its container */\n        height: 600px; /* Set a fixed height for all images */\n        object-fit: cover; /* Preserve aspect ratio while covering the container */\n    }\n" }} />
        <div className="container-fluid team py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Team</small>
              <h1 className="display-5 mb-5">We have experienced chef Team</h1>
            </div>
            <div className="row g-4">
            <div class="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.1s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.27.25 PM.jpg" style={{height:'300PX', width:'250px'}} />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Shankar Devendra</h4>
                    <p className="text-white mb-0">Special South Indian Chef in J.W. Spice Kitchen</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.57.06 PM.jpg"  style={{height:'300PX'}}  alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Dhan Singh </h4>
                    <p className="text-white mb-0">Indian Specialist Chef</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.5s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.57.53 PM.jpeg" style={{height:'300PX'}}   alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Dinesh Dhurandhare</h4>
                    <p className="text-white mb-0">Multi Cuisine Chef</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" >
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.57.26 PM.jpeg" style={{height:'300px', width:'250px'}}   alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Narendra Chauhan </h4>
                    <p className="text-white mb-0">Tandoori Master Chef</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.58.03 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Rathore Chef </h4>
                    <p className="text-white mb-0">Halwai Section Master</p>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.58.13 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Sadaf Qadri </h4>
                    <p className="text-white mb-0">Conti sp</p>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.58.25 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Mukesh Singh</h4>
                    <p className="text-white mb-0">Tandoor CDP</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.58.37 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Nandu Singh</h4>
                    <p className="text-white mb-0">Tandoor Special</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.58.48 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Vishal Wankade </h4>
                    <p className="text-white mb-0">South Indian Specialist</p>
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.59.01 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Omkar </h4>
                    <p className="text-white mb-0">Conti Special</p>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.59.16 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Indian Section Chef </h4>
                    <p className="text-white mb-0">Hyatt Pune</p>
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.7s">
                <div className="event-img position-relative">
                  <img className="img-fluid rounded w-100" src="allimage/WhatsApp Image 2024-01-13 at 2.59.30 PM.jpeg" alt="" style={{height:'300PX'}}/>
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                    <h4 className="text-primary">Deepak Singh </h4>
                    <p className="text-white mb-0">Tandoori Special in hyatt Pune</p>
                  </div>
                 
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Team End */}
        {/* Footer Start */}
        
        {/* Footer End */}
        {/* Copyright Start */}
       
        {/* Copyright End */}
        {/* Back to Top */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
      </div>
    );
  }
}

export default TeamComponent ;