import React, { Component } from 'react';
import ManpowerFooterComponent from './ManpowerFooterComponent';
import ManpowerNavBar from './ManpowerNavBar';

class ManpowerServicesComponent  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>

<div>
        <meta charSet="utf-8" />
        <title>CONSULT - Consultancy Website Template</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content="Free HTML Templates" name="keywords" />
        <meta content="Free HTML Templates" name="description" />
        {/* Favicon */}
        <link href="img/favicon.ico" rel="icon" />
        {/* Google Web Fonts */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" /> 
        {/* Icon Font Stylesheet */}
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
        {/* Libraries Stylesheet */}
        <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        {/* Customized Bootstrap Stylesheet */}
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        {/* Template Stylesheet */}
        <link href="css/style.css" rel="stylesheet" />
        <div>
          {/* Topbar Start */}
         <ManpowerNavBar></ManpowerNavBar>
          {/* Navbar End */}
          {/* Page Header Start */}
          <div className="container-fluid bg-dark p-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="display-4 text-white">Services</h1>
                <a href>Home</a>
                <i className="far fa-square text-primary px-2" />
                <a href>Services</a>
              </div>
            </div>
          </div>
          {/* Page Header End */}
          {/* Services Start */}
          <div className="container-fluid py-6 px-5">
            <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
              <h1 className="display-5 mb-0">What We Offer</h1>
              <hr className="w-25 mx-auto bg-primary" />
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center  justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-user-tie fa-2x" />
                  </div>
                  <h3 className="mb-3">
                    Service Staff:</h3>
                  <p className="mb-0" /><ul><li>Skilled and well-trained professionals</li><li>Professional waiters and waitresses</li><li>Efficient and courteous service for various events</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-utensils fa-2x" />
                  </div>
                  <h3 className="mb-3">Kitchen Support Team:</h3>
                  <p className="mb-0" /><ul><li>Skilled kitchen support staff</li><li>Ensuring smooth operations in the kitchen</li><li>Collaborating with chefs for seamless event catering</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fas fa-coffee fa-2x" />
                  </div>
                  <h3 className="mb-3">Chefs for All Occasions:</h3>
                  <p className="mb-0" /><ul><li>Diverse team of chefs specializing in different cuisines</li><li>Customized menus for events</li><li>Expertise in catering for weddings, corporate gatherings, and more</li></ul><p /><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-broom fa-2x" />
                  </div>
                  <h3 className="mb-3">Housekeeping Services:</h3>
                  <p className="mb-0" /><ul><li>Professional housekeeping staff</li><li>Maintaining cleanliness and hygiene at event venues</li><li>Pre and post-event cleaning services</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-chalkboard-teacher fa-2x" />
                  </div>
                  <h3 className="mb-3">Supervisors</h3>
                  <p className="mb-0" /><ul><li>Leadership Excellence</li><li>Team Coordination</li><li>Client Satisfaction</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fas fa-shield-alt fa-2x" />
                  </div>
                  <h3 className="mb-3">Security Services:</h3>
                  <p className="mb-0" /><ul><li>Trained Security Personnel</li><li>Crowd Control</li><li>Emergency Response</li></ul><p />
                </div>
              </div>
            </div>
          </div>
          {/* Services End */}
          {/* Quote Start */}
          <div className="container-fluid bg-secondary px-0">
            <div className="row g-0">
              <div className="col-lg-6 py-6 px-5 d-flex align-items-center mx-auto">
                <div>
                  <h1 className="display-5 mb-4">Share Your Thought With Us!!</h1>
                  <form>
                    <div className="row gx-3">
                      <div className="col-6">
                        <div className="form-floating">
                          <input type="text" className="form-control" id="form-floating-1" placeholder="John Doe" />
                          <label htmlFor="form-floating-1">Full Name</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control" id="form-floating-2" placeholder="name@example.com" />
                          <label htmlFor="form-floating-2">Email address</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input type="thought" className="form-control" id="form-floating-3" placeholder="Thought" />
                          <label htmlFor="form-floating-3">Your Thoughts</label>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <button className="btn btn-primary w-100" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Quote End */}
        {/* Footer Start */}
       <ManpowerFooterComponent></ManpowerFooterComponent>
        {/* Footer End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up" /></a>
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
   
       </div>
    );
  }
}
export default ManpowerServicesComponent ;
