import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';

class BlogComponent  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>
        <NavBar></NavBar>
        
        {/* Navbar End */}
        {/* Modal Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="false">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Search End */}
        {/* Hero Start */}
        <div className="container-fluid bg-light py-6 my-6 mt-0">
          <div className="container text-center animated bounceInDown">
            <h1 className="display-1 mb-4">Our Blog</h1>
           
          </div>
        </div>
        {/* Hero End */}
        {/* Blog Start */}
        <div className="container-fluid blog py-6"  style={{ marginTop: '-17%' }}>
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Blog</small>
              <h1 className="display-5 mb-5">Be First Who Read News</h1>
            </div>
            <div className="row gx-4 justify-content-center">
              <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s">
                <div className="blog-item">
                  <div className="overflow-hidden rounded">
                    <img src="allimage/blog-1.jpg" className="img-fluid w-100" alt="" />
                  </div>
                  <div className="blog-content mx-4 d-flex rounded bg-light">
                    <div className="text-dark bg-primary rounded-start">
                      <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                        <p className="fw-bold mb-0">16</p>
                        <p className="fw-bold mb-0">Sep</p>
                      </div>
                    </div>
                    <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.3s">
                <div className="blog-item">
                  <div className="overflow-hidden rounded">
                    <img src="allimage/blog-2.jpg" className="img-fluid w-100" alt="" />
                  </div>
                  <div className="blog-content mx-4 d-flex rounded bg-light">
                    <div className="text-dark bg-primary rounded-start">
                      <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                        <p className="fw-bold mb-0">16</p>
                        <p className="fw-bold mb-0">Sep</p>
                      </div>
                    </div>
                    <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.5s">
                <div className="blog-item">
                  <div className="overflow-hidden rounded">
                    <img src="allimage/blog-3.jpg" className="img-fluid w-100" alt="" />
                  </div>
                  <div className="blog-content mx-4 d-flex rounded bg-light">
                    <div className="text-dark bg-primary rounded-start">
                      <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                        <p className="fw-bold mb-0">16</p>
                        <p className="fw-bold mb-0">Sep</p>
                      </div>
                    </div>
                    <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}
     <FooterComponent></FooterComponent>
        {/* Footer End */}
        {/* Copyright Start */}
      
        {/* Copyright End */}
        {/* Back to Top */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
    );
  }
}

export default BlogComponent ;
