import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonialSliderSettings } from './slickSettings';

class MenuComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      testimonialSliderSettings: {
        dots: true,
        infinite: true,
        speed: 2000,
        slidesToShow: 2, // Adjust the number of testimonials you want to show in one row
        slidesToScroll: 2,

      },
      isFullScreen: false

    }
    this.handleImageClick = this.handleImageClick.bind(this);

  }
  handleImageClick() {
    const elem = document.getElementById('testimonialImage');
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      this.setState({ isFullScreen: true });
    }
  }
  handleImageClick1() {
    const elem = document.getElementById('testimonialImage');
    if (elem) {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullscreen) {
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        elem.msRequestFullscreen();
      }
      this.setState({ isFullScreen: true });
    }
  }
  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    const { isFullScreen } = this.state;

    return (
      <div>
      
    <NavBar/>
    
        {/* Navbar End */}
        {/* Modal Search Start */}
            {/* Navbar End */}
        {/* Modal Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Search End */}
        {/* Hero Start */}
        <div className="container-fluid bg-light py-6 my-6 mt-0">
          <div className="container text-center animated bounceInDown">
            <h1 className="display-1 mb-4">Menu</h1>
          
          </div>
        </div>
        {/* Hero End */}
        {/* Menu Start */}<style dangerouslySetInnerHTML={{__html: "\n  .menu-item img {\n    width: 50px; /* Set your desired width */\n    height: 50px; /* Set your desired height */\n    object-fit: cover; /* Maintain aspect ratio */\n  }\n" }} />
        <div className="container-fluid menu py-6" style={{ marginTop: '-17%' }}>
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Menu</small>
              <h1 className="display-5 mb-5">Our Most Popular Menu</h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill active" data-bs-toggle="pill" href="#tab-6">
                    <span className="text-dark" style={{width: '150px'}}>Welcome Drinks</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-7">
                    <span className="text-dark" style={{width: '150px'}}>Main Course</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-8">
                    <span className="text-dark" style={{width: '150px'}}>Mocktails</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-9">
                    <span className="text-dark" style={{width: '150px'}}>Soups</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-10">
                    <span className="text-dark" style={{width: '150px'}}>Our Special</span>
                  </a>
                </li>
              </ul>
              <style dangerouslySetInnerHTML={{__html: "\n                        .menu-item img {\n                          width: 50px; /* Set your desired width */\n                          height: 50px; /* Set your desired height */\n                          object-fit: cover; /* Maintain aspect ratio */\n                        }\n                      " }} />
              <div className="tab-content">
                <div id="tab-6" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.1s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/Guava Fruit Juice Recipe.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Delightful &amp; Refreshing Guava </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.2s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/Coconut-Lime-Cocktail (1).jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Coconut &amp; Lime Sparkler</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.3s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/orangehoney.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Orange Honey Lassi</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.4s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cocout thandai.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Coconut Thandai</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.5s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/aam panna.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Aam panna, Kairi panna</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.6s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mangococonut lassi.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mango Coconut Lassi</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.7s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/watermelon mojito.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Watermelon Mojito</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.8s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/pineappleice.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Pineaaple ice cube, Strawberry mint</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-7" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/rotis.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Rotis</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/rice.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special Rice</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/chinese.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Veg Chinese</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/tandoori chicken.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Tandoor Chicken</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/biryani.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Biryani</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/indian.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mutton.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/indian chicken.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian (Chicken)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-8" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mindful.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>The mindful Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cocounr mocktail.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Orange, Pineapple &amp; coconut mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/party.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Party Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/jaljeera.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Jaljeera (Indian Special)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/summer.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Refreshing Summer Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/purple deli.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Purple Delight mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/watermelon.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div c lass="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Watermelon mint mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/lemonde.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian Style Lemonde</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-9" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/specialsoup.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Providence International Special Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mixveg.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mix Veg </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/tomatobased.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Tomato Based Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/asian.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Roasted red and black pepper Tomato with asian cheese soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/creamy.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Creamy Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cornleek.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Corn and Leek Bisque</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/curried.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Curried Pumpkin Coconut Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mushroom.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Creamy Mushroom Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-10" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/international.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Providence International Special Non-Veg Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/bbqcontin.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special BBQ (Continental)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/bbqindian.webp" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special BBQ(Indian)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/wings.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>BBQ Chicken Wings</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/muttonsteak'.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton Steak</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/leg.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Chicken Leg Tandoori</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/ran.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton Ran</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/fish.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Fish Tandoori</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-6" ><img
          id="testimonialImage"
          src="allimage/WhatsApp Image 2024-03-24 at 13.42.47_52fc5060.jpg"
          className="testimonial-item rounded bg-light"
          alt=""
          style={{ height: '500px', width: '450px' }}
          onClick={this.handleImageClick}  /> </div> </div>


                        <div><div className="d-flex mb-6" ><img
          id="testimonialImage"
          src="allimage/WhatsApp Image 2024-03-30 at 10.27.11_7ec080e5.jpg"
          className="testimonial-item rounded bg-light"
          alt=""
          style={{ height: '500px', width: '450px' }}
          onClick={this.handleImageClick1}/></div> </div>
                     </Slider>


        {/* Menu End */}
       <FooterComponent/>
        {/* Footer End */}
        {/* Copyright Start */}
       
        {/* Copyright End */}
        {/* Back to Top */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
  
    );
  }
}

export default MenuComponent;
