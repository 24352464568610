import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';
import BanquetFooterComponent from './BanquetFooterComponent';
import ManpowerNavBar from './ManpowerNavBar';
import axios from 'axios';
import emailjs from 'emailjs-com';

class MainHomeComponent extends Component {
  state = {
    name: '',
    email: '',
    service: '',
  
    mobileNumber: '',
    specialRequest: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    const { alertMessage } = this.state;
    return (
      <div>
        <div>
          <meta charSet="utf-8" />
          <title>Restoran - Bootstrap Restaurant Template</title>
          <meta content="width=device-width, initial-scale=1.0" name="viewport" />
          <meta content name="keywords" />
          <meta content name="description" />
          {/* Favicon */}
          <link href="img/favicon.ico" rel="icon" />
          {/* Google Web Fonts */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
          <link href="https://fonts.googleapis.com/css2?family=Heebo:wght@400;500;600&family=Nunito:wght@600;700;800&family=Pacifico&display=swap" rel="stylesheet" />
          {/* Icon Font Stylesheet */}
          <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
          <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
          {/* Libraries Stylesheet */}
          <link href="lib/animate/animate.min.css" rel="stylesheet" />
          <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
          <link href="lib/tempusdominus/css/tempusdominus-bootstrap-4.min.css" rel="stylesheet" />
          {/* Customized Bootstrap Stylesheet */}
          <link href="css/bootstrap.min.css" rel="stylesheet" />
          {/* Template Stylesheet */}
          <link href="css/style.css" rel="stylesheet" />
          <div className="container-xxl bg-white p-0">
            {/* Spinner Start */}
            {/* <div id="spinner" className="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center">
              <div className="spinner-border text-primary" style={{ width: '3rem', height: '3rem' }} role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div> */}
            
            {/* Spinner End */}
            {/* Navbar & Hero Start */}
            <div className="container-xxl position-relative p-0">
              <nav className="navbar navbar-expand-lg navbar-dark bg-dark px-4 px-lg-5 py-3 py-lg-0">
                <a href className="navbar-brand p-0">
                <img src="/allimage/providene-logo-with-name copy.png"  style={{width:'200px'}}  alt="" />
                  {/* <img src="img/logo.png" alt="Logo"> */}
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                  <span className="fa fa-bars" />
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                  <div className="navbar-nav ms-auto py-0 pe-4">
                    <a href="/cetrin-home" className="nav-item nav-link active">CATERING SERVICES</a>
                    <a href="/banquet-home" className="nav-item nav-link">BANQUET HALL</a>
                    <a href="/manpower-home"className="nav-item nav-link">HOSPITALITY SERVICES</a>
                    <a href="/productionHouse-home"className="nav-item nav-link">PRODUCTION HOUSE </a>
                    <div className="nav-item dropdown">
                    </div>
                  </div>
                </div></nav>
              <div className="container-xxl py-5 bg-dark hero-header mb-5">
                <div className="container my-5 py-5">
                  <div className="row align-items-center g-5">
                    <div className="col-lg-6 text-center text-lg-start">
                      <h1 className="display-3 text-white animated slideInLeft">Providence International<br />Pvr.Ltd
                      </h1>
                      <p className="text-white animated slideInLeft mb-4 pb-2">
                        "Indulge in culinary delight at our catering service, where passion meets perfection in
                        our elegant banquet hall. With expert manpower and customizable menus, we craft
                        unforgettable moments for every event. From intimate gatherings to grand celebrations,
                        savor the essence of fine dining with us."</p>
                      <p />
                     
                    </div>
                    <div className="col-lg-6 text-center text-lg-end overflow-hidden">
                      <img className="img-fluid" src="unn/img/hero.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Navbar & Hero End */}
            {/* Service Start */}
            <div className="container-xxl py-5">
              <div className="container">
                <div className="row g-4">
                  <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.1s">
                  <a  href="/banquet-home">

                    <div className="service-item rounded pt-3">
                      <div className="p-4">
                        <img className="img-fluid" src="allimage/408189767_7119952374736246_1130948630410623605_n.jpg" alt=""  />
                        <h3 className="text-white">Best Banquet Hall</h3>
                        <p className="text-white text-uppercase mb-0"></p>
                      </div>
                    </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.3s">
                  <a href="/cetrin-home">
                    <div className="service-item rounded pt-3">
                      <div className="p-4">
                        <img className="img-fluid" src="allimage/best-catering.jpg" alt="" style={{height:'250px'}}/>
                        <h3 className="text-white">Catering Service</h3>
                        <p className="text-white text-uppercase mb-0"></p>

                      </div>
                    </div>
                    </a>
                  </div>
                  <div className="col-lg-4 col-sm-6 wow fadeInUp" data-wow-delay="0.5s">
                  <a href="/manpower-home">
                    <div className="service-item rounded pt-3">
                      <div className="p-4">
                        <img className="img-fluid" src="allimage/manpowerprovider.jpeg" alt="" />
                        <h3 className="text-white">Manpower Provide</h3>
                        <p className="text-white text-uppercase mb-0"></p>
                      </div>
                    </div>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            {/* Service End */}
            {/* About Start */}
            <div className="container-xxl py-5">
              <div className="container">
                <div className="row g-5 align-items-center">
                  <div className="col-lg-6">
                    <div className="row g-3">
                      <div className="col-6 text-start">
                        <img className="img-fluid rounded w-1000 wow zoomIn" style={{ width: '1500px', height: '600px', paddingLeft:'55px' }} src="allimage/CEO.webp" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <h5 className="section-title ff-secondary text-start text-primary fw-normal">About Us</h5>
                    <h1 className="mb-4">Welcome to <i className="fa fa-utensils text-primary me-2" />Providence
                      International</h1>
                    <p className="mb-4"  style={{color:'black'}}>"As the founder of our esteemed catering service, banquet hall, and manpower
                      supply, I envisioned a space where every celebration is elevated to an unforgettable
                      experience. With a passion for impeccable service and culinary excellence, we strive to
                      exceed expectations at every event. Our dedication to quality extends to every aspect, from
                      the carefully curated menus to the seamless execution of events. With our commitment to
                      professionalism and hospitality, we create cherished memories that last a lifetime."</p>
                    <div className="row g-4 mb-4">
                      <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                          <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">15
                          </h1>
                          <div className="ps-4">
                            <p className="mb-0">Years of</p>
                            <h6 className="text-uppercase mb-0">Experience</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="d-flex align-items-center border-start border-5 border-primary px-3">
                          <h1 className="flex-shrink-0 display-5 text-primary mb-0" data-toggle="counter-up">50
                          </h1>
                          <div className="ps-4">
                            <p className="mb-0">Popular</p>
                            <h6 className="text-uppercase mb-0">Master Chefs</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <a className="btn btn-primary py-3 px-5 mt-2" href>Read More</a>
                  </div>
                </div>
              </div>
            </div>
            {/* About End */}
            {/* Reservation Start */}
            <div>
              <div>
                <div className="container-xxl py-5 px-0 wow fadeInUp" data-wow-delay="0.1s">
                  <div className="row g-0">
                    <div className="col-md-6">
                      <div className="video">
                        <button type="button" className="btn-play" data-bs-toggle="modal" data-src="https://youtu.be/sx37lMY_V6Q?si=6GJ9EMb3EWMxuRMe" data-bs-target="#videoModal">
                          <span />
                        </button>
                      </div>
                    </div>
                    <div className="col-md-6 bg-dark d-flex align-items-center">
                      <div className="p-5 wow fadeInUp" data-wow-delay="0.2s">
                        <h5 className="section-title ff-secondary text-start text-primary fw-normal">Reservation</h5>
                        <h1 className="text-white mb-4">Book A Table Online</h1>
                        <form id="form" onSubmit={this.handleSubmit}>
                          <div className="row g-3">
                            <div className="col-md-6">
                              <div className="form-floating">
                                <input type="text" className="form-control" id="name"  required name="name" placeholder="Your Name" />
                                <label htmlFor="name">Your Name</label>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-floating">
                                <input type="email" className="form-control" htmlFor="email" id="email"  required name="email" placeholder="Your Email" />
                                <label htmlFor="email">Your Email</label>
                              </div>
                            </div>
                            <div className="col-md-6" >
                              <div className="form-floating">
                              
                                <select type="text" name="service" id="service"  required style={{height:'55px',width:'270px'}} >
                                <label for="service" class="form-control" >Service</label>
                                  <option>Select Services</option>
                                  <option>Catering Services</option>
                                  <option>Banquet Hall</option>
                                  <option>Hospitality Services</option>
                                </select>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-floating date" id="date3" data-target-input="nearest">
                               
                                <input type="text" name="mobileNumber" id="mobileNumber"  required className="form-control" placeholder="Mobile Number" style={{color:'black'}} />
                                <label htmlFor="mobie">Mobile</label>
                              </div>
                            </div>

                            <div className="col-12">
                              <div className="form-floating">
                                <textarea className="form-control" placeholder="Special Request" id="message" required name="specialRequest" style={{ height: '100px' }} />
                                <label htmlFor="message">Special Request</label>
                              </div>
                            </div>
                            <div className="col-12">
                              <button   id="button" className="btn btn-primary w-100 py-3" type="submit">Book Now</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {alertMessage && <div className="alert alert-success">{alertMessage}</div>}
              </div>
            </div>
            <div className="modal fade" id="videoModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg">
    <div className="modal-content rounded-0">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        {/* 16:9 aspect ratio */}
        <div className="ratio ratio-16x9">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/sx37lMY_V6Q?si=6GJ9EMb3EWMxuRMe" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>        </div>
      </div>
    </div>
  </div>
</div>

            {/* Reservation Start */}
            {/* Team Start */}
            {/* Testimonial Start */}
            {/* Testimonial End */}
            {/* Footer Start */}
            <div className="container-fluid bg-dark text-light footer pt-5 mt-5 wow fadeIn" data-wow-delay="0.1s">
              <div className="container py-5">
                <div className="row g-4">
                  <div className="col-lg-6 col-md-6">
                    <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Quick Links</h4>
                    <a className="btn btn-link" href="/cetrin-home">Catering Service</a>
                    <a className="btn btn-link" href="/banquet-home">Banquet Hall</a>
                    <a className="btn btn-link" href="/manpower-home">Hospitality Services</a>
                    <a className="btn btn-link" href="/productionHouse-home">Production House</a>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <h4 className="section-title ff-secondary text-start text-primary fw-normal mb-4">Contact</h4>
                    <p className="mb-2"><i className="fa fa-map-marker-alt me-3" />Head Officde No. A55, First Floor,
                      Virvani Plaza, East Street, Camp Pune 411001.</p>
                    <p className="mb-2"><i className="fa fa-phone-alt me-3" />9595264988</p>
                    <p className="mb-2"><i className="fa fa-envelope me-3" />providenceinternational180@gmail.com</p>
                    <div className="d-flex pt-2">
                      <a className="btn btn-outline-light btn-social" href="https://www.facebook.com/profile.php?id=100090355260753&mibextid=ZbWKwL"><i className="fab fa-facebook-f" /></a>
                      <a className="btn btn-outline-light btn-social" href="https://instagram.com/providenceinternational.co?igshid=YmMyMTA2M2Y="><i className="fab fa-instagram" /></a>
                    </div>
                  </div>

                 
                </div>
              </div>
              <div className="container">
                <div className="copyright">
                  <div className="row">
                    <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                      {/* Footer End */}
                      {/* Back to Top */}
                      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top"><i className="bi bi-arrow-up" /></a>
                    </div>
                    {/* JavaScript Libraries */}
                    {/* Template Javascript */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default MainHomeComponent;

