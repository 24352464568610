import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';
class EventComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>
      
    <NavBar/>
                   {/* Navbar End */}
        {/* Modal Search Start */}
      
        {/* Modal Search End */}
        {/* Hero Start */}
        <div className="container-fluid bg-light py-6 my-6 mt-0">
          <div className="container text-center animated bounceInDown">
            <h1 className="display-1 mb-4">Events</h1>
          </div>
        </div>
        {/* Hero End */}
        {/* Events Start */}
        <div className="container-fluid event py-6" style={{ marginTop: '-17%' }}>
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Latest Events</small>
              <h1 className="display-5 mb-5">Our Social &amp; Professional Events Gallery</h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                    <span className="text-dark" style={{width: '150px'}}>All Events</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                <a href="#tab-2" className="d-flex py-2 mx-2 border border-primary bg-light rounded-pill">
                    <span className="text-dark" style={{width: '150px'}}>Wedding</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                    <span className="text-dark" style={{width: '150px'}}>Corporate</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                    <span className="text-dark" style={{width: '150px'}}>Cocktail</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                    <span className="text-dark" style={{width: '150px'}}>Buffet</span>
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a  src="allimage/event-1.jpg" data-lightbox="event-1" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.3s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a  src="allimage/even-3.jpg" data-lightbox="event-2" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.5s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-7.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a  src="allimage/event-7.jpg" data-lightbox="event-3" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.7s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-5.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a  src="allimage/event-5.jpg" data-lightbox="event-4" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="img/event-2.jpg" data-lightbox="event-5" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.3s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-8.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a  src="allimage/event-8.jpg" data-lightbox="event-6" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.5s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a  src="allimage/event-2.jpg" data-lightbox="event-7" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.7s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a  src="allimage/event-5.jpg" data-lightbox="event-17" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="img/event-8.jpg" data-lightbox="event-8" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-5.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="img/event-5.jpg" data-lightbox="event-9" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-3" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-7.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="img/event-7.jpg" data-lightbox="event-10" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-5.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="img/event-5.jpg" data-lightbox="event-11" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-4" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="img/event-2.jpg" data-lightbox="event-12" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-8.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="img/event-3.jpg" data-lightbox="event-13" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-5" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="img/event-4.jpg" data-lightbox="event-14" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/event-6.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="img/event-4.jpg" data-lightbox="event-15" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Events End */}
        {/* Book Us Start */}
        <div className="container-fluid contact py-6 wow bounceInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row g-0">
              <div className="col-1">
                <img src="allimage/background-site.jpg" className="img-fluid h-100 w-100 rounded-start" style={{objectFit: 'cover', opacity: '0.7'}} alt="" />
              </div>
              <div className="col-10">
                <div className="border-bottom border-top border-primary bg-light py-5 px-4">
                  <div className="text-center">
                    <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Book Us</small>
                    <h1 className="display-5 mb-5">Where you want Our Services</h1>
                  </div>
                  <div className="row g-4 form">
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>Select Country</option>
                        <option value={3}>India</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>Select City</option>
                        <option selected>Select City</option>
                        <option value="Agra">Agra</option>
                        <option value="Ahmedabad">Ahmedabad</option>
                        <option value="Ajmer">Ajmer</option>
                        <option value="Allahabad">Allahabad</option>
                        <option value="Aligarh">Aligarh</option>
                        <option value="Amravati">Amravati</option>
                        <option value="Amritsar">Amritsar</option>
                        <option value="Aurangabad">Aurangabad</option>
                        <option value="Bangalore">Bangalore</option>
                        <option value="Bhopal">Bhopal</option>
                        <option value="Bhubaneswar">Bhubaneswar</option>
                        <option value="Chandigarh">Chandigarh</option>
                        <option value="Chennai">Chennai</option>
                        <option value="Coimbatore">Coimbatore</option>
                        <option value="Cuttak">Cuttak</option>
                        <option value="Dehradun">Dehradun</option>
                        <option value="Delhi">Delhi </option>
                        <option value="Faridabad">Faridabad</option>
                        <option value="Ghaziabad">Ghaziabad</option>
                        <option value="Goa">Goa</option>
                        <option value="Gorakhpur">Gorakhpur</option>
                        <option value="Gurgaon">Gurgaon</option>
                        <option value="Guwahati">Guwahati</option>
                        <option value="Hubli">Hubli</option>
                        <option value="Imphal">Imphal</option>
                        <option value="Indore">Indore</option>
                        <option value="Jaipur">Jaipur</option>
                        <option value="Jalandhar">Jalandhar</option>
                        <option value="Jamshedpur">Jamshedpur</option>
                        <option value="Jodhpur">Jodhpur</option>
                        <option value="Kanpur">Kanpur</option>
                        <option value="Kochi">Kochi</option>
                        <option value="Kolkata">Kolkata</option>
                        <option value="Kozhikode">Kozhikode</option>
                        <option value="Lucknow">Lucknow</option>
                        <option value="Ludhiana">Ludhiana</option>
                        <option value="Madurai">Madurai</option>
                        <option value="Mangalore">Mangalore</option>
                        <option value="Meerut">Meerut</option>
                        <option value="Mumbai">Mumbai</option>
                        <option value="Mysore">Mysore</option>
                        <option value="Nagpur">Nagpur</option>
                        <option value="Nashik">Nashik</option>
                        <option value="Noida">Noida</option>
                        <option value="Patna">Patna</option>
                        <option value="Pimpri-Chinchwad">Pimpri-Chinchwad</option>
                        <option value="Pondicherry">Pondicherry</option>
                        <option value="Pune">Pune</option>
                        <option value="Raipur">Raipur</option>
                        <option value="Rajkot">Rajkot</option>
                        <option value="Ranchi">Ranchi</option>
                        <option value="Salem">Salem</option>
                        <option value="Shillong">Shillong</option>
                        <option value="Shimla">Shimla</option>
                        <option value="Siliguri">Siliguri</option>
                        <option value="Srinagar">Srinagar</option>
                        <option value="Surat">Surat</option>
                        <option value="Thane">Thane</option>
                        <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                        <option value="Tirupati">Tirupati</option>
                        <option value="Udaipur">Udaipur</option>
                        <option value="Vadodara">Vadodara</option>
                        <option value="Varanasi">Varanasi</option>
                        <option value="Vijayawada">Vijayawada</option>
                        <option value="Visakhapatnam">Visakhapatnam</option> 
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>Select Palace</option>
                        <option value={3}>India</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>Small Event</option>
                        <option value={1}>Event Type</option>
                        <option value={2}>Big Event</option>
                        <option value={3}>Small Event</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>No. Of Palace</option>
                        <option value={1}>100-200</option>
                        <option value={2}>300-400</option>
                        <option value={3}>500-600</option>
                        <option value={4}>700-800</option>
                        <option value={5}>900-1000</option>
                        <option value={6}>1000+</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <select className="form-select border-primary p-2" aria-label="Default select example">
                        <option selected>Vegetarian</option>
                        <option value={1}>Vegetarian</option>
                        <option value={2}>Non Vegetarian</option>
                      </select>
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <input type="mobile" className="form-control border-primary p-2" placeholder="Your Contact No." />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <input type="date" className="form-control border-primary p-2" placeholder="Select Date" />
                    </div>
                    <div className="col-lg-4 col-md-6">
                      <input type="email" className="form-control border-primary p-2" placeholder="Enter Your Email" />
                    </div>
                    <div className="col-12 text-center">
                      <button type="submit" className="btn btn-primary px-5 py-3 rounded-pill">Submit Now</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1">
                <img src="allimage/background-site.jpg" className="img-fluid h-100 w-100 rounded-end" style={{objectFit: 'cover', opacity: '0.7'}} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Book Us End */}
        {/* Footer Start */}
        <FooterComponent></FooterComponent>
        {/* Footer End */}
        {/* Copyright Start */}
       
        {/* Copyright End */}
        {/* Back to Top */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
    );
  }
}

export default EventComponent;
