import React, { Component } from 'react';
import FooterComponent from './FooterComponent';
import NavBar from './NavBar';

class GalleryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>
        <NavBar></NavBar>
        <style dangerouslySetInnerHTML={{ __html: "\n            .team-item img {\n                width: 100%; /* Make sure the image takes the full width of its container */\n                height: 200px; /* Set a fixed height for all images */\n                object-fit: cover; /* Preserve aspect ratio while covering the container */\n            }\n        " }} />
        <div className="container-fluid team py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Gallery</small>
              <h1 className="display-5 mb-5">Our Gallery</h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                    <span className="text-dark" style={{ width: '150px' }}>Photos</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                    <span className="text-dark" style={{ width: '150px' }}>Videos</span>
                  </a>
                </li>
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                    <span className="text-dark" style={{width: '150px'}}>Corporate</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                    <span className="text-dark" style={{width: '150px'}}>Mocktail</span>
                  </a>
                </li> */}
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                    <span className="text-dark" style={{width: '150px'}}>Buffet</span>
                  </a>
                </li> */}
              </ul>
              <style dangerouslySetInnerHTML={{ __html: "\n                        .event-img img {\n                            width: 100%; /* Make sure the image takes the full width of its container */\n                            height: 200px; /* Set a fixed height for all images */\n                            object-fit: cover; /* Preserve aspect ratio while covering the container */\n                        }\n                    " }} />
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-12"></div>
                    <div className="row g-4">


 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/271652705_4900009800063859_948204746010088730_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/271664568_4900010400063799_5834512505962305677_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/271744086_4900010120063827_6459050546996629327_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/294562456_5469657206432446_3169868925825926002_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/295089710_5466514596746707_4299402414676974335_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/295138652_5466529036745263_1831471610883635456_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/312719311_5753372128060951_3483250997248436587_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316261755_5839344682797028_2488272146832904016_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316264895_5839347919463371_6271953992961065889_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316267581_5839320459466117_4548551478241077682_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316274090_5839345219463641_2520663102820825021_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316279700_5839345286130301_7176244071107953423_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316288459_5839319579466205_5570004633824795312_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316293066_5839320299466133_4001295680707495716_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316312259_5839318602799636_1171518402354127682_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316314741_5839319379466225_1691694240645126162_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316318412_5839351199463043_255606376158562510_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316414002_5839320006132829_2362025104240723826_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316414449_5839318042799692_7430733030222007139_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316418602_5839346109463552_5902022306136895021_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316419075_5839347139463449_1854646233586335493_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316419924_5839346146130215_8505815670520181559_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316420861_5839350322796464_6341581555298736380_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316421940_5839320366132793_7889278562118571933_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316427930_5839317402799756_2014384706250546810_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316431252_5839319172799579_9109459942652358448_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316542510_5839348876129942_440293168650533767_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316555887_5839349596129870_8538401070160475759_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316555991_5839320122799484_9048227349605283107_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316681400_5839319006132929_6599666063393015561_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316808450_5839317586133071_5384410435669899832_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316813458_5839320212799475_5152439879065321774_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316820631_5839349856129844_3950993963830250437_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316824568_5839350829463080_3044799617594465512_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316824976_5839351626129667_8064883748282511212_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316833995_5839344292797067_7433352405689732705_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316959378_5839318799466283_3670104830092042213_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/316964900_5839347132796783_3996256361997092643_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317093767_5839319799466183_7343500867578140172_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317724534_5866129146785248_859978761994500556_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317724674_5866123570119139_948783521599267599_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317735602_5866126226785540_5656606158378991412_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317792862_5866127376785425_6051520654925890739_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/317831931_5866125776785585_2661463956299628817_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318019653_5866128486785314_6897721696968264347_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318047371_5866124413452388_3873029218776173503_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318313580_5866125016785661_1476578345925104988_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318345494_5866129803451849_7195075522606120991_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318347426_5866125456785617_8580973090353456532_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/318360131_5866123916785771_5562581557678984966_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/331308263_1330680730840912_2775318621582451259_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/331687751_5551098724995896_3148043305506955935_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/332090562_901344314532818_6793854684812602799_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/337145808_9479215568756970_4295640145343400559_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/337366209_140871822020656_4570904731889911289_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339107337_595003502663084_6828806355832361426_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339109092_757791409188245_7315452231916961365_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339110059_192311383557748_8999362617218263523_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339112550_905804990690349_8245496737230409942_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339114712_162389479723222_6395571750761577299_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339116065_769563467783421_4529905839015769736_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339116329_528444782810702_3486654811452928345_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339117036_1756218808109494_3701317913701732812_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339121253_554957213415330_3264773463404528403_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339126181_668096078409873_7746820373293518062_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339127975_990569702387493_4804226147147955229_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339128291_6002052143257295_1056484723160506762_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339129071_626414479313110_6736864086146072509_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339132219_772926677549550_1420494634361548328_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339133928_1362269167904544_4701240016648894982_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339134927_1535556606971145_5506744827864068771_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339138913_780982680132119_7106524065002937859_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339139359_889213092163577_2753818315685811514_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339145341_2144975805712012_1489777889413942286_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339147741_247471330979163_6913142830821596624_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339148774_539362941689647_1643482369049606096_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339154217_773164307365513_4176215232292614179_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339158613_553983576717456_5495762026069563779_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339161258_3709998145900066_8735384526504064581_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339161270_760850698722241_6135638577965381169_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339168245_187629024055364_1833672925561666952_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339170826_112503685139636_1741574601735508890_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339255402_619843886206684_7290030857856157712_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339263699_543845201166131_7998342411371925681_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339265667_992292028804246_7490710790840873603_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339271024_606168118047796_1031447099355069822_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339274093_1614944445652890_9010595833357759222_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339278521_6471732132866667_1343471826776263359_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339281246_1379588082891482_3612444024157792191_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339284178_909849950284554_1119808776235390374_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339284178_1946154429058711_1548409874345772650_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339286462_567064008558010_355162907788067373_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339286757_233446132535435_5879249488732716092_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339286815_969570844212280_5053060578352284111_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339295623_574178068042696_6021553217406304720_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339297192_548563174027963_2033111419532835370_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339306099_261004706265441_4787873319957628874_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339309157_715488080369054_5558612236609787258_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339314528_1315493985848890_5768020263176835328_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339323440_3034356473525276_5399189793436504108_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339323909_230217386338572_4816137915254638148_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339328433_794003135477412_7456729485508099398_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339333232_1666600737115731_1648874198326324764_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339415597_190507853737449_4380564270180798919_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/339651599_2300064220180333_8942694995093422620_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/340090009_952805625738822_4512146831702871581_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/340147806_1258218724822106_6357047668849093836_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/340472513_961543048390218_6778034811646553760_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/340761809_217182304294496_2364667443614315829_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/340986259_789390095561907_3988802402582443768_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/341026757_773589180748213_5947963226078990716_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342486393_562094116065613_4745702581497956282_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342504411_914289059787662_1389650578706704487_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342803284_1195976314619097_5446677125868004637_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342818902_244198111452208_1017708808846583623_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342821529_1009588707091583_8610525431028480619_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342832380_789614688911953_1156873286321357582_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/342844478_626101925633842_4671702777885037124_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/343745343_6239732809428663_1094413642202999926_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345446155_560556499536421_6716932421935413278_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345451269_170739669267084_3258451670861238121_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345577857_1368966477226407_6958228621504551590_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345577963_3540912972863912_6599212643131185075_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345593418_914354493014608_3383182578621080694_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345597378_124379347273540_2104380822478661849_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/345605352_780572973658599_5511589990548226684_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/346642330_973413283662134_6940267838422765031_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/349752150_3412530279012017_899456667749967800_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/363425190_6662175130513975_103697632489891058_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/363431555_6662176567180498_2613199370717091429_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366087919_6662176930513795_1485666240404835152_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366162332_6662175617180593_3610860456492126451_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366173599_6662176127180542_1788544578185835210_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366184363_6662178197180335_7108974722033679448_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366217992_6662177877180367_285420317413707474_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366247850_6662175893847232_5532247807378581340_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366252146_6662174430514045_7388479138192137605_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366270274_6662174223847399_1261278454427456383_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366290901_6662174740514014_6752317289122969653_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/366372807_6662176303847191_8274801416498288630_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/374279081_6749239268474227_3860284093694584318_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/384203012_6906800526051433_4051465457407281753_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/392919202_6940226676042151_7301645757195056278_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/398595357_7027917257273092_9061731243945189539_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/398600331_7027918267272991_6953994936465590470_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/398600510_7027917040606447_822481345927999037_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/398602349_7027918010606350_6657939921157943201_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/399971786_7020037764727708_7735969557835245497_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/400747304_7027916733939811_1559312014718222370_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/400807034_7027915530606598_7965750813306510400_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/400974719_7027918517272966_151753709887837159_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/407628376_266377503083205_3488094016007124197_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408096825_7119951341403016_7997633087453408780_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408112620_7119953181402832_8157214831724092682_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408117976_7119952041402946_3919547175312348757_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408121605_7119951721402978_1015101898404070993_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408124581_7119952231402927_2781605281728290471_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408124942_7119950578069759_2968710535653083178_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408127696_7119952774736206_2597841598111106246_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408128411_7119950718069745_2033833631374714031_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408131117_7119951038069713_1059791689875519857_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408131789_7119950218069795_3173199045941034391_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408132719_7119950404736443_2271794744944000710_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408138412_7119951548069662_2325648566873938623_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408141950_7119952924736191_9068372658263416386_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408142727_7119950911403059_4670398001531846971_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408142761_7119953811402769_5900960464709119864_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408145543_7119951854736298_8541882143776580144_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408149450_7119953634736120_587219543810651483_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408159910_7119953301402820_6913695992427006806_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408184348_7119952491402901_4196788688121796127_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408189767_7119952374736246_1130948630410623605_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/408216259_7119953478069469_1433805777955560433_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/414505950_7233029836761832_5415741901320640353_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417357681_7265034890227993_2742654379556599652_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417357686_7265030936895055_5687944742698243957_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417358729_7265035093561306_8695742457797422218_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417358760_7233030243428458_765172829603800063_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417363294_7250333635031452_4865140529263760124_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417367674_7233029226761893_2341638204702246273_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417372590_7265033316894817_1947046229314804911_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417379806_7265031723561643_3194077366105108673_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417380210_7265031493561666_6075841796847146166_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417392204_7233029006761915_3286919605253450031_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417396694_7265031120228370_7153403405346225911_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417398031_7233029966761819_4775432342524953623_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417398639_7233029500095199_801398756875720197_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417398798_7293037717427710_8507071141667888179_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417399605_7233030123428470_8736006253663399518_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417400048_7293038657427616_2602221080497372609_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417412399_7265031916894957_2616722799123411876_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417412720_7250333761698106_4514509374969234528_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417418942_7293040604094088_5247312148236685580_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417419615_7233028856761930_8591756628674360410_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417425584_7265034496894699_4375319197338813422_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417438228_7265034116894737_2115429441921686765_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417438241_7265032050228277_4049277207220777135_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417445260_7250333528364796_807732085553091776_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417450687_7265033696894779_5919482645811068008_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417451051_7293039540760861_3828270716477330259_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417452490_7265033150228167_1280131335746471249_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417459031_7293038934094255_4478188941822596981_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417464559_7250333331698149_4943767816965049411_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417469837_7303803866351095_7884206495517124082_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417471058_7293039730760842_5036495070563537263_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417476353_7462737707124376_3593518811128279774_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417476634_7462737993791014_7319542200288466022_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417476943_7462734193791394_1838920175625473949_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417481058_7293038440760971_7190700256583403403_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417482134_7462736947124452_2836502630853459586_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417485927_7233029620095187_2523381942255475323_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417486387_7462730927125054_7314835673043847761_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417486854_7265033923561423_2904407473784063236_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417487662_7462735147124632_1744345504818265753_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417487802_7293038197427662_2298059597127574855_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417491678_7462734487124698_3947399157984800538_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417496363_7462734780458002_8683048265438135902_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417498730_7293041110760704_4641916046046524554_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417498782_7265032233561592_3167395747300280423_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417498877_7265033506894798_8659405031040039924_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417502318_7293040174094131_5405441264605677650_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417507716_7462736067124540_8477682062298049966_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417507718_7462730617125085_3373963892736021949_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417508848_7293039940760821_8724003923482867281_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417508919_7462732013791612_377876364692738390_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417509099_7233029730095176_2582768718128562161_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417514178_7462731773791636_5057892267166784331_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417517810_7462737280457752_7309649914893232029_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417524501_7462735450457935_2500057333962810240_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417528405_7233029350095214_1144256938633844447_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417532878_7265031266895022_1014938244025442561_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417537835_7250333411698141_609031163559026887_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417548497_7293040417427440_4058299263069289069_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417552775_7293037940761021_6288419011024063445_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417562349_7293040770760738_7440140044001183460_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417564928_7462736403791173_6737418109384654649_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417577522_7250333151698167_5161410303684544488_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417683887_7293039200760895_8519713764350008093_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/417696241_7250333245031491_1441769432117677909_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/418083331_7462732203791593_2835451175185032240_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/418171555_7462736697124477_6313314999247453657_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/418834656_7462731357125011_7086867668639433705_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/419018253_7462731150458365_2966411196289125882_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/419248337_7265032756894873_1422311974155879185_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/419736872_7462733877124759_1326789711053137242_n.jpg" width={300} height={200} alt="" /></div></div>
 <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s"><div className="event-img position-relative"><img className="img-fluid rounded w-100" src="allimage/420169413_7293040920760723_1981137678715180586_n.jpg" width={300} height={200} alt="" /></div></div>


                   
                     
                      {/* Testimonial Start */}

                      {/* Blog Start */}
                     
                    </div>
                  </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n                            .event-img img {\n                                width: 100%; /* Make sure the image takes the full width of its container */\n                                height: 200px; /* Set a fixed height for all images */\n                                object-fit: cover; /* Preserve aspect ratio while covering the container */\n                            }\n                        " }} />
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      

<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/3663422117232735.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/7_1817589598688444.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/9_935588398095914.mp4" type="video/mp4" /></video></div></div>

<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/25_1111224253381450.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/26_903733138051147.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/27_1627599411392147.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/55_746692490653313.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/56_355531560441942.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/57_931458078695883.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/59_1117693302924475.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/131_2361289687269229.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/132_2361289350602596.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/140_2301314293266769.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/155_2229542070443992.mp4" type="video/mp4" /></video></div></div>

</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/157_2191510927580440.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/163_2188489967882536.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/165_2188488267882706.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/166_2188486394549560.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/171_2188436597887873.mp4" type="video/mp4" /></video></div></div>
<div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/cetringvcl/172_2188425637888969.mp4" type="video/mp4" /></video></div></div>

</div>
<div className="row g-4">
</div>




                    </div>
                  </div>
                </div>
                <div className="container-fluid blog py-6">
                        <div className="container">
                          <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
                            <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Blog</small>
                            <h1 className="display-5 mb-5">Be First Who Read News</h1>
                          </div>
                          <div className="row gx-4 justify-content-center">
                            <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s">
                              <div className="blog-item">
                                <div className="overflow-hidden rounded">
                                  <img src="allimage/blog-1.jpg" className="img-fluid w-100" alt="" />
                                </div>
                                <div className="blog-content mx-4 d-flex rounded bg-light">
                                  <div className="text-dark bg-primary rounded-start">
                                    <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                                      <p className="fw-bold mb-0">16</p>
                                      <p className="fw-bold mb-0">Sep</p>
                                    </div>
                                  </div>
                                  <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.3s">
                              <div className="blog-item">
                                <div className="overflow-hidden rounded">
                                  <img src="allimage/blog-2.jpg" className="img-fluid w-100" alt="" />
                                </div>
                                <div className="blog-content mx-4 d-flex rounded bg-light">
                                  <div className="text-dark bg-primary rounded-start">
                                    <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                                      <p className="fw-bold mb-0">16</p>
                                      <p className="fw-bold mb-0">Sep</p>
                                    </div>
                                  </div>
                                  <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.5s">
                              <div className="blog-item">
                                <div className="overflow-hidden rounded">
                                  <img src="allimage/blog-3.jpg" className="img-fluid w-100" alt="" />
                                </div>
                                <div className="blog-content mx-4 d-flex rounded bg-light">
                                  <div className="text-dark bg-primary rounded-start">
                                    <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                                      <p className="fw-bold mb-0">16</p>
                                      <p className="fw-bold mb-0">Sep</p>
                                    </div>
                                  </div>
                                  <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
              </div>
              {/* Blog End */}
              {/* Footer Start */}
         
              {/* Footer End */}
              {/* Copyright Start */}

              {/* Copyright End */}
              {/* Back to Top */}
              {/* JavaScript Libraries */}
              {/* Template Javascript */}
            </div>
          </div>
        </div>
        <FooterComponent></FooterComponent>
      </div>

    );
  }
}
export default GalleryComponent;

