import React, { Component } from 'react'





class NavBar extends Component {
  constructor(props) {
    super(props);


    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }



  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();


  }


  render() {
    return (


<div className="container-fluid nav-bar" style={{ marginLeft: '-5%', backgroundColor: 'white' }}>
        <div className="container">
          <nav className="navbar navbar-light navbar-expand-lg py-4">
            <li class="nav-item nav-link">
              <a href="/cetrin-home" className="navbar-brand" >
                <h1 className="text-primary fw-bold mb-0" > <img src="/allimage/providene-logo.png" style={{width:'45px'}} ></img>Providence<span className="text-dark">International</span> </h1>

              </a></li>
            <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="fa fa-bars text-primary" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav mx-auto" style={{ fontSize: '14px' }}>
                <li class="nav-item nav-link">
                  <a href="/cetrin-home" className="nav-item nav-link active" >Home</a></li>
                <li className="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                  <a className="nav-item nav-link" href="/cetrin-about">About</a>
                </li>
                <li class="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                  <a href="/cetrin-service" className="nav-item nav-link">Services</a>  </li>
                <li class="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                  <a href="/cetrin-event" className="nav-item nav-link">Events</a>  </li>
                <li class="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                  <a href="/cetrin-menu" className="nav-item nav-link">Menu</a>  </li>
                <div className="nav-item dropdown">
                  <li class="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                    <a href="/#" className="nav-link dropdown-toggle" >Pages</a>  </li>
                  <div className="dropdown-menu bg-light" style={{ marginTop: '-50px' }}>
                    <li class="nav-item nav-link" style={{   marginTop: '-30px' }}>
                      <a href="/cetrin-image" className="dropdown-item" >Gallery</a>  </li>

                    <li class="nav-item nav-link" style={{   marginTop: '-50px' }} >
                      <a href="/cetrin-booknow" className="dropdown-item" >Booking</a>  </li>
                    <li class="nav-item nav-link" style={{   marginTop: '-50px' }}>
                      <a href="/cetrin-blog" className="dropdown-item">Our Blog</a>  </li>
                    <li class="nav-item nav-link" style={{ marginTop: '-50px' }}>
                      <a href="/cetrin-team" className="dropdown-item">Our Team</a>  </li>
                    <li class="nav-item nav-link" style={{  marginTop: '-50px' }}>
                      <a href="/cetrin-testimonial" className="dropdown-item">Testimonial</a>  </li>
                    <li class="nav-item nav-link" style={{  marginTop: '-50px' }}>
                      <a href="/cetrin-404" className="dropdown-item">404 Page</a>  </li>
                  </div>
                </div>
                <li class="nav-item nav-link" style={{ marginInlineStart: '-30px' }}>
                  <a href="/cetrin-contact" className="nav-item nav-link">Contact</a>  </li>
                
              </div>
            </div>
          </nav>
        </div>
      </div>

    );
  }



}

export default NavBar