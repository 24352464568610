import React, { Component } from 'react';
import ManpowerFooterComponent from './ManpowerFooterComponent';
import ManpowerNavBar from './ManpowerNavBar';


class ManpowerGalleryComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (

      <div>
        <ManpowerNavBar></ManpowerNavBar>
          {/* Subpage title start */}
          <div className="container-fluid bg-dark p-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="display-4 text-white">OUR GALLERY</h1>
                
              </div>
            </div>
          </div>
        {/* Subpage title end */}
        {/* Page Banner end */}


        <div className="container-fluid event py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Latest Events</small>
              <h1 className="display-5 mb-5">Our Social &amp; Professional Events Gallery</h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                    <span className="text-dark" style={{ width: '150px'}}>Photos</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                    <span className="text-dark" style={{ width: '150px' }}>Videos</span>
                  </a>
                </li>
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                    <span className="text-dark" style={{width: '150px'}}>Corporate</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                    <span className="text-dark" style={{width: '150px'}}>Mocktail</span>
                  </a>
                </li> */}
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                    <span className="text-dark" style={{width: '150px'}}>Buffet</span>
                  </a>
                </li> */}
              </ul>
              <style dangerouslySetInnerHTML={{ __html: "\n                        .event-img img {\n                            width: 100%; /* Make sure the image takes the full width of its container */\n                            height: 200px; /* Set a fixed height for all images */\n                            object-fit: cover; /* Preserve aspect ratio while covering the container */\n                        }\n                    " }} />
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240120-WA0102.jpg" alt=""  style={{height:'300px'}} />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0017.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0018.jpg" alt=""  style={{height:'300px'}}  /> 
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0019.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0020.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0021.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0022.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0023.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0024.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0025.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0026.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0027.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0028.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0029.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0030.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0031.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0032.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0033.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0034.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0035.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0036.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0037.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0038.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0039.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0040.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0041.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0042.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0043.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0044.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0045.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0046.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0047.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0048.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0049.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0050.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0051.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0052.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0053.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0054.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0055.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0056.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0057.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0058.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0059.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0060.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0061.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0062.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0063.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0064.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0065.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0066.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0067.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0069.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0070.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0071.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0072.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0073.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0074.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0075.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0076.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0077.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0078.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/IMG-20240123-WA0079.jpg"  alt=""  style={{height:'300px'}}  />
                          </div>
                        </div>







                      </div>
                    </div>
                  </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n                            .event-img img {\n                                width: 100%; /* Make sure the image takes the full width of its container */\n                                height: 200px; /* Set a fixed height for all images */\n                                object-fit: cover; /* Preserve aspect ratio while covering the container */\n                            }\n                        " }} />
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-2">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0030.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0005.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0006.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0007.mp4" type="video/mp4" /></video></div></div>
                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0008.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0009.mp4" type="video/mp4" /></video></div></div>
                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0010.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0011.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0012.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0013.mp4" type="video/mp4" /></video></div></div>
                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0014.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0015.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0016.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0017.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0018.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0019.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0020.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0021.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0022.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0023.mp4" type="video/mp4" /></video></div></div>

                      </div>
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0024.mp4" type="video/mp4" /></video></div></div>
                        <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="allimage/VID-20240123-WA0025.mp4" type="video/mp4" /></video></div></div>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ManpowerFooterComponent></ManpowerFooterComponent>
        </div>

      </div>
    );
  }
}
export default ManpowerGalleryComponent;

