import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import ProductionHouseNavBarComponent from './ProductionHouseNavBarComponent';

class ProductionHouseTeamComponent extends Component {
  state = {
    country: '',
    city: '',
    place: '',
    event: '',
    palace: '',
    veg: '',
    mobileNumber: '',
    date: '',
    email: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };


  // Assuming you have a form reference named 'form'
  // this.form.validateAll();


  render() {
    return (
      <div>
      <meta charSet="utf-8" />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Planet</title>
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      <link href="css/font-awesome.min.css" rel="stylesheet" />
      <link href="css/global.css" rel="stylesheet" />
      <link href="css/about.css" rel="stylesheet" />
      <link href="https://fonts.googleapis.com/css2?family=Rajdhani&display=swap" rel="stylesheet" />
      <ProductionHouseNavBarComponent/>

     
      <section id="team" className="pt-4 pb-4 bg_grey"  >
        <div className="container-xl">
          <div className="team_1i row">
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/37.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Semp Porta</h4>
                  <h6>Manager</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/38.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Dapibus Diam</h4>
                  <h6>Trainer</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/39.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Eget Nulla</h4>
                  <h6>Designer</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="team_1i row mt-4">
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/40.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Semp Porta</h4>
                  <h6>Manager</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/41.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Dapibus Diam</h4>
                  <h6>Trainer</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="team_1i1 clearfix position-relative">
                <div className="team_1i1i clearfix">
                  <div className="grid clearfix">
                    <figure className="effect-jazz mb-0">
                      <a href="#"><img src="production house/img/42.jpg" className="w-100" alt="abc" /></a>
                    </figure>
                  </div>
                </div>
                <div className="team_1i1i1 clearfix position-absolute w-100 bottom-0">
                  <h4 className="col_red">Eget Nulla</h4>
                  <h6>Designer</h6>
                  <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore. Aliquam ut elit venenatis, congue dolor ut, mattis leo</p>
                  <ul className="social-network social-circle mb-0 mt-3">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer">
        <div className="footer_m clearfix">
          <div className="container">
            <div className="row footer_1">
              <div className="col-md-4">
                <div className="footer_1i">
                  <h3><a className="text-white" href="index.html"><i className="fa fa-video-camera col_red me-1" /> Planet</a></h3>
                  <p className="mt-3">Lorem ipsum dolor sit amet consect etur adi pisicing elit sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet consect etur. </p>
                  <h6 className="fw-normal"><i className="fa fa-map-marker fs-5 align-middle col_red me-1" /> 5311 Ceaver Sidge Td.
                    Pakland, DE 13507</h6>
                  <h6 className="fw-normal mt-3"><i className="fa fa-envelope fs-5 align-middle col_red me-1" /> info@gmail.com</h6>
                  <h6 className="fw-normal mt-3 mb-0"><i className="fa fa-phone fs-5 align-middle col_red me-1" />  +123 123 456</h6>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer_1i">
                  <h4>Flickr <span className="col_red">Stream</span></h4>
                  <div className="footer_1i1 row mt-4">
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/4.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/5.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/6.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/7.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="footer_1i1 row mt-3">
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/8.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/9.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/10.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="footer_1i1i">
                        <div className="grid clearfix">
                          <figure className="effect-jazz mb-0">
                            <a href="#"><img src="production house/img/11.jpg" height={70} className="w-100" alt="abc" /></a>
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer_1i">
                  <h4>Sign  <span className="col_red">Newsletter</span></h4>
                  <p className="mt-3">Subscribe to our newsletter list to get latest news and updates from us</p>
                  <div className="input-group">
                    <input type="text" className="form-control bg-black" placeholder="Email" />
                    <span className="input-group-btn">
                      <button className="btn btn text-white bg_red rounded-0 border-0" type="button">
                        Subscribe</button>
                    </span>
                  </div>
                  <ul className="social-network social-circle mb-0 mt-4">
                    <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                    <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                    <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                    <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                    <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="footer_b" className="pt-3 pb-3 bg_grey">
        <div className="container">
          <div className="row footer_1">
            <div className="col-md-8">
              <div className="footer_1l">
                <p className="mb-0">© 2013 Your Website Name. All Rights Reserved | Design by <a className="col_red" href="http://www.templateonweb.com">TemplateOnWeb</a></p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="footer_1r">
                <ul className="mb-0">
                  <li className="d-inline-block me-2"><a href="#">Home</a></li>
                  <li className="d-inline-block me-2"><a href="#">Features</a></li>
                  <li className="d-inline-block me-2"><a href="#">Pages</a></li>
                  <li className="d-inline-block me-2"><a href="#">Portfolio</a></li>
                  <li className="d-inline-block me-2"><a href="#">Blog</a></li>
                  <li className="d-inline-block"><a href="#">Contact</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
}
export default ProductionHouseTeamComponent;
