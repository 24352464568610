import React, { Component } from 'react';
import ManpowerFooterComponent from './ManpowerFooterComponent';
import ManpowerNavBar from './ManpowerNavBar';
import emailjs from 'emailjs-com';

class ManpowerContactComponent  extends Component {

  state= {
    name: '',
    email: '',
   message:'',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <title>CONSULT - Consultancy Website Template</title>
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <meta content="Free HTML Templates" name="keywords" />
        <meta content="Free HTML Templates" name="description" />
        {/* Favicon */}
        <link href="img/favicon.ico" rel="icon" />
        {/* Google Web Fonts */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
        <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" /> 
        {/* Icon Font Stylesheet */}
        <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
        <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
        {/* Libraries Stylesheet */}
        <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
        {/* Customized Bootstrap Stylesheet */}
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        {/* Template Stylesheet */}
        <link href="css/style.css" rel="stylesheet" />
        <div>
          {/* Topbar Start */}
          <ManpowerNavBar></ManpowerNavBar>
{/* Navbar End */}
          {/* Page Header Start */}
          <div className="container-fluid bg-dark p-5">
            <div className="row">
              <div className="col-12 text-center">
                <h1 className="display-4 text-white">Contact Us</h1>
               
               
                
              </div>
            </div>
          </div>
          {/* Page Header End */}
          {/* Contact Start */}
          <div className="container-fluid bg-secondary px-0">
            <div className="row g-0">
            <div className="col-lg-3 py-6 px-5  text-center">
               
              </div>
              <div className="col-lg-6 py-6 px-5  text-center">
                <h1 className="display-5 mb-4">Contact For Any Queries</h1>
                <form id="form" onSubmit={this.handleSubmit}>
                  <div className="row g-3">
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="text" className="form-control" name="name" id="name"  required    />
                        <label htmlFor="form-floating-1">Full Name</label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="form-floating">
                        <input type="email" className="form-control" name="email" id="email"  required/>
                        <label htmlFor="form-floating-2">Email address</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <input type="text" className="form-control" name="subject" id="subject" required   />
                        <label htmlFor="form-floating-3">Subject</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-floating">
                        <textarea className="form-control" name="message" id="message" required style={{height: '150px'}} defaultValue={""} />
                        <label htmlFor="form-floating-4">Message</label>
                      </div>
                    </div>
                    <div className="col-12">
                      <button id="button"className="btn btn-primary w-100 py-3" type="submit">Submit</button>
                    </div>
                  </div>
                </form>
              </div>
       
            </div>
          </div>
          {/* Contact End */}
          {/* Footer Start */}
         <ManpowerFooterComponent></ManpowerFooterComponent>
         
          {/* Footer End */}
          {/* Back to Top */}
          {/* JavaScript Libraries */}
          {/* Template Javascript */}
        </div></div>
    );
  }
}
export default ManpowerContactComponent ;
