import React, { Component } from 'react';

class FooterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div className="container-fluid footer py-6 my-6 mb-0 bg-light wow bounceInUp" data-wow-delay="0.1s">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-6">
            <div className="footer-item">
              <h3 className="text-primary">Quick<span className="text-dark">Links</span></h3>
              <div className="d-flex flex-column align-items-start">
                <a className="text-body mb-3" href="/cetrin-home"><i className="fa fa-check text-primary me-2" />Catering Services</a>
                <a className="text-body mb-3" href="/banquet-home"><i className="fa fa-check text-primary me-2" />Banquet Hall</a>
                <a className="text-body mb-3" href="/manpower-home"><i className="fa fa-check text-primary me-2" />Hospitality Services</a>
                <a className="text-body mb-3" href="/productionHouse-home"><i className="fa fa-check text-primary me-2" />Production House</a>
              </div>
             
              <div className="footer-icon d-flex">
                <a className="btn btn-primary btn-sm-square me-2 rounded-circle" href="https://www.facebook.com/profile.php?id=100090355260753&mibextid=ZbWKwL"><i className="fab fa-facebook-f" /></a>
                <a href="https://instagram.com/providenceinternational.co?igshid=YmMyMTA2M2Y=" className="btn btn-primary btn-sm-square me-2 rounded-circle"><i className="fab fa-instagram" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-item">
              <h4 className="mb-4">Special Facilities</h4>
              <div className="d-flex flex-column align-items-start">
                <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Cheese Burger</a>
                <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Sandwich</a>
                <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Panner Burger</a>
                <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Special Sweets</a>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-item">
              <h4 className="mb-4">Contact Us</h4>
              <div  className="d-flex flex-column align-items-start">
                <p style={{color:'black'}}><i className="fa fa-map-marker-alt text-primary me-2" /> Head Office No. A55, First Floor, Virvani Plaza, East Street, Camp Pune 411001.</p>
                <p style={{color:'black'}}><i className="fa fa-phone-alt text-primary me-2" />9595264988</p>
                <p style={{color:'black'}}><i className="fas fa-envelope text-primary me-2" />providenceinternational180@gmail.com</p>
                <p style={{color:'black'}}><i className="fa fa-clock text-primary me-2" /> Mon - Fri: 9am - 6pm
                  Sat: 10am - 2pm
                  Sun: Closed</p>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-6">
            <div className="footer-item">
              <h4 className="mb-4">Social Gallery</h4>
              <div className="row g-2">
                <div className="col-4">
                  <img src="allimage/menu-01.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
                <div className="col-4">
                  <img src="allimage/menu-02.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
                <div className="col-4">
                  <img src="allimage/menu-03.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
                <div className="col-4">
                  <img src="allimage/menu-04.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
                <div className="col-4">
                  <img src="allimage/menu-05.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
                <div className="col-4">
                  <img src="allimage/menu-06.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}
export default FooterComponent;

