import React, { Component } from 'react';
import NavBar from './NavBar';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { testimonialSliderSettings } from './slickSettings';
import FooterComponent from './FooterComponent';





class TestimonialComponent extends Component {


  constructor(props) {
    super(props);

    this.state = {
      testimonialSliderSettings: {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 4, // Adjust the number of testimonials you want to show in one row
        slidesToScroll: 1,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 4000,
      },
      testimonialSliderSettings1: {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 3, // Adjust the number of testimonials you want to show in one row
        slidesToScroll: 1,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 4000,
      },
    };
  }


  render() {
    return (
      <div>
        {/* Navbar End */}
        {/* Modal Search Start */}
        <div>
          <NavBar></NavBar>

          <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-fullscreen">
              <div className="modal-content rounded-0">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                </div>
                <div className="modal-body d-flex align-items-center">
                  <div className="input-group w-75 mx-auto d-flex">
                    <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                    <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Modal Search End */}
          {/* Hero Start */}
          <div className="container-fluid bg-light py-6 my-6 mt-0">
            <div className="container text-center animated bounceInDown">
              <h1 className="display-1 mb-4">Testimonial</h1>

            </div>
          </div>
          {/* Hero End */}
          {/* Testimonial Start */}
          <div className="container-fluid py-6" style={{ marginTop: '-17%' }}>
            <div className="container">
              <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
                <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Testimonial</small>
                <h1 className="display-5 mb-5">What Our Customers says!</h1>
              </div>
        
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-1.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Johnson Smith</h4>
                     
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">"Exceptional service and delicious food! Highly recommend Providence International for any event."</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-2.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Emily Johnson</h4>
                   
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3"> "Impressed by the professionalism and attention to detail. Will definitely use Providence International again!"</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-3.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Sarah Brown</h4>
                    
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">"Absolutely fantastic experience with Providence International. The team exceeded all expectations!"</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-4.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Mathew Taylor</h4>
                    
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">"Top-notch catering service that never disappoints. Thanks, for making my event a memorable event!"</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-1.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>

                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-2.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>




                {/* Add more testimonial items as needed */}

            
              <br></br>
              <Slider {...this.state.testimonialSliderSettings1}>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-3.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-4.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-1.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-2.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-3.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>
                <div className="testimonial-item rounded bg-light">
                  <div className="d-flex mb-3">
                    <img src="img/testimonial-4.jpg" className="img-fluid rounded-circle flex-shrink-0" alt="" />
                    <div className="position-absolute" style={{ top: '15px', right: '20px' }}>
                      <i className="fa fa-quote-right fa-2x" />
                    </div>
                    <div className="ps-3 my-auto">
                      <h4 className="mb-0">Person Name</h4>
                      <p className="m-0">Profession</p>
                    </div>
                  </div>
                  <div className="testimonial-content">
                    <div className="d-flex">
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                      <i className="fas fa-star text-primary" />
                    </div>
                    <p className="fs-5 m-0 pt-3">Lorem ipsum dolor sit amet elit, sed do eiusmod tempor ut labore et dolore magna aliqua.</p>
                  </div>
                </div>





                {/* Add more testimonial items as needed */}

              </Slider>


            </div>
          </div>
          <FooterComponent></FooterComponent>
          {/* Template Javascript */}
        </div>
      </div>
    );
  }
}

export default TestimonialComponent;
