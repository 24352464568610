import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import ProductionHouseNavBarComponent from './ProductionHouseNavBarComponent';

class ProductionHouseBlogComponent extends Component {
  state = {
    country: '',
    city: '',
    place: '',
    event: '',
    palace: '',
    veg: '',
    mobileNumber: '',
    date: '',
    email: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };


  // Assuming you have a form reference named 'form'
  // this.form.validateAll();


  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>Planet</title>
        <link href="css/bootstrap.min.css" rel="stylesheet" />
        <link href="css/font-awesome.min.css" rel="stylesheet" />
        <link href="css/global.css" rel="stylesheet" />
        <link href="css/blog.css" rel="stylesheet" />
        <link href="https://fonts.googleapis.com/css2?family=Rajdhani&display=swap" rel="stylesheet" />
        <ProductionHouseNavBarComponent/>

       
        <section id="blog" className="pt-4 pb-4 bg_grey" style={{marginTop:'-100px'}}>
          <div className="container-xl">
            <div className="row blog_1">
              <div className="col-md-8">
                <div className="blog_1l">
                  <div className="blog_1l1">
                    <div className="popular_2i1lm position-relative clearfix">
                      <div className="popular_2i1lm1 clearfix">
                        <div className="grid">
                          <figure className="effect-jazz mb-0">
                            <a href="blog_detail.html"><img src="production house/img/2.jpg" height={450} className="w-100" alt="img25" /></a>
                          </figure>
                        </div>
                      </div>
                      <div className="popular_2i1lm2 position-absolute top-0 w-100 text-center clearfix">
                        <ul>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-link col_red" /></a></li>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-search col_red" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog_1l1i mt-3">
                      <h5><i className="fa fa-folder-open col_red me-1" /> Movie News</h5>
                      <h2 className="mt-3"><a className="col_red" href="blog_detail.html">Company Foundation Provides  Grant to Emory Law	</a></h2>
                      <h6 className="fw-normal mt-3 col_light">
                        <span><i className="fa fa-clock-o me-1 align-middle col_red" /> 14 December 2021</span>
                        <span className="ms-3"><i className="fa fa-user me-1 align-middle col_red" /> Admin</span>
                        <span className="ms-3"><i className="fa fa-comment me-1 align-middle col_red" /> Comment</span>
                      </h6>
                      <p className="mt-3">Consectetuer adipiscing elit sedare diam nonummy nibh euismod estat tinciduntal ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minimatisa veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl. Name liber tempor cum soluta nobis eleifend option congue nihil imperdietes</p>
                      <h6 className="mt-3 mb-0"><a className="button" href="blog_detail.html"><i className="fa fa-long-arrow-right align-middle me-1" /> Read More</a></h6>
                    </div>
                  </div>
                  <div className="blog_1l1 mt-4">
                    <div className="popular_2i1lm position-relative clearfix">
                      <div className="popular_2i1lm1 clearfix">
                        <div className="grid">
                          <figure className="effect-jazz mb-0">
                            <a href="blog_detail.html"><img src="production house/img/1.jpg" height={450} className="w-100" alt="img25" /></a>
                          </figure>
                        </div>
                      </div>
                      <div className="popular_2i1lm2 position-absolute top-0 w-100 text-center clearfix">
                        <ul>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-link col_red" /></a></li>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-search col_red" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog_1l1i mt-3">
                      <h5><i className="fa fa-folder-open col_red me-1" /> Movie News</h5>
                      <h2 className="mt-3"><a className="col_red" href="blog_detail.html">Company Foundation Provides  Grant to Emory Law	</a></h2>
                      <h6 className="fw-normal mt-3 col_light">
                        <span><i className="fa fa-clock-o me-1 align-middle col_red" /> 14 December 2021</span>
                        <span className="ms-3"><i className="fa fa-user me-1 align-middle col_red" /> Admin</span>
                        <span className="ms-3"><i className="fa fa-comment me-1 align-middle col_red" /> Comment</span>
                      </h6>
                      <p className="mt-3">Consectetuer adipiscing elit sedare diam nonummy nibh euismod estat tinciduntal ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minimatisa veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl. Name liber tempor cum soluta nobis eleifend option congue nihil imperdietes</p>
                      <h6 className="mt-3 mb-0"><a className="button" href="blog_detail.html"><i className="fa fa-long-arrow-right align-middle me-1" /> Read More</a></h6>
                    </div>
                  </div>
                  <div className="blog_1l1 mt-4">
                    <div className="popular_2i1lm position-relative clearfix">
                      <div className="popular_2i1lm1 clearfix">
                        <div className="grid">
                          <figure className="effect-jazz mb-0">
                            <a href="blog_detail.html"><img src="production house/img/3.jpg" height={450} className="w-100" alt="img25" /></a>
                          </figure>
                        </div>
                      </div>
                      <div className="popular_2i1lm2 position-absolute top-0 w-100 text-center clearfix">
                        <ul>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-link col_red" /></a></li>
                          <li className="d-inline-block"><a href="blog_detail.html"><i className="fa fa-search col_red" /></a></li>
                        </ul>
                      </div>
                    </div>
                    <div className="blog_1l1i mt-3">
                      <h5><i className="fa fa-folder-open col_red me-1" /> Movie News</h5>
                      <h2 className="mt-3"><a className="col_red" href="blog_detail.html">Company Foundation Provides  Grant to Emory Law	</a></h2>
                      <h6 className="fw-normal mt-3 col_light">
                        <span><i className="fa fa-clock-o me-1 align-middle col_red" /> 14 December 2021</span>
                        <span className="ms-3"><i className="fa fa-user me-1 align-middle col_red" /> Admin</span>
                        <span className="ms-3"><i className="fa fa-comment me-1 align-middle col_red" /> Comment</span>
                      </h6>
                      <p className="mt-3">Consectetuer adipiscing elit sedare diam nonummy nibh euismod estat tinciduntal ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad minimatisa veniam, quis nostrud exerci tation ullamcorper suscipit lobortis nisl. Name liber tempor cum soluta nobis eleifend option congue nihil imperdietes</p>
                      <h6 className="mt-3 mb-0"><a className="button" href="blog_detail.html"><i className="fa fa-long-arrow-right align-middle me-1" /> Read More</a></h6>
                    </div>
                  </div>
                  <div className="pages">
                    <div className="col-md-12">
                      <ul className="mb-0">
                        <li><a href="blog_detail.html"><i className="fa fa-chevron-left" /></a></li>
                        <li><a className="act" href="blog_detail.html">1</a></li>
                        <li><a href="blog_detail.html">2</a></li>
                        <li><a href="blog_detail.html">3</a></li>
                        <li><a href="blog_detail.html">4</a></li>
                        <li><a href="blog_detail.html">5</a></li>
                        <li><a href="blog_detail.html">6</a></li>
                        <li><a href="blog_detail.html"><i className="fa fa-chevron-right" /></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>   
              <div className="col-md-4">
                <div className="blog_1r">
                  <div className="blog_1r1 p-4">
                    <h4>Main <span className="col_red">Menu</span></h4>
                    <hr className="line mb-4" />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Audio <span className="pull-right">(12)</span></a></h6>
                    <hr />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Magazine <span className="pull-right">(03)</span></a></h6>
                    <hr />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> News <span className="pull-right">(08)</span></a></h6>
                    <hr />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Photoshop <span className="pull-right">(12)</span></a></h6>
                    <hr />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Technical <span className="pull-right">(07)</span></a></h6>
                    <hr />
                    <h6><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Uncategorized <span className="pull-right">(09)</span></a></h6>
                    <hr />
                    <h6 className="mb-0"><a href="blog_detail.html"><i className="fa fa-chevron-right me-1 col_red font_12" /> Video <span className="pull-right">(04)</span></a></h6>
                  </div>
                  <div className="blog_1r1 p-4 mt-4">
                    <h4>Sidebar  <span className="col_red">News</span></h4>
                    <hr className="line mb-4" />
                    <div className="blog_1r1i row">
                      <div className="col-md-4 col-4">
                        <div className="blog_1r1il">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="blog_detail.html"><img src="production house/img/4.jpg" className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 ps-0 col-8">
                        <div className="blog_1r1ir">
                          <h6 className="mb-1"><a className="col_red" href="blog_detail.html">Company Foundation Provides</a></h6>
                          <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                        </div>
                      </div>
                    </div><hr />
                    <div className="blog_1r1i row">
                      <div className="col-md-4 col-4">
                        <div className="blog_1r1il">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="blog_detail.html"><img src="production house/img/5.jpg" className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 ps-0 col-8">
                        <div className="blog_1r1ir">
                          <h6 className="mb-1"><a className="col_red" href="blog_detail.html">Company Foundation Provides</a></h6>
                          <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                        </div>
                      </div>
                    </div><hr />
                    <div className="blog_1r1i row">
                      <div className="col-md-4 col-4">
                        <div className="blog_1r1il">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="blog_detail.html"><img src="production house/img/6.jpg" className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 ps-0 col-8">
                        <div className="blog_1r1ir">
                          <h6 className="mb-1"><a className="col_red" href="blog_detail.html">Company Foundation Provides</a></h6>
                          <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                        </div>
                      </div>
                    </div><hr />
                    <div className="blog_1r1i row">
                      <div className="col-md-4 col-4">
                        <div className="blog_1r1il">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="blog_detail.html"><img src="production house/img/7.jpg" className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8 ps-0 col-8">
                        <div className="blog_1r1ir">
                          <h6 className="mb-1"><a className="col_red" href="blog_detail.html">Company Foundation Provides</a></h6>
                          <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_1r1 p-4 mt-4">
                    <h4>Accordion  <span className="col_red">Module</span></h4>
                    <hr className="line mb-4" />
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingOne">
                          <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Vestibulum Ante Ipsum Prin Orci
                          </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Lorem ipsum dolor sit amet consec tetuer adipiscing elit Suspendissete justo consec tetuer elite.consectetuer adipiscing hendrerit augue .
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingTwo">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            Cras Eleifend Lacus Ullamcorper
                          </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Lorem ipsum dolor sit amet consec tetuer adipiscing elit Suspendissete justo consec tetuer elite.consectetuer adipiscing hendrerit augue .
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header" id="headingThree">
                          <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                            Suspendisse Lacinia Turpis Convallis
                          </button>
                        </h2>
                        <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                          <div className="accordion-body">
                            Lorem ipsum dolor sit amet consec tetuer adipiscing elit Suspendissete justo consec tetuer elite.consectetuer adipiscing hendrerit augue .
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="blog_1r1 p-4 mt-4">
                    <h4>Login   <span className="col_red">Form</span></h4>
                    <hr className="line mb-4" />
                    <div className="input-group input-group-merge">
                      <div className="input-group-text bg-transparent text-white border-right-0" data-password="false">
                        <span className="fa fa-user" />
                      </div>
                      <input type="username" id="username" className="form-control bg-transparent" placeholder="Username" />
                    </div>
                    <div className="input-group input-group-merge mt-3">
                      <div className="input-group-text bg-transparent text-white border-right-0" data-password="false">
                        <span className="fa fa-user" />
                      </div>
                      <input type="password" id="username" className="form-control bg-transparent" placeholder="password" />
                    </div>
                    <div className="form-check mt-3">
                      <input type="checkbox" className="form-check-input" id="checkmeout0" />
                      <label className="form-check-label" htmlFor="checkmeout0">Remember Me</label>
                    </div>
                    <h6 className="mt-3"><a className="button" href="blog_detail.html"><i className="fa fa-long-arrow-right align-middle me-1" /> Login</a></h6>
                    <h6 className="mt-3"><a className="col_red" href="blog_detail.html">Create an account <i className="fa fa-long-arrow-right ms-1" /></a></h6>
                    <h6 className="mt-3"><a className="col_red" href="blog_detail.html">Forgot your username? </a></h6>
                    <h6 className="mt-3 mb-0"><a className="col_red" href="blog_detail.html">Forgot your password? </a></h6>
                  </div>
                  <div className="blog_1r1 p-4 mt-4">
                    <h4>Popular   <span className="col_red">Tags</span></h4>
                    <hr className="line mb-4" />
                    <ul className="mb-0">
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Analyze</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Audio</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Blog</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Business</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Creative</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Design</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Experiment</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">News</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Expertize</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Express</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Share</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Sustain</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Video</a></li>
                      <li className="d-inline-block"><a className="d-block" href="blog_detail.html">Youtube</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="footer">
          <div className="footer_m clearfix">
            <div className="container">
              <div className="row footer_1">
                <div className="col-md-4">
                  <div className="footer_1i">
                    <h3><a className="text-white" href="index.html"><i className="fa fa-video-camera col_red me-1" /> Planet</a></h3>
                    <p className="mt-3">Lorem ipsum dolor sit amet consect etur adi pisicing elit sed do eiusmod tempor incididunt. Lorem ipsum dolor sit amet consect etur. </p>
                    <h6 className="fw-normal"><i className="fa fa-map-marker fs-5 align-middle col_red me-1" /> 5311 Ceaver Sidge Td.
                      Pakland, DE 13507</h6>
                    <h6 className="fw-normal mt-3"><i className="fa fa-envelope fs-5 align-middle col_red me-1" /> info@gmail.com</h6>
                    <h6 className="fw-normal mt-3 mb-0"><i className="fa fa-phone fs-5 align-middle col_red me-1" />  +123 123 456</h6>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_1i">
                    <h4>Flickr <span className="col_red">Stream</span></h4>
                    <div className="footer_1i1 row mt-4">
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/4.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/5.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/6.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/7.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="footer_1i1 row mt-3">
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/8.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/9.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/10.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-3 col-3">
                        <div className="footer_1i1i">
                          <div className="grid clearfix">
                            <figure className="effect-jazz mb-0">
                              <a href="#"><img src="production house/img/11.jpg" height={70} className="w-100" alt="abc" /></a>
                            </figure>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="footer_1i">
                    <h4>Sign  <span className="col_red">Newsletter</span></h4>
                    <p className="mt-3">Subscribe to our newsletter list to get latest news and updates from us</p>
                    <div className="input-group">
                      <input type="text" className="form-control bg-black" placeholder="Email" />
                      <span className="input-group-btn">
                        <button className="btn btn text-white bg_red rounded-0 border-0" type="button">
                          Subscribe</button>
                      </span>
                    </div>
                    <ul className="social-network social-circle mb-0 mt-4">
                      <li><a href="#" className="icoRss" title="Rss"><i className="fa fa-instagram" /></a></li>
                      <li><a href="#" className="icoFacebook" title="Facebook"><i className="fa fa-facebook" /></a></li>
                      <li><a href="#" className="icoTwitter" title="Twitter"><i className="fa fa-twitter" /></a></li>
                      <li><a href="#" className="icoGoogle" title="Google +"><i className="fa fa-youtube" /></a></li>
                      <li><a href="#" className="icoLinkedin" title="Linkedin"><i className="fa fa-linkedin" /></a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="footer_b" className="pt-3 pb-3 bg_grey">
          <div className="container">
            <div className="row footer_1">
              <div className="col-md-8">
                <div className="footer_1l">
                  <p className="mb-0">© 2013 Your Website Name. All Rights Reserved | Design by <a className="col_red" href="http://www.templateonweb.com">TemplateOnWeb</a></p>
                </div>
              </div>
              <div className="col-md-4">
                <div className="footer_1r">
                  <ul className="mb-0">
                    <li className="d-inline-block me-2"><a href="#">Home</a></li>
                    <li className="d-inline-block me-2"><a href="#">Features</a></li>
                    <li className="d-inline-block me-2"><a href="#">Pages</a></li>
                    <li className="d-inline-block me-2"><a href="#">Portfolio</a></li>
                    <li className="d-inline-block me-2"><a href="#">Blog</a></li>
                    <li className="d-inline-block"><a href="#">Contact</a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
export default ProductionHouseBlogComponent;
