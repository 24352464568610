import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';
import emailjs from 'emailjs-com';
class ContactComponent extends Component {
  state= {
    name: '',
    email: '',
    service: '',
    country:'',
    city:'',
    event:'',
    mobileNumber: '',
    specialRequest: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    return (
      <div>

        <NavBar />

        {/* Navbar End */}
        {/* Modal Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center animated">
          <h1 className="display-1">Contact</h1>
        </div>

        {/* Modal Search End */}
        {/* Hero Start */}

        {/* Hero End */}
        {/* Contact Start */}
        <div className="container-fluid contact py-6 wow bounceInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="p-5 bg-light rounded contact-form">
              <div className="row g-4">
                <div className="col-12">
                  <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Get in touch</small>
                  <h1 className="display-5 mb-0">"Get in Touch"</h1>
                </div>
                <div className="col-md-6 col-lg-7">
                <form id="form" onSubmit={this.handleSubmit}>
                    <input type="text" id='name'  name="name" className="w-100 form-control p-3 mb-4 border-primary bg-light" placeholder="Your Name" required />
                    <input type="email" id='email'  name="email" className="w-100 form-control p-3 mb-4 border-primary bg-light" placeholder="Enter Your Email"  required/>
                    <input type="text" id='mobileNumber ' name='mobileNumber' className="w-100 form-control p-3 mb-4 border-primary bg-light" placeholder="Enter Your Mobile Number" required/>
                    <select name="country" id="country"  required  style={{ width: '100%', height: 'calc(2.250em + 20px)', marginBottom: '1.5rem', borderColor: '#F3525A', color: '#495057', borderRadius: '0.25rem', paddingLeft: '0.75rem' }} >
                      <option value="India">India</option>
                    </select>
                    <select name="City" id="city"  required style={{ width: '100%', height: 'calc(2.50rem + 20px)', marginBottom: '1.5rem', borderColor: '#F3525A', color: '#495057', borderRadius: '0.25rem', paddingLeft: '0.75rem' }} >
                      <option value="Agra">Select your City</option>
                      <option value="Agra">Agra</option>
                      <option value="Ahmedabad">Ahmedabad</option>
                      <option value="Ajmer">Ajmer</option>
                      <option value="Allahabad">Allahabad</option>
                      <option value="Aligarh">Aligarh</option>
                      <option value="Amravati">Amravati</option>
                      <option value="Amritsar">Amritsar</option>
                      <option value="Aurangabad">Aurangabad</option>
                      <option value="Bangalore">Bangalore</option>
                      <option value="Bhopal">Bhopal</option>
                      <option value="Bhubaneswar">Bhubaneswar</option>
                      <option value="Chandigarh">Chandigarh</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Coimbatore">Coimbatore</option>
                      <option value="Cuttak">Cuttak</option>
                      <option value="Dehradun">Dehradun</option>
                      <option value="Delhi">Delhi </option>
                      <option value="Faridabad">Faridabad</option>
                      <option value="Ghaziabad">Ghaziabad</option>
                      <option value="Goa">Goa</option>
                      <option value="Gorakhpur">Gorakhpur</option>
                      <option value="Gurgaon">Gurgaon</option>
                      <option value="Guwahati">Guwahati</option>
                      <option value="Hubli">Hubli</option>
                      <option value="Imphal">Imphal</option>
                      <option value="Indore">Indore</option>
                      <option value="Jaipur">Jaipur</option>
                      <option value="Jalandhar">Jalandhar</option>
                      <option value="Jamshedpur">Jamshedpur</option>
                      <option value="Jodhpur">Jodhpur</option>
                      <option value="Kanpur">Kanpur</option>
                      <option value="Kochi">Kochi</option>
                      <option value="Kolkata">Kolkata</option>
                      <option value="Kozhikode">Kozhikode</option>
                      <option value="Lucknow">Lucknow</option>
                      <option value="Ludhiana">Ludhiana</option>
                      <option value="Madurai">Madurai</option>
                      <option value="Mangalore">Mangalore</option>
                      <option value="Meerut">Meerut</option>
                      <option value="Mumbai">Mumbai</option>
                      <option value="Mysore">Mysore</option>
                      <option value="Nagpur">Nagpur</option>
                      <option value="Nashik">Nashik</option>
                      <option value="Noida">Noida</option>
                      <option value="Patna">Patna</option>
                      <option value="Pimpri-Chinchwad">Pimpri-Chinchwad</option>
                      <option value="Pondicherry">Pondicherry</option>
                      <option value="Pune">Pune</option>
                      <option value="Raipur">Raipur</option>
                      <option value="Rajkot">Rajkot</option>
                      <option value="Ranchi">Ranchi</option>
                      <option value="Salem">Salem</option>
                      <option value="Shillong">Shillong</option>
                      <option value="Shimla">Shimla</option>
                      <option value="Siliguri">Siliguri</option>
                      <option value="Srinagar">Srinagar</option>
                      <option value="Surat">Surat</option>
                      <option value="Thane">Thane</option>
                      <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                      <option value="Tirupati">Tirupati</option>
                      <option value="Udaipur">Udaipur</option>
                      <option value="Vadodara">Vadodara</option>
                      <option value="Varanasi">Varanasi</option>
                      <option value="Vijayawada">Vijayawada</option>
                      <option value="Visakhapatnam">Visakhapatnam</option>
                    </select>
                    <select name="service" id="service"  required style={{ width: '100%', height: 'calc(2.50rem + 20px)', marginBottom: '1.5rem', borderColor: '#F3525A', color: '#495057', borderRadius: '0.25rem', paddingLeft: '0.75rem' }}>
                      <option value="default">Select an option</option>
                      <option value="Hotel">Hotel</option>
                      <option value="Banquet Hall">Banquet Hall</option>
                      <option value="Events">Events</option>
                      <option value="Production Line">Production Line</option>
                    </select>



                    <div>
                    <button   id="button" className="btn btn-primary w-100 py-3" type="submit">Submit Now</button>
                    </div>
                  </form></div>

                <div className="col-md-6 col-lg-5">
                  <div>
                    <div className="d-inline-flex w-100 border border-primary p-4 rounded mb-4">
                      <i className="fas fa-map-marker-alt fa-2x text-primary me-4" />
                      <div className>
                        <h4>Address</h4>
                        <p> Head Office No. A55, First Floor, Virvani Plaza, East Street, Camp Pune 411001.</p>
                      </div>
                    </div>
                    <div className="d-inline-flex w-100 border border-primary p-4 rounded mb-4">
                      <i className="fas fa-envelope fa-2x text-primary me-4" />
                      <div className>
                        <h4>Mail Us</h4>
                        <p className="mb-2">providenceinternational80@gmail.com</p>
                        <p className="mb-0" />
                      </div>
                    </div>
                    <div className="d-inline-flex w-100 border border-primary p-4 rounded">
                      <i className="fa fa-phone-alt fa-2x text-primary me-4" />
                      <div className>
                        <h4>Telephone</h4>
                        <p className="mb-2">+91 9595264988</p>
                        <p className="mb-0"></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact End */}
        {/* Footer Start */}
        <FooterComponent></FooterComponent>
        {/* Footer End */}
        {/* Copyright Start */}

        {/* Copyright End */}
        {/* Back to Top */}
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div>
    );
  }
}

export default ContactComponent;
