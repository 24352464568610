import React, { Component } from 'react';

class BanquetFooterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
    //   <div className="container-fluid footer py-6 my-6 mb-0 bg-light wow bounceInUp" data-wow-delay="0.1s">
    //   <div className="container">
    //     <div className="row">
    //       <div className="col-lg-3 col-md-6">
    //         <div className="footer-item">
    //           <h3 className="text-primary">Providence<span className="text-dark">International</span></h3>
    //           <p className="lh-lg mb-4">Indulge in a feast of flavors with our exceptional catering services. 
    //             Your satisfaction is our commitment — let us cater to your culinary desires and make your occasions truly special.</p>
    //           <div className="footer-icon d-flex">
    //             <a className="btn btn-primary btn-sm-square me-2 rounded-circle" href="https://www.facebook.com/profile.php?id=100090355260753&mibextid=ZbWKwL"><i className="fab fa-facebook-f" /></a>
    //             <a href="https://instagram.com/providenceinternational.co?igshid=YmMyMTA2M2Y=" className="btn btn-primary btn-sm-square me-2 rounded-circle"><i className="fab fa-instagram" /></a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-3 col-md-6">
    //         <div className="footer-item">
    //           <h4 className="mb-4">Special Facilities</h4>
    //           <div className="d-flex flex-column align-items-start">
    //             <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Cheese Burger</a>
    //             <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Sandwich</a>
    //             <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Panner Burger</a>
    //             <a className="text-body mb-3" href><i className="fa fa-check text-primary me-2" />Special Sweets</a>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-3 col-md-6">
    //         <div className="footer-item">
    //           <h4 className="mb-4">Contact Us</h4>
    //           <div className="d-flex flex-column align-items-start">
    //             <p><i className="fa fa-map-marker-alt text-primary me-2" /> Head Officde No. A55, First Floor, Virvani Plaza, East Street, Camp Pune 411001.</p>
    //             <p><i className="fa fa-phone-alt text-primary me-2" />9595264988</p>
    //             <p><i className="fas fa-envelope text-primary me-2" />providenceinternational180@gmail.com</p>
    //             <p><i className="fa fa-clock text-primary me-2" /> Mon - Fri: 9am - 6pm
    //               Sat: 10am - 2pm
    //               Sun: Closed</p>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="col-lg-3 col-md-6">
    //         <div className="footer-item">
    //           <h4 className="mb-4">Social Gallery</h4>
    //           <div className="row g-2">
    //             <div className="col-4">
    //               <img src="img/menu-01.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //             <div className="col-4">
    //               <img src="img/menu-02.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //             <div className="col-4">
    //               <img src="img/menu-03.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //             <div className="col-4">
    //               <img src="img/menu-04.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //             <div className="col-4">
    //               <img src="img/menu-05.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //             <div className="col-4">
    //               <img src="img/menu-06.jpg" className="img-fluid rounded-circle border border-primary p-2" alt="" />
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
    <div>
    <section className="ts-map-direction wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="400ms">
    <div className="container">
      <div className="row">
        <div className="col-lg-5">
          <h2 className="column-title" >
            <span style={{fontFamily:'bold',color:'black', fontSize:'30px'}}>Reach us</span>
          Get in Touch with us at:
          </h2>
          <div className="ts-map-tabs">
            
            {/* Tab panes */}
            <div className="tab-content direction-tabs">
              <div role="tabpanel" className="tab-pane active" id="profile">
                <div className="direction-tabs-content">
               
                  <p className="derecttion-vanue" style={{color:'black'}}>
                  Head Office No. A55, First Floor, Virvani Plaza, East Street, Camp Pune 411001.<br />
                    
                  </p>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="contact-info-box">
                        <h2>More Information </h2>
                        
                        <p style={{color:'black'}}><strong>Phone:</strong> 9595264988</p>
                        <p style={{color:'black'}}><strong>Email: </strong> providenceinternational180@gmail.com</p>
                      </div>
                    </div>
                    
                  </div>{/* row end*/}
                </div>{/* direction tabs end*/}
              </div>{/* tab pane end*/}
              <div role="tabpanel" className="tab-pane fade" id="buzz">
                <div className="direction-tabs-content">
                  <h3>Brighton Waterfront Hotel, Brighton, London</h3>
                  <p className="derecttion-vanue">
                    1Hd- 50, 010 Avenue, NY 90001<br />
                    United States
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Tickets info </h3>
                        <p><strong>Name:</strong> Ronaldo König</p>
                        <p><strong>Phone:</strong> 009-215-5595</p>
                        <p><strong>Email: </strong> info@example.com</p>
                      </div>
                    </div>
                    
                  </div>{/* row end*/}
                </div>{/* direction tabs end*/}
              </div>
              <div role="tabpanel" className="tab-pane fade" id="references">
                <div className="direction-tabs-content">
                 
                  <p className="derecttion-vanue">
                    1Hd- 50, 010 Avenue, NY 90001<br />
                    United States
                  </p>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Tickets info </h3>
                        <p><strong>Name:</strong> Ronaldo König</p>
                        <p><strong>Phone:</strong> 009-215-5595</p>
                        <p><strong>Email: </strong> info@example.com</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="contact-info-box">
                        <h3>Programme Details </h3>
                        <p><strong>Name:</strong> Ronaldo König</p>
                        <p><strong>Phone:</strong> 009-215-5595</p>
                        <p><strong>Email: </strong> info@example.com</p>
                      </div>
                    </div>
                  </div>{/* row end*/}
                </div>{/* direction tabs end*/}
              </div>
            </div>
          </div>{/* map tabs end*/}
        </div>{/* col end*/}
        <div className="col-lg-6 offset-lg-1">
          <div className="ts-map">
            <div className="mapouter">
            <div className="mapouter">
  <div className="col-md-12">
 
              <iframe src="https://maps.google.com/maps?width=600&height=400&hl=en&q=Office No. 6, First Floor, Virvani Plaza, East St, Pune Cantonment, Pune, Maharashtra 411001, India&t=&z=13&ie=UTF8&iwloc=B&output=embed" height={450} style={{border: 0, width: '100%'}} allowFullScreen />
        
  </div>
  <style
    dangerouslySetInnerHTML={{
      __html:
        ".mapouter{position:relative;text-align:right;width:100%;height:400px;}.gmap_canvas {overflow:hidden;background:none!important;width:100%;height:400px;}.gmap_iframe {height:400px!important;}",
    }}
  />
</div>
            </div>
          </div>
        </div>
      </div>{/* col end*/}
    </div>{/* container end*/}
    <div className="speaker-shap">
      <img className="shap1 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="300ms" src="allimage/shap/Direction_memphis3.png" alt="" />
      <img className="shap2 wow fadeInRight" data-wow-duration="1.5s" data-wow-delay="400ms" src="allimage/shap/Direction_memphis2.png" alt="" />
      <img className="shap3 wow fadeInLeft" data-wow-duration="1.5s" data-wow-delay="500ms" src="allimage/shap/Direction_memphis4.png" alt="" />
      <img className="shap4 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="600ms" src="allimage/shap/Direction_memphis1.png" alt="" />
    </div>
  </section>
    <div className="footer-area">
    {/* ts-book-seat start*/}
    <section className="ts-book-seat" style={{backgroundImage: 'url(allimage/book_seat_img.jpg)'}}>
      <div className="container">
        <div className="row">
          <div className="col-lg-8 mx-auto">
            <div className="book-seat-content text-center mb-70">
              
              
            </div>{/* book seat end*/}
          </div>{/* col end*/}
        </div>{/* row end*/}
        <div className="ts-footer-newsletter">
          <div className="ts-newsletter" style={{backgroundImage: 'url(allimage/shap/subscribe_pattern.png)'}}>
            <div className="row">
              <div className="col-lg-6 mx-auto">
                <div className="ts-newsletter-content">
                  <h2 className="section-title">
                    <span>Write to us!!!</span>
                    Want something extra?
                  </h2>
                </div>
                <div className="newsletter-form">
                  <form action="#" method="post" className="media align-items-end">
                    <div className="email-form-group media-body">
                      <input type="email" name="email" id="newsletter-form-email" className="form-control" placeholder="Your Email" autoComplete="off" required />
                    </div>
                    <div className="d-flex ts-submit-btn">
                      <button className="btn">Submit</button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>{/* container end*/}
    </section>
    {/* book seat  end*/}
    {/* footer start*/}
    <footer className="ts-footer">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <div className="footer-menu text-center mb-25">
                    <ul>
                      <li>
                        <a href="https://www.facebook.com/profile.php?id=100090355260753&mibextid=ZbWKwL"><i className="fa fa-facebook" />Facebook</a>
                      </li>
                      <li>
                        <a href="https://instagram.com/providenceinternational.co?igshid=YmMyMTA2M2Y="><i className="fa fa-twitter" />Twitter</a>
                      </li>
                      {/* <li>
                        <a href="#"><i className="fa fa-google-plus" /></a>
                      </li> */}
                    
                    </ul>
                  </div>
                  {/* footer social end*/}
                  {/* <div className="footer-menu text-center mb-25">
                    <ul>
                      <li><a href="#">About Eventime</a></li>
                      <li><a href="#">Blog</a></li>
                      <li><a href="#">Contact</a></li>
                      <li><a href="#">Tickets</a></li>
                      <li><a href="#">Venue</a></li>
                    </ul>
                  </div>footer menu end */}
                  
                </div>
              </div>
            </div>
          </footer>
    {/* footer end*/}
    <div className="BackTo">
      <a href="#" className="fa fa-angle-up" aria-hidden="true" />
    </div>
  </div>
  </div>
    );
  }
}
export default BanquetFooterComponent;