import React, { Component } from 'react'
import NavBar from './NavBar';

import FooterComponent from './FooterComponent';
import emailjs from 'emailjs-com';




class HomePageComponent extends Component {
  state = {
    name: '',
    email: '',
    service: '',
    mobileNumber: '',
    specialRequest: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    return (
      
       <div>
        <NavBar/>
                {/* Navbar End */}
        {/* Modal Search Start */}
        <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true" style={{fontFamily:'initial'}}>
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Search End */}
        {/* Hero Start */}
        <div className="container-fluid bg-light py-6 my-6 mt-0">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-7 col-md-12">
                <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-4 animated bounceInDown">Welcome to Providence International</small>
                <h1 style={{fontSize:'50px'}} >  Book <span className="text-primary">Providence</span>International For You!!!!!</h1>
              
              </div>
              <div className="col-lg-5 col-md-12">
                <img src="allimage/hero.png" className="img-fluid rounded animated zoomIn" alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Hero End */}
        {/* About Satrt */}
        <div className="container-fluid py-6" style={{marginTop:'-100px'}}>
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                <img src="allimage/CEO.webp" className="img-fluid rounded" alt="" />
              </div>
              <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
                <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">About Us</small>
                <h1 className="display-5 mb-4">We make Tastebuds Happy</h1>
                <p className="mb-4"   style={{color:'black'}}>"At Providence International, we pride ourselves on delivering exceptional culinary experiences for every occasion. 
                  With a passion for innovative and delectable cuisine, our team is dedicated to creating unforgettable moments through expertly crafted menus, impeccable service, and attention to detail. 
                  Whether it's a wedding, corporate event, or special celebration, we bring a blend of creativity and culinary expertise to ensure a delightful and memorable dining experience for you and your guests.
                  Elevate your event with Providence International where every dish tells a story of flavor, freshness, and excellence. Conti Foods is a premier provider of culinary delights, specializing in continental cuisine crafted by our team of seasoned chefs. With a rich history of serving NATO forces in Afghanistan and supporting United Nations operations in Africa, we bring a wealth of experience and expertise to every dish we create. Our commitment to excellence and unwavering dedication to quality make us a trusted choice for unforgettable dining experiences."
                </p>
                <div className="row g-4 text-dark mb-5">
                  <div className="col-sm-6">
                    🍽️ Culinary Mastery
                  </div>
                  <div className="col-sm-6">
                    🎉 Tailored Elegance
                  </div>
                  <div className="col-sm-6">
                    🌟 Impeccable Hospitality
                  </div>
                  <div className="col-sm-6">
                    🥂 Unforgettable Moments
                  </div>
                  <div className="col-sm-6">
                    🎊 Freshness and Excellence
                  </div>
                  <div className="col-sm-6">
                    🤝 Providence Promise
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Fact Start*/}
        <div className="container-fluid faqt py-6">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-lg-7">
                <div className="row g-4">
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.3s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-users fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">900</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Happy Customers</p>
                    </div>
                  </div>
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.5s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-users-cog fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">107</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Expert Chefs</p>
                    </div>
                  </div>
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.7s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-check fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">253</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Events Complete</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                <div className="video">
                  <button type="button" className="btn btn-play" data-bs-toggle="modal" data-src="https://youtu.be/sx37lMY_V6Q?si=EgBMuEA9oZPbi4nH" data-bs-target="#videoModal">
                    <span />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Video */}
        <div className="modal fade" id="videoModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                {/* 16:9 aspect ratio */}
                <div className="ratio ratio-16x9">
                  {/* <iframe className="embed-responsive-item" src id="video" allowFullScreen allowscriptaccess="always" allow="autoplay" /> */}
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/sx37lMY_V6Q?si=EgBMuEA9oZPbi4nH" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fact End */}
        {/* International Start */}
        <div className="container-fluid service py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Services</small>
              <h1 className="display-5 mb-5">What We Offer</h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.1s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-cheese fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Wedding Services</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.3s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-pizza-slice fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Corporate Catering</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.5s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-hotdog fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Cocktail Reception</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.7s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-hamburger fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Bento Catering</h4>
                   
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.1s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-wine-glass-alt fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Party</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.3s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-walking fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Home Delivery</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.5s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-wheelchair fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Sit-down Catering</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-12 wow bounceInUp" data-wow-delay="0.7s">
                <div className="bg-light rounded service-item">
                  <div className="service-content d-flex align-items-center justify-content-center p-4">
                    <div className="service-content-icon text-center">
                      <i className="fas fa-utensils fa-7x text-primary mb-4" />
                      <h4 className="mb-3">Buffet Catering</h4>
                      <a href="#" className="btn btn-primary px-4 py-2 rounded-pill">Read More</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* International End */}
        {/* Events Start */}
        {/* Events Start */}
        <div className="container-fluid event py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Latest Events</small>
              <h1 className="display-5 mb-5">Our Social &amp; Professional Events Gallery</h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                    <span className="text-dark" style={{width: '150px'}}>All Events</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                    <span className="text-dark" style={{width: '150px'}}>Wedding</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                    <span className="text-dark" style={{width: '150px'}}>Corporate</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                    <span className="text-dark" style={{width: '150px'}}>Mocktail</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                    <span className="text-dark" style={{width: '150px'}}>Buffet</span>
                  </a>
                </li>
              </ul>
              <style dangerouslySetInnerHTML={{__html: "\n                        .event-img img {\n                            width: 100%; /* Make sure the image takes the full width of its container */\n                            height: 200px; /* Set a fixed height for all images */\n                            object-fit: cover; /* Preserve aspect ratio while covering the container */\n                        }\n                    " }} />
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/wedding1.jpg" data-lightbox="event-1" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.3s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/corporate1.jpg" data-lightbox="event-2" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.5s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding3.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/wedding3.jpg" data-lightbox="event-3" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.7s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/buffet1.jpg" data-lightbox="event-4" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.1s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/cocktail2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="allimage/cocktail2.jpg" data-lightbox="event-5" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.3s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding2.webp" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/wedding2.webp" data-lightbox="event-6" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.5s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/buffet2.jpg" data-lightbox="event-7" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3 wow bounceInUp" data-wow-delay="0.7s">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/corporate2.jpg" data-lightbox="event-17" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <style dangerouslySetInnerHTML={{__html: "\n                            .event-img img {\n                                width: 100%; /* Make sure the image takes the full width of its container */\n                                height: 200px; /* Set a fixed height for all images */\n                                object-fit: cover; /* Preserve aspect ratio while covering the container */\n                            }\n                        " }} />
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-8" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding 3.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/02.jpg" data-lightbox="event-8" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/03.jpg" data-lightbox="event-8" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/wedding2.webp" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Wedding</h4>
                              <a href="allimage/04.jpg" data-lightbox="event-9" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-3" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-10" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-11" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate3.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-11" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/corporate4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Corporate</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-11" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-4" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/cocktail1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-12" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/cocktail2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-13" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/cocktail 3.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-13" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/cocktail4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Cocktail</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-13" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-5" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      <div className="row g-4">
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet1.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-14" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet2.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-15" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet3.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-15" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 col-lg-3">
                          <div className="event-img position-relative">
                            <img className="img-fluid rounded w-100" src="allimage/buffet4.jpg" alt="" />
                            <div className="event-overlay d-flex flex-column p-4">
                              <h4 className="me-auto">Buffet</h4>
                              <a href="allimage/01.jpg" data-lightbox="event-15" className="my-auto"><i className="fas fa-search-plus text-dark fa-2x" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Events End */}
        {/* Menu Start */}
        <div className="container-fluid menu bg-light py-6 my-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Menu</small>
              <h1 className="display-5 mb-5">Our Most Popular Menu </h1>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill active" data-bs-toggle="pill" href="#tab-6">
                    <span className="text-dark" style={{width: '150px'}}>Welcome Drinks</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-7">
                    <span className="text-dark" style={{width: '150px'}}>Main Course</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-8">
                    <span className="text-dark" style={{width: '150px'}}>Mocktails</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-9">
                    <span className="text-dark" style={{width: '150px'}}>Soups</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-white rounded-pill" data-bs-toggle="pill" href="#tab-10">
                    <span className="text-dark" style={{width: '150px'}}>Our Special</span>
                  </a>
                </li>
              </ul>
              <style dangerouslySetInnerHTML={{__html: "\n                        .menu-item img {\n                          width: 50px; /* Set your desired width */\n                          height: 50px; /* Set your desired height */\n                          object-fit: cover; /* Maintain aspect ratio */\n                        }\n                      " }} />
              <div className="tab-content">
                <div id="tab-6" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.1s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/Guava Fruit Juice Recipe.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Delightful &amp; Refreshing Guava </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.2s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/Coconut-Lime-Cocktail (1).jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Coconut &amp; Lime Sparkler</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.3s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/orangehoney.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Orange Honey Lassi</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.4s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cocout thandai.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Coconut Thandai</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.5s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/aam panna.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Aam panna, Kairi panna</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.6s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mangococonut lassi.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mango Coconut Lassi</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.7s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/watermelon mojito.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Watermelon Mojito</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 wow bounceInUp" data-wow-delay="0.8s">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/pineappleice.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Pineaaple ice cube, Strawberry mint</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-7" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/rotis.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Rotis</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/rice.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special Rice</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/chinese.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Veg Chinese</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/tandoori chicken.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Tandoor Chicken</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/biryani.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Biryani</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/indian.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mutton.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/indian chicken.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian (Chicken)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-8" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mindful.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>The mindful Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cocounr mocktail.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Orange, Pineapple &amp; coconut mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/party.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Party Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/jaljeera.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Jaljeera (Indian Special)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/summer.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Refreshing Summer Mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/purple deli.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Purple Delight mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/watermelon.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div c lass="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Watermelon mint mocktail</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/lemonde.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Indian Style Lemonde</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-9" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/specialsoup.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Providence International Special Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mixveg.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mix Veg </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/tomatobased.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Tomato Based Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/asian.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Roasted red and black pepper Tomato with asian cheese soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/creamy.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Creamy Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/cornleek.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Corn and Leek Bisque</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/curried.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Curried Pumpkin Coconut Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/mushroom.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Creamy Mushroom Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div id="tab-10" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/international.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Providence International Special Non-Veg Soup</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/bbqcontin.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special BBQ (Continental)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/bbqindian.webp" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Special BBQ(Indian)</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/wings.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>BBQ Chicken Wings</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/muttonsteak'.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton Steak</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/leg.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Chicken Leg Tandoori</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/ran.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Mutton Ran</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="menu-item d-flex align-items-center">
                        <img className="flex-shrink-0 img-fluid rounded-circle" src="allimage/fish.jpg" alt="" />
                        <div className="w-100 d-flex flex-column text-start ps-4">
                          <div className="d-flex justify-content-between border-bottom border-primary pb-2 mb-2">
                            <h4>Fish Tandoori</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Book Us Start */}
        <div className="container-fluid contact py-6 wow bounceInUp" data-wow-delay="0.1s">
          <div className="container">
            <div className="row g-0">
              <div className="col-1">
                <img src="allimage/background-site.jpg" className="img-fluid h-100 w-100 rounded-start" style={{objectFit: 'cover', opacity: '0.7'}} alt="" />
              </div>
              <div className="col-10">
                <div className="border-bottom border-top border-primary bg-light py-5 px-4">
                  <div className="text-center">
                    <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Book Us</small>
                    <h1 className="display-5 mb-5">Where you want Our Services</h1>
                  </div>
                  <form id="form" onSubmit={this.handleSubmit}>
                    <div className="row g-4 form">
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="country" id='country'>
                          <option selected>Select Country</option>
                          <option value1 >India</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="city" id="city"  required>
                          <option selected>Select City</option>
                          <option value="Agra">Agra</option>
                          <option value="Ahmedabad">Ahmedabad</option>
                          <option value="Ajmer">Ajmer</option>
                          <option value="Allahabad">Allahabad</option>
                          <option value="Aligarh">Aligarh</option>
                          <option value="Amravati">Amravati</option>
                          <option value="Amritsar">Amritsar</option>
                          <option value="Aurangabad">Aurangabad</option>
                          <option value="Bangalore">Bangalore</option>
                          <option value="Bhopal">Bhopal</option>
                          <option value="Bhubaneswar">Bhubaneswar</option>
                          <option value="Chandigarh">Chandigarh</option>
                          <option value="Chennai">Chennai</option>
                          <option value="Coimbatore">Coimbatore</option>
                          <option value="Cuttak">Cuttak</option>
                          <option value="Dehradun">Dehradun</option>
                          <option value="Delhi">Delhi </option>
                          <option value="Faridabad">Faridabad</option>
                          <option value="Ghaziabad">Ghaziabad</option>
                          <option value="Goa">Goa</option>
                          <option value="Gorakhpur">Gorakhpur</option>
                          <option value="Gurgaon">Gurgaon</option>
                          <option value="Guwahati">Guwahati</option>
                          <option value="Hubli">Hubli</option>
                          <option value="Imphal">Imphal</option>
                          <option value="Indore">Indore</option>
                          <option value="Jaipur">Jaipur</option>
                          <option value="Jalandhar">Jalandhar</option>
                          <option value="Jamshedpur">Jamshedpur</option>
                          <option value="Jodhpur">Jodhpur</option>
                          <option value="Kanpur">Kanpur</option>
                          <option value="Kochi">Kochi</option>
                          <option value="Kolkata">Kolkata</option>
                          <option value="Kozhikode">Kozhikode</option>
                          <option value="Lucknow">Lucknow</option>
                          <option value="Ludhiana">Ludhiana</option>
                          <option value="Madurai">Madurai</option>
                          <option value="Mangalore">Mangalore</option>
                          <option value="Meerut">Meerut</option>
                          <option value="Mumbai">Mumbai</option>
                          <option value="Mysore">Mysore</option>
                          <option value="Nagpur">Nagpur</option>
                          <option value="Nashik">Nashik</option>
                          <option value="Noida">Noida</option>
                          <option value="Patna">Patna</option>
                          <option value="Pimpri-Chinchwad">Pimpri-Chinchwad</option>
                          <option value="Pondicherry">Pondicherry</option>
                          <option value="Pune">Pune</option>
                          <option value="Raipur">Raipur</option>
                          <option value="Rajkot">Rajkot</option>
                          <option value="Ranchi">Ranchi</option>
                          <option value="Salem">Salem</option>
                          <option value="Shillong">Shillong</option>
                          <option value="Shimla">Shimla</option>
                          <option value="Siliguri">Siliguri</option>
                          <option value="Srinagar">Srinagar</option>
                          <option value="Surat">Surat</option>
                          <option value="Thane">Thane</option>
                          <option value="Thiruvananthapuram">Thiruvananthapuram</option>
                          <option value="Tirupati">Tirupati</option>
                          <option value="Udaipur">Udaipur</option>
                          <option value="Vadodara">Vadodara</option>
                          <option value="Varanasi">Varanasi</option>
                          <option value="Vijayawada">Vijayawada</option>
                          <option value="Visakhapatnam">Visakhapatnam</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="place" id="place" required>
                          <option selected>Select Place</option>
                          <option value={3}>India</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="event" id="event" required>
                          <option selected>Small Event</option>
                          <option value="Event Type">Event Type</option>
                          <option value="Big Event">Big Event</option>
                          <option value="Small Event">Small Event</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="palace" id="palace">
                          <option selected>No. Of Palace</option>
                          <option value="100-200">100-200</option>
                          <option value="300-400">300-400</option>
                          <option value="500-600">500-600</option>
                          <option value="700-800">700-800</option>
                          <option value="900-1000">900-1000</option>
                          <option value="1000+">1000+</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <select className="form-select border-primary p-2" aria-label="Default select example" name="veg" id="veg">
                          <option selected>Vegetarian</option>
                          <option value="Vegetarian">Vegetarian</option>
                        <option value="Non Vegetarian">Non Vegetarian</option>
                        </select>
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <input type="number" className="form-control border-primary p-2" placeholder="Your Contact No." name="mobileNumber" id="mobileNumber"  required />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <input type="date" className="form-control border-primary p-2" placeholder="Select Date" name="date" id="date" required />
                      </div>
                      <div className="col-lg-4 col-md-6">
                        <input type="email" className="form-control border-primary p-2" placeholder="Enter Your Email" name="email" id="email"  required />
                      </div>
                      <div className="col-12 mt-3">
                      <button id="button" className="btn btn-primary w-100" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-1">
                <img src="allimage/background-site.jpg" className="img-fluid h-100 w-100 rounded-end" style={{objectFit: 'cover', opacity: '0.7'}} alt="" />
              </div>
            </div>
          </div>
        </div>
        {/* Book Us End */}
        <style dangerouslySetInnerHTML={{__html: "\n            .team-item img {\n                width: 100%; /* Make sure the image takes the full width of its container */\n                height: 200px; /* Set a fixed height for all images */\n                object-fit: cover; /* Preserve aspect ratio while covering the container */\n            }\n        " }} />
        <div className="container-fluid team py-6">
          <div className="container">
            <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
              <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Gallery</small>
              <h1 className="display-5 mb-5">Our Gallery</h1>
            </div>
            <div className="row g-4">
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.1s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp1.jpg" width={300} height={200} />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp2.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                  
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp7.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp5.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp6.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp10.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp8.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp9.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp11.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp12.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                  
                </div>
              </div>     <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp13.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp14.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wpe.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
               
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp16.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp17.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
               
                </div>
              </div>
              <div className="col-lg-3 col-md-6 wow bounceInUp" data-wow-delay="0.3s">
                <div className="team-item rounded">
                  <img className="img-fluid rounded-top " src="allimage/wp18.jpg" width={300} height={200} alt="" />
                  <div className="team-content text-center py-3 bg-dark rounded-bottom">
                  </div>
                 
                </div>
              </div>
              {/* Testimonial Start */}
       
              {/* Blog Start */}
              <div className="container-fluid blog py-6">
                <div className="container">
                  <div className="text-center wow bounceInUp" data-wow-delay="0.1s">
                    <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">Our Blog</small>
                    <h1 className="display-5 mb-5">Be First Who Read News</h1>
                  </div>
                  <div className="row gx-4 justify-content-center">
                    <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.1s">
                      <div className="blog-item">
                        <div className="overflow-hidden rounded">
                          <img src="allimage/blog-1.jpg" className="img-fluid w-100" alt="" />
                        </div>
                        <div className="blog-content mx-4 d-flex rounded bg-light">
                          <div className="text-dark bg-primary rounded-start">
                            <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                              <p className="fw-bold mb-0">16</p>
                              <p className="fw-bold mb-0">Sep</p>
                            </div>
                          </div>
                          <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.3s">
                      <div className="blog-item">
                        <div className="overflow-hidden rounded">
                          <img src="allimage/blog-2.jpg" className="img-fluid w-100" alt="" />
                        </div>
                        <div className="blog-content mx-4 d-flex rounded bg-light">
                          <div className="text-dark bg-primary rounded-start">
                            <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                              <p className="fw-bold mb-0">16</p>
                              <p className="fw-bold mb-0">Sep</p>
                            </div>
                          </div>
                          <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-4 wow bounceInUp" data-wow-delay="0.5s">
                      <div className="blog-item">
                        <div className="overflow-hidden rounded">
                          <img src="allimage/blog-3.jpg" className="img-fluid w-100" alt="" />
                        </div>
                        <div className="blog-content mx-4 d-flex rounded bg-light">
                          <div className="text-dark bg-primary rounded-start">
                            <div className="h-100 p-3 d-flex flex-column justify-content-center text-center">
                              <p className="fw-bold mb-0">16</p>
                              <p className="fw-bold mb-0">Sep</p>
                            </div>
                          </div>
                          <a href="#" className="h5 lh-base my-auto h-100 p-3">How to get more test in your food from</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Blog End */}
              {/* Footer Start */}
              <FooterComponent></FooterComponent>
              {/* Footer End */}
              {/* Copyright Start */}

              {/* Copyright End */}
              {/* Back to Top */}
                    {/* JavaScript Libraries */}
              {/* Template Javascript */}
            </div></div></div></div>
    );
  }
}

export default HomePageComponent