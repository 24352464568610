import React, { Component } from 'react';
import ManpowerFooterComponent from './ManpowerFooterComponent';
import ManpowerNavBar from './ManpowerNavBar';
import emailjs from 'emailjs-com';

  


class ManpowerHomeComponent  extends Component {
  state= {
    name: '',
    address:'',
  subject: '',
  
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };

  render() {
    return (
     <div>
       <div>
        <div>
          {/* Topbar Start */}
         
          {/* Topbar End */}
          {/* Navbar Start */}
         <ManpowerNavBar></ManpowerNavBar>
          {/* Navbar End */}
          {/* Carousel Start */}
          <div className="container-fluid p-0">
            <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img className="w-100" src="allimage/hyat.jpg" alt="Image" style={{height:'1000px'}}/>
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: '900px'}}>
                      <h1 className="text-white text-uppercase;">Providence International: The Biggest Manpower Suppliers </h1><br/>
                      <h4 className="display-1 text-white mb-md-4">"Crafting Unforgettable Experiences with Expert Manpower"</h4>
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img className="w-100" src="allimage/JWmariot.jpg" alt="Image" style={{height:'1000px'}} />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: '900px'}}>
                      <h5 className="text-white text-uppercase" />
                      <h1 className="display-1 text-white mb-md-4">"Elevate Your Hospitality Experience with Expert Catering Staff"</h1>
                     
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img className="w-100" src="allimage/radisson.jpg" alt="Image" style={{height:'1000px'}} />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: '900px'}}>
                      <h5 className="text-white text-uppercase" />
                      <h1 className="display-1 text-white mb-md-4">" Take Your Event to the Next Level: Our Expert Catering Staff"</h1>
                     
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img className="w-100" src="allimage/sheraton.jpg" alt="Image" style={{height:'1000px'}} />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: '900px'}}>
                      <h5 className="text-white text-uppercase" />
                      <h1 className="display-1 text-white mb-md-4">"Elevate Your Occasion: Expert Catering Staff at Your Servic"</h1>
                     
                    </div>
                  </div>
                </div>
                <div className="carousel-item">
                  <img className="w-100" src="allimage/crwon.jpg" alt="Image" style={{height:'1000px'}} />
                  <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3" style={{maxWidth: '900px'}}>
                      <h5 className="text-white text-uppercase" />
                      <h1 className="display-1 text-white mb-md-4">"Experience Excellence: Expert Catering Staff Ready to Serve"</h1>
                     
                    </div>
                  </div>
                </div>
              </div>
              <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel" data-bs-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true" />
                <span className="visually-hidden">Previous</span>
              </button>
              <button className="carousel-control-next" type="button" data-bs-target="#header-carousel" data-bs-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true" />
                <span className="visually-hidden">Next</span>
              </button>
            </div>
          </div>
          {/* Carousel End */}
          {/* About Start */}
          {/* About Start */}
          <div className="container-fluid bg-secondary p-0">
            <div className="row g-0">
              <div className="col-lg-6 py-6 px-5"><br />
                <h1 className="display-5 mb-4">Welcome To Providence International:<span className="text-primary">The Biggest Manpower Suppliers.</span></h1><br />
                <p className="mb-4" style={{color:'black'}}>
                  Welcome to Providence International,<span style={{color:'red'}}> Pune's Biggest Manpower Suppliers</span> and your trusted partner in delivering unparalleled manpower solutions for a wide range of events in the hospitality industry.We take pride in having conducted some of the largest events in the industry, including the<span style={{color: 'red'}}> record-breaking event of hosting 10,000 people at the prestigious Hyatt Hotel.</span> Our ability to manage such colossal gatherings reflects our commitment to scale, precision, and a customer-centric approach.
                </p></div>
              <div className="col-lg-6" style={{marginTop: '20px'}}>
                <div className="h-100 d-flex flex-column justify-content-center p-5" style={{backgroundColor: 'rgba(225, 72, 108, 0.781)'}}>
                  <div className="d-flex text-white mb-5">
                    <div className="ps-4">
                      <h3>✨ Excellence in Catering Manpower:</h3>
                      <p className="mb-0"> </p><ul className="mb-0" /><li> A dedicated team of skilled professionals.</li>
                      <li> Specialized in providing manpower to hotels.</li>
                      <li>Ensuring seamless execution of events.</li> <p />
                    </div>
                  </div>
                  <div className="d-flex text-white mb-5">
                    <div className="ps-4">
                      <h3>🍽️ Culinary Mastery:</h3>
                      <p className="mb-0"> </p><ul className="mb-0" /><li> Expertise in crafting exquisite culinary experiences.
                      </li><li>Precision in handling diverse culinary demands.</li><li>Elevating the gastronomic aspect of every occasion.</li><p />
                    </div>
                  </div>
                  <div className="d-flex text-white">
                    <div className="ps-4">
                      <h3>🤝 Tailored Hospitality Solutions:</h3>
                      <p className="mb-0"> </p><ul className="mb-0" /><li> Committed to meeting the unique needs of each venue.
                      </li><li>Providing top-notch service with attention to detail.</li><li>Flexible and adaptable to various event requirements.</li><p />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* About End */}
          {/* Services Start */}
          {/* Services Start */}
          <div className="container-fluid py-6 px-5">
            <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
              <h1 className="display-5 mb-0">What We Offer</h1>
              <hr className="w-25 mx-auto bg-primary" />
            </div>
            <div className="row g-5">
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center  justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-user-tie fa-2x" />
                  </div>
                  <h3 className="mb-3">
                    Service Staff:</h3>
                  <p className="mb-0" /><ul><li>Skilled and well-trained professionals</li><li>Professional waiters and waitresses</li><li>Efficient and courteous service for various events</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-utensils fa-2x" />
                  </div>
                  <h3 className="mb-3">Kitchen Support Team:</h3>
                  <p className="mb-0" /><ul><li>Skilled kitchen support staff</li><li>Ensuring smooth operations in the kitchen</li><li>Collaborating with chefs for seamless event catering</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fas fa-coffee fa-2x" />
                  </div>
                  <h3 className="mb-3">Chefs for All Occasions:</h3>
                  <p className="mb-0" /><ul><li>Diverse team of chefs specializing in different cuisines</li><li>Customized menus for events</li><li>Expertise in catering for weddings, corporate gatherings, and more</li></ul><p /><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-broom fa-2x" />
                  </div>
                  <h3 className="mb-3">Housekeeping Services:</h3>
                  <p className="mb-0" /><ul><li>Professional housekeeping staff</li><li>Maintaining cleanliness and hygiene at event venues</li><li>Pre and post-event cleaning services</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fa fa-chalkboard-teacher fa-2x" />
                  </div>
                  <h3 className="mb-3">Supervisors</h3>
                  <p className="mb-0" /><ul><li>Leadership Excellence</li><li>Team Coordination</li><li>Client Satisfaction</li></ul><p />
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="service-item bg-secondary text-center px-5">
                  <div className="d-flex align-items-center justify-content-center bg-primary text-white rounded-circle mx-auto mb-4" style={{width: '90px', height: '90px'}}>
                    <i className="fas fa-shield-alt fa-2x" />
                  </div>
                  <h3 className="mb-3">Security Services:</h3>
                  <p className="mb-0" /><ul><li>Trained Security Personnel</li><li>Crowd Control</li><li>Emergency Response</li></ul><p />
                </div>
              </div>
            </div>
          </div>
          {/* Features Start */}
          <div className="container-fluid py-6 px-5">
            <div className="text-center mx-auto mb-5" style={{maxWidth: '600px'}}>
              <h1 className="display-5 mb-0">Why Choose Us!!!</h1>
              <hr className="w-25 mx-auto bg-primary" />
            </div>
            <div className="row g-5">
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12">
                    <div className=" d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3 >
                      🌐 Skilled Manpower: </h3>
                    <p style={{color:'black'}} className="mb-0">Our team comprises highly trained professionals, each excelling in their respective roles, ensuring the success of every event.</p>
                  </div>
                  <div className="col-12">
                    <div className=" d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3>⚖️ Scalability: </h3>
                    <p style={{color:'black'}} className="mb-0">Whether your event is intimate or grand, Providence International has the resources and expertise to scale our services according to your needs./p&gt;
                    </p></div>
                  <div className="col-12">
                    <div className=" d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3>🔒 Reliability:</h3>
                    <p style={{color:'black'}} className="mb-0">We understand the importance of reliability in event management. Our track record speaks for itself, with countless successful events delivered with precision./p&gt;
                    </p></div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="d-block bg-primary h-100 text-center">
                  <img className="img-fluid" src="allimage/CEO.webp" style={{paddingTop: '20px'}} alt="" />
                  <div className="p-4">
                    <p style={{color:'black'}} className="text-white mb-4"> At Providence International, we stand out as your premier catering partner for several compelling reasons. Our commitment to culinary excellence is at the forefront of our service, with a team of skilled professionals dedicated to crafting delectable and innovative dishes.
                      From weddings to corporate gatherings, we bring a perfect blend of creativity and culinary expertise to every occasion, promising a delightful and memorable dining experience for you and your guests.</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="row g-5">
                  <div className="col-12">
                    <div className=" d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3>✨ Innovation:</h3>
                    <p style={{color:'black'}} className="mb-0">In an ever-evolving industry, we stay ahead by embracing innovation. Our approach is dynamic, ensuring that your event is not just successful but also memorable.
                    </p>
                  </div>
                  <div className="col-12">
                    <div className=" d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3>🏆 Proudest Achievement:</h3>
                    <p style={{color:'black'}} className="mb-0">The Hyatt Hotel event, hosting a staggering 10,000 people, stands as a testament to our capabilities and commitment. It exemplifies the heights we can reach when it comes to delivering top-notch manpower solutions.</p>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center justify-content-center mb-3" style={{width: '60px', height: '60px'}}>
                    </div>
                    <h3>🌟 Exceptional Quality:</h3>
                    <p style={{color:'black'}} className="mb-0">We pride ourselves on delivering exceptional quality services, ensuring that each event is executed with precision, attention to detail, and a commitment to excellence.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Features Start */}
          {/* Quote Start */}
          <div className="container-fluid bg-secondary px-0">
            <div className="row g-0">
              <div className="col-lg-6 py-6 px-5 d-flex align-items-center mx-auto">
                <div>
                  <h1 className="display-5 mb-4">Share Your Thought With Us!!</h1>
                  <form id="form" onSubmit={this.handleSubmit}>
                    <div className="row gx-3">
                      <div className="col-6">
                        <div className="form-floating">
                          <input type="text" className="form-control"  name="name" id="name"  required />
                          <label htmlFor="form-floating-1">Full Name</label>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-floating mb-3">
                          <input type="email" className="form-control" name="email" id="email"  required />
                          <label htmlFor="form-floating-2">Email address</label>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-floating">
                          <input type="thought" className="form-control" name="subject" id="subject" required />
                          <label htmlFor="form-floating-3">Your Thoughts</label>
                        </div>
                      </div>
                      <div className="col-12 mt-3">
                        <button  id="button" className="btn btn-primary w-100" type="submit">Submit</button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
       
      <ManpowerFooterComponent></ManpowerFooterComponent>
        
      </div>
  
     </div>
  );
}
}
export default ManpowerHomeComponent ;
