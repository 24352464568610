import React, { Component } from 'react';
import emailjs from 'emailjs-com';

class ProductionHouseNavBarComponent extends Component {
  state = {
    country: '',
    city: '',
    place: '',
    event: '',
    palace: '',
    veg: '',
    mobileNumber: '',
    date: '',
    email: '',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };


  // Assuming you have a form reference named 'form'
  // this.form.validateAll();


  render() {
    return (

      <div>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.4/css/all.min.css" integrity="sha512-w+5z9jyZXiLxOm2w0r/zHqb3Uvqz9IWUkOL+kqnjuv2E64M9m+L3JHioGj++Qu72G1zlN1JcFcJWvS0f7UKw/g==" crossorigin="anonymous" referrerpolicy="no-referrer" />

        <section id="top" style={{backgroundColor:'#191a1d'}} >
          <div className="container" >
            <div className="row top_1">
              <div className="col-md-3">
                <div className="top_1l pt-1">
                  <h3 className="mb-0"><a className="text-white" href="index.html"><i className="fa fa-video-camera col_red me-1" /> Planet</a></h3>
                </div>
              </div>
              <div className="col-md-5">
                <div className="top_1m">
                  <div className="input-group">
                    <input type="text" className="form-control bg-black" placeholder="Search Site..." />
                    <span className="input-group-btn">
                      <button className="btn btn text-white bg_red rounded-0 border-0" type="button">
                        Search</button>
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <div class="top_1r text-end">
                  <ul class="social-network social-circle mb-0">
                    <li><a href="https://www.instagram.com/" class="icoRss" title="Instagram"><i class="fab fa-instagram"></i></a></li>
                    <li><a href="https://www.facebook.com/" class="icoFacebook" title="Facebook"><i class="fab fa-facebook"></i></a></li>
                    <li><a href="https://twitter.com/" class="icoTwitter" title="Twitter"><i class="fab fa-twitter"></i></a></li>
                    <li><a href="https://www.youtube.com/" class="icoGoogle" title="YouTube"><i class="fab fa-youtube"></i></a></li>
                    <li><a href="https://www.linkedin.com/" class="icoLinkedin" title="LinkedIn"><i class="fab fa-linkedin"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="header" style={{ marginTop: '-100px' }}>
          <nav className="navbar navbar-expand-md navbar-light navbarnew" id="navbar_sticky">
            <div className="container">
              <a className="navbar-brand text-white fw-bold" href="index.html"><i className="fa fa-video-camera col_red me-1" /> Planet</a>
              <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" />
              </button>
              <div className="collapse navbar-collapse" id="navbarSupportedContent">
                <ul className="navbar-nav mb-0">
                  <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="/productionHouse-home">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/productionHouse-about-us">About Us</a>
                  </li>
                  <li className="nav-item ">
                    <a className="nav-link " href="/productionHouse-blog"  >
                      Blog
                    </a>

                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/productionHouse-servies">Services</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/productionHouse-teams">Team</a>
                  </li>
                  <li className="nav-item dropdown">
                    <a className="nav-link " href="/productionHouse-gallery" >
                      Gallery
                    </a>

                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/productionHouse-contact-us">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </section>
      </div>
    );
  }
}

export default ProductionHouseNavBarComponent;
