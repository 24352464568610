import React, { Component } from 'react'

import NavBar from './NavBar';
import FooterComponent from './FooterComponent';



class AboutComponent extends Component {
  constructor(props) {
    super(props);
    

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    
  }


  render() {
    return (
      
    <div>
       <div>
       <NavBar></NavBar>
       <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-fullscreen">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body d-flex align-items-center">
                <div className="input-group w-75 mx-auto d-flex">
                  <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
                  <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
                </div>
              </div>
            </div>
          </div>
        </div>
         {/* Modal Search End */}
        {/* Hero Start */}
        <div className="container-fluid bg-light py-6 my-6 mt-0">
          <div className="container text-center animated bounceInDown">
            <h1 className="display-1 mb-4">About Us</h1>
          </div>
        </div>
        {/* Hero End */}
        {/* About Satrt */}
        <div className="container-fluid py-6" style={{ marginTop: '-17%' }}>
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                <img src="allimage/CEO.webp" className="img-fluid rounded" alt="" />
              </div>
              <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
                <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">About Us</small>
                <h1 className="display-5 mb-4">We make Tastebuds Happy</h1>
                <p className="mb-4" style={{color:'black'}}>"At Providence International, we pride ourselves on delivering exceptional culinary experiences for every occasion. 
                  With a passion for innovative and delectable cuisine, our team is dedicated to creating unforgettable moments through expertly crafted menus, impeccable service, and attention to detail. 
                  Whether it's a wedding, corporate event, or special celebration, we bring a blend of creativity and culinary expertise to ensure a delightful and memorable dining experience for you and your guests.
                  Elevate your event with Providence International where every dish tells a story of flavor, freshness, and excellence.
                  Conti Foods is a premier provider of culinary delights, specializing in continental cuisine crafted by our team of seasoned chefs. With a rich history of serving NATO forces in Afghanistan and supporting 
                  United Nations operations in Africa, we bring a wealth of experience and expertise to every dish we create. Our commitment to excellence and unwavering dedication to quality make us a trusted choice for unforgettable
                   dining experiences.






"
                </p>
                <div className="row g-4 text-dark mb-5">
                  <div className="col-sm-6">
                    🍽️ Culinary Mastery
                  </div>
                  <div className="col-sm-6">
                    🎉 Tailored Elegance
                  </div>
                  <div className="col-sm-6">
                    🌟 Impeccable Hospitality
                  </div>
                  <div className="col-sm-6">
                    🥂 Unforgettable Moments
                  </div>
                  <div className="col-sm-6">
                    🎊 Freshness and Excellence
                  </div>
                  <div className="col-sm-6">
                    🤝 Providence Promise
                  </div>
                </div>
                
              </div>
            </div>
          </div>
        </div>
        {/* About End */}
        {/* Fact Start*/}
        <div className="container-fluid faqt py-6">
          <div className="container">
            <div className="row g-4 align-items-center">
              <div className="col-lg-7">
                <div className="row g-4">
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.3s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-users fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">900</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Happy Customers</p>
                    </div>
                  </div>
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.5s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-users-cog fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">107</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Expert Chefs</p>
                    </div>
                  </div>
                  <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.7s">
                    <div className="faqt-item bg-primary rounded p-4 text-center">
                      <i className="fas fa-check fa-4x mb-4 text-white" />
                      <h1 className="display-4 fw-bold" data-toggle="counter-up">253</h1>
                      <p className="text-dark text-uppercase fw-bold mb-0">Events Complete</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                <div className="video">
                  <button type="button" className="btn btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
                    <span />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Modal Video */}
        <div className="modal fade" id="videoModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content rounded-0">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                {/* 16:9 aspect ratio */}
                <div className="ratio ratio-16x9">
                  <iframe className="embed-responsive-item" src id="video" allowFullScreen allowscriptaccess="always" allow="autoplay" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Fact End */}
        <div className="container-fluid py-6">
          <div className="container">
            <div className="row g-5 align-items-center">
              <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
                <img src="allimage/CEO.webp" className="img-fluid rounded" alt="" />
              </div>
              <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
                <h1 className="display-5 mb-4">Meet our Founder</h1>
                <p className="mb-4" style={{color:'black'}}>"As we are managing family functions for more than a decade now, we came across various needs and problems faced by guests like lacking great taste. There is a big problem in the market and that is trust, whether the quality of ingredients is good? Whether food has not been adulterated? Is proper hygiene maintained? And so on. Many times food selection does not happen according to the guest’s choice, if it happens then the menu pricing goes out of the budget. How the food service and presentation is going to be. We are so proud to say that we are the solutions to all the problems and needs of the market.
                  "
                </p>
              </div>
            </div>
          </div>
        </div>
        <FooterComponent></FooterComponent>
      </div>
    
    </div>
  );
}
}

//       <div>
//         <NavBar/>
       
        
//         {/* Navbar End */}
//         {/* Modal Search Start */}
//         <div className="modal fade" id="searchModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
//           <div className="modal-dialog modal-fullscreen">
//             <div className="modal-content rounded-0">
//               <div className="modal-header">
//                 <h5 className="modal-title" id="exampleModalLabel">Search by keyword</h5>
//                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//               </div>
//               <div className="modal-body d-flex align-items-center">
//                 <div className="input-group w-75 mx-auto d-flex">
//                   <input type="search" className="form-control bg-transparent p-3" placeholder="keywords" aria-describedby="search-icon-1" />
//                   <span id="search-icon-1" className="input-group-text p-3"><i className="fa fa-search" /></span>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Modal Search End */}
//         {/* Hero Start */}
//         <div className="container-fluid bg-light py-6 my-6 mt-0">
//           <div className="container text-center animated bounceInDown">
//             <h1 className="display-1 mb-4">About Us</h1>
            
//           </div>
//         </div>
//         {/* Hero End */}
//         {/* About Satrt */}
//         <div className="container-fluid py-6" style={{ marginTop: '-17%' }}>
//           <div className="container">
//             <div className="row g-5 align-items-center">
//               <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
//                 <img src="allimage/OIP.jpg" className="img-fluid rounded" alt="" />
//               </div>
//               <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
//                 <small className="d-inline-block fw-bold text-dark text-uppercase bg-light border border-primary rounded-pill px-4 py-1 mb-3">About Us</small>
//                 <h1 className="display-5 mb-4">We make Tastebuds Happy</h1>
//                 <p className="mb-4">"At Providence International, we pride ourselves on delivering exceptional culinary experiences for every occasion. 
//                   With a passion for innovative and delectable cuisine, our team is dedicated to creating unforgettable moments through expertly crafted menus, impeccable service, and attention to detail. 
//                   Whether it's a wedding, corporate event, or special celebration, we bring a blend of creativity and culinary expertise to ensure a delightful and memorable dining experience for you and your guests.
//                   Elevate your event with Providence International where every dish tells a story of flavor, freshness, and excellence."
//                 </p>
//                 <div className="row g-4 text-dark mb-5">
//                   <div className="col-sm-6">
//                     🍽️ Culinary Mastery
//                   </div>
//                   <div className="col-sm-6">
//                     🎉 Tailored Elegance
//                   </div>
//                   <div className="col-sm-6">
//                     🌟 Impeccable Hospitality
//                   </div>
//                   <div className="col-sm-6">
//                     🥂 Unforgettable Moments
//                   </div>
//                   <div className="col-sm-6">
//                     🎊 Freshness and Excellence
//                   </div>
//                   <div className="col-sm-6">
//                     🤝 Providence Promise
//                   </div>
//                 </div>
//                 <a href className="btn btn-primary py-3 px-5 rounded-pill">About Us<i className="fas fa-arrow-right ps-2" /></a>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* About End */}
//         {/* Fact Start*/}
//         <div className="container-fluid faqt py-6">
//           <div className="container">
//             <div className="row g-4 align-items-center">
//               <div className="col-lg-7">
//                 <div className="row g-4">
//                   <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.3s">
//                     <div className="faqt-item bg-primary rounded p-4 text-center">
//                       <i className="fas fa-users fa-4x mb-4 text-white" />
//                       <h1 className="display-4 fw-bold" data-toggle="counter-up">900</h1>
//                       <p className="text-dark text-uppercase fw-bold mb-0">Happy Customers</p>
//                     </div>
//                   </div>
//                   <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.5s">
//                     <div className="faqt-item bg-primary rounded p-4 text-center">
//                       <i className="fas fa-users-cog fa-4x mb-4 text-white" />
//                       <h1 className="display-4 fw-bold" data-toggle="counter-up">107</h1>
//                       <p className="text-dark text-uppercase fw-bold mb-0">Expert Chefs</p>
//                     </div>
//                   </div>
//                   <div className="col-sm-4 wow bounceInUp" data-wow-delay="0.7s">
//                     <div className="faqt-item bg-primary rounded p-4 text-center">
//                       <i className="fas fa-check fa-4x mb-4 text-white" />
//                       <h1 className="display-4 fw-bold" data-toggle="counter-up">253</h1>
//                       <p className="text-dark text-uppercase fw-bold mb-0">Events Complete</p>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//               <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
//                 <div className="video">
//                   <button type="button" className="btn btn-play" data-bs-toggle="modal" data-src="https://www.youtube.com/embed/DWRcNpR6Kdc" data-bs-target="#videoModal">
//                     <span />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Modal Video */}
//         <div className="modal fade" id="videoModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
//           <div className="modal-dialog">
//             <div className="modal-content rounded-0">
//               <div className="modal-header">
//                 <h5 className="modal-title" id="exampleModalLabel">Youtube Video</h5>
//                 <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
//               </div>
//               <div className="modal-body">
//                 {/* 16:9 aspect ratio */}
//                 <div className="ratio ratio-16x9">
//                   <iframe className="embed-responsive-item" src id="video" allowFullScreen allowscriptaccess="always" allow="autoplay" />
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Fact End */}
//         <div className="container-fluid py-6">
//           <div className="container">
//             <div className="row g-5 align-items-center">
//               <div className="col-lg-5 wow bounceInUp" data-wow-delay="0.1s">
//                 <img src="allimage/CEO.webp" className="img-fluid rounded" alt="" />
//               </div>
//               <div className="col-lg-7 wow bounceInUp" data-wow-delay="0.3s">
//                 <h1 className="display-5 mb-4">Meet our Founder</h1>
//                 <p className="mb-4">"As we are managing family functions for more than a decade now, we came across various needs and problems faced by guests like lacking great taste. There is a big problem in the market and that is trust, whether the quality of ingredients is good? Whether food has not been adulterated? Is proper hygiene maintained? And so on. Many times food selection does not happen according to the guest’s choice, if it happens then the menu pricing goes out of the budget. How the food service and presentation is going to be. We are so proud to say that we are the solutions to all the problems and needs of the market.
//                   "
//                 </p>
//               </div>
//             </div>
//           </div>
//         </div>
//         {/* Footer Start */}
//        <FooterComponent></FooterComponent>
//         {/* Footer End */}
//         {/* Copyright Start */}
       
//         {/* Copyright End */}
//         {/* Back to Top */}
//         {/* JavaScript Libraries */}
//         {/* Template Javascript */}
//       </div>
//     );


// }
    
  
// }

export default AboutComponent