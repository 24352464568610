import React, { Component } from 'react';
import NavBar from './NavBar';
import BanquetFooterComponent from './BanquetFooterComponent';
import NavBarBanquet from './NavBarBanquet';
import { testimonialSliderSettings } from './slickSettings';
import Slider from 'react-slick';

class BanquetGallaryComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonialSliderSettings: {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 4, // Adjust the number of testimonials you want to show in one row
        slidesToScroll: 1,

      },
      testimonialSliderSettings1: {
        dots: true,
        infinite: true,
        speed: 100,
        slidesToShow: 4, // Adjust the number of testimonials you want to show in one row
        slidesToScroll: 1,
        autoplay: true, // Enable automatic sliding
        autoplaySpeed: 4000,
      },
    }; // this.form.validateAll();
  }

  render() {
    return (
      <div>
        <div>
          <NavBarBanquet></NavBarBanquet>
          {/*/ Header end */}
          <div id="page-banner-area" className="page-banner-area" style={{ backgroundImage: 'url(allimage/hero_area/banner_bg.jpg)' }}>
            {/* Subpage title start */}
            <div className="page-banner-title">
              <div className="text-center">
                <h2>Event Gallery</h2>
                <ol className="breadcrumb">
                  <li>
                    <a href="#">Exibit /</a>
                  </li>
                  <li>
                    Gallery
                  </li>
                </ol>
              </div>
            </div>{/* Subpage title end */}
          </div>{/* Page Banner end */}
          <section id="main-container" className="main-container ts-gallery">
            <div className="container">
              <div className="row">
                <div className="row-lg-8 mx-auto text-center">
                  <h2 className="section-title text-center">
                    <span>Previus Moments</span>
                    Event Gallery
                  </h2>
                </div>{/* col end*/}
              </div>
            </div>
            <div className="tab-class text-center">
              <ul className="nav nav-pills d-inline-flex justify-content-center mb-5 wow bounceInUp" data-wow-delay="0.1s">
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill active" data-bs-toggle="pill" href="#tab-1">
                    <span className="text-dark" style={{ width: '150px' }}>Photos</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex py-2 mx-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-2">
                    <span className="text-dark" style={{ width: '150px' }}>Videos</span>
                  </a>
                </li>
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-3">
                    <span className="text-dark" style={{width: '150px'}}>Corporate</span>
                  </a>
                </li>
                <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-4">
                    <span className="text-dark" style={{width: '150px'}}>Mocktail</span>
                  </a>
                </li> */}
                {/* <li className="nav-item p-2">
                  <a className="d-flex mx-2 py-2 border border-primary bg-light rounded-pill" data-bs-toggle="pill" href="#tab-5">
                    <span className="text-dark" style={{width: '150px'}}>Buffet</span>
                  </a>
                </li> */}
              </ul>
              <style dangerouslySetInnerHTML={{ __html: "\n                        .event-img img {\n                            width: 100%; /* Make sure the image takes the full width of its container */\n                            height: 200px; /* Set a fixed height for all images */\n                            object-fit: cover; /* Preserve aspect ratio while covering the container */\n                        }\n                    " }} />
              <div className="tab-content">
                <div id="tab-1" className="tab-pane fade show p-0 active">
                  <div className="row g-4">
                    <div className="col-lg-12">

                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0121.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0118.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0119.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0120.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0121.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0122.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0123.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0124.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0125.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0126.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0127.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0121.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0088.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0061.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0071.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0072.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0073.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0074.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0075.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0076.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0077.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0078.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0079.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240120-WA0080.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '300px', width: '250px' }} /> </div> </div>
                      </Slider>

                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0051.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0075.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0072.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0074.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0069.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0071.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0068.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0070.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0066.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0065.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0067.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0064.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0063.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0062.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0061.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0060.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0058.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0059.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0057.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0056.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                      <Slider {...this.state.testimonialSliderSettings}>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0052.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0053.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0054.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                        <div><div className="d-flex mb-3" ><img src="allimage/IMG-20240121-WA0055.jpg" className="testimonial-item rounded bg-light" alt="" style={{ height: '350px', width: '300px' }} /> </div> </div>
                      </Slider>
                    </div>
                  </div>
                </div>
                <style dangerouslySetInnerHTML={{ __html: "\n                            .event-img img {\n                                width: 100%; /* Make sure the image takes the full width of its container */\n                                height: 200px; /* Set a fixed height for all images */\n                                object-fit: cover; /* Preserve aspect ratio while covering the container */\n                            }\n                        " }} />
                <div id="tab-2" className="tab-pane fade show p-0">
                  <div className="row g-4">
                    <div className="col-lg-12">
                      
<div className="row g-4">
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/11_643755037794381.mp4" type="video/mp4" /></video></div></div>
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/12_380894384528101.mp4" type="video/mp4" /></video></div></div>
 </div>
 <div className="row g-4">
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/13_1602623960507082.mp4" type="video/mp4" /></video></div></div>
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/15_3688909231366999.mp4" type="video/mp4" /></video></div></div>
 
</div>
<div className="row g-4">
    <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/21_2020049711697749.mp4" type="video/mp4" /></video></div></div>
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/22_3682100412077610.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/23_887316933078205.mp4" type="video/mp4" /></video></div></div>
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/78_1056371852181391.mp4" type="video/mp4" /></video></div></div>
</div>
<div className="row g-4">

 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/79_383771444015831.mp4" type="video/mp4" /></video></div></div>
 <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/119_2441915409206656.mp4" type="video/mp4" /></video></div></div>

</div>
<div className="row g-4">
     <div className="col-md-6 col-lg-6"><div className="event-img position-relative"><video width="640" height="360" controls><source src="/allimage/banvcl/10_1375167113365367.mp4" type="video/mp4" /></video></div></div>
</div>


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>{/* Main container end */}
          {/* ts footer area start*/}




          <BanquetFooterComponent></BanquetFooterComponent>


        </div >

      </div >
    );
  }
}
export default BanquetGallaryComponent;
