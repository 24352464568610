import React, { Component } from 'react';
import NavBar from './NavBar';
import emailjs from 'emailjs-com'; 
import NavBarBanquet from './NavBarBanquet';


class BanquetContactComponent extends Component {
  state = {
    name: '',
    lname: '',
    subject: '',
    email: '',
    message: '',
   
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };
    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  

  render() {
    return (
      <div>

        {/* contact17:37*/}
        {/* Basic Page Needs ================================================== */}
        <meta charSet="utf-8" />
        {/* Mobile Specific Metas ================================================== */}
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />
        {/* Site Title */}
        <title>Exhibit - Conference &amp; Event HTML Template</title>
        {/* CSS
         ================================================== */}
        {/* Bootstrap */}
        <link rel="stylesheet" href="css/bootstrap.min.css" />
        {/* FontAwesome */}
        <link rel="stylesheet" href="css/font-awesome.min.css" />
        {/* Animation */}
        <link rel="stylesheet" href="css/animate.css" />
        {/* magnific */}
        <link rel="stylesheet" href="css/magnific-popup.css" />
        {/* carousel */}
        <link rel="stylesheet" href="css/owl.carousel.min.css" />
        {/* isotop */}
        <link rel="stylesheet" href="css/isotop.css" />
        {/* ico fonts */}
        <link rel="stylesheet" href="css/xsIcon.css" />
        {/* Template styles*/}
        <link rel="stylesheet" href="css/style.css" />
        {/* Responsive styles*/}
        <link rel="stylesheet" href="css/responsive.css" />
        {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
        {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
        {/*[if lt IE 9]>
      
      
      <![endif]*/}
        <div className="body-inner">
          {/* Header start */}
         <NavBarBanquet></NavBarBanquet>
          {/*/ Header end */}
          <div id="page-banner-area" className="page-banner-area" style={{backgroundImage: 'url(allimage/hero_area/banner_bg.jpg)'}}>
            {/* Subpage title start */}
            <div className="page-banner-title">
              <div className="text-center">
                <h2>Contact Us</h2>
               
              </div>
            </div>{/* Subpage title end */}
          </div>{/* Page Banner end */}
          {/* ts intro start */}
        
          {/* ts contact end*/}
          <section className="ts-contact-map no-padding">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-12 no-padding">
                  <div className="mapouter">
                  
                  </div>
                </div>
              </div>
            </div></section>
          <section className="ts-contact-form">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mx-auto">
                  <h2 className="section-title text-center">
                    <span>Have Questions?</span>
                    Send Message
                  </h2>
                </div>{/* col end*/}
              </div>
              <div className="row">
                <div className="col-lg-8 mx-auto">
                <form id="form" onSubmit={this.handleSubmit}>
                    <div className="error-container" />
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control form-control-name"  placeholder="Full  Name" name="name" id="name"   type="text" required />
                        </div>
                      </div>
                     
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control form-control-subject" placeholder="Request" name="Requ" id="subject"  required />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <input className="form-control form-control-email" placeholder="Email" name="email" id="email"   type="email" required />
                        </div>
                      </div>
                    </div>
                    <div className="form-group">
                      <textarea className="form-control form-control-message" name="message" id="message"  placeholder="Your message...*" rows={6} required defaultValue={""} />
                    </div>
                    <div className="text-center"><br />
                      <button id="button" className="btn" type="submit"> Send Message</button>
                    </div>
                  </form>{/* Contact form end */}
                </div>
              </div>
            </div>
            <div className="speaker-shap">
              <img className="shap1" src="allimage/shap/home_schedule_memphis2.png" alt="" />
            </div>
          </section>
          {/* ts footer area start*/}
          <div className="footer-area">
            {/* ts-book-seat start*/}
            <section className="ts-book-seat" style={{backgroundImage: 'url(allimage/book_seat_img.jpg)'}}>
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="book-seat-content text-center mb-70">
                      <h2 className="section-title white">
                        <span>Hurry up!</span>
                        Book your Seat
                      </h2>
                      <a href="#" className="btn">Buy Ticket</a>
                    </div>{/* book seat end*/}
                  </div>{/* col end*/}
                </div>{/* row end*/}
                <div className="ts-footer-newsletter">
                  <div className="ts-newsletter" style={{backgroundImage: 'url(allimage/shap/subscribe_pattern.png)'}}>
                    <div className="row">
                      <div className="col-lg-6 mx-auto">
                        <div className="ts-newsletter-content">
                          <h2 className="section-title">
                            <span>sUBSCRIBE TO nEWSLETTER</span>
                            Want something extra?
                          </h2>
                        </div>
                        <div className="newsletter-form">
                          <form action="#" method="post" className="media align-items-end">
                            <div className="email-form-group media-body">
                              <input type="email" name="email" id="newsletter-form-email" className="form-control" placeholder="Your Email" autoComplete="off" required />
                            </div>
                            <div className="d-flex ts-submit-btn">
                              <button className="btn">Subscribe</button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>{/* container end*/}
            </section>
            {/* book seat  end*/}
            {/* footer start*/}
            <footer className="ts-footer">
              <div className="container">
                <div className="row">
                  <div className="col-lg-8 mx-auto">
                    <div className="ts-footer-social text-center mb-30">
                      <ul>
                        <li>
                          <a href="#"><i className="fa fa-facebook" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fa fa-twitter" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fa fa-google-plus" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fa fa-linkedin" /></a>
                        </li>
                        <li>
                          <a href="#"><i className="fa fa-instagram" /></a>
                        </li>
                      </ul>
                    </div>
                    {/* footer social end*/}
                    <div className="footer-menu text-center mb-25">
                      <ul>
                        <li><a href="#">About Eventime</a></li>
                        <li><a href="#">Blog</a></li>
                        <li><a href="#">Contact</a></li>
                        <li><a href="#">Tickets</a></li>
                        <li><a href="#">Venue</a></li>
                      </ul>
                    </div>{/* footer menu end*/}
                    <div className="copyright-text text-center">
                      <p><a target="_blank" href="https://www.templateshub.net">Templates Hub</a></p>
                    </div>
                  </div>
                </div>
              </div>
            </footer>
            {/* footer end*/}
            <div className="BackTo">
              <a href="#" className="fa fa-angle-up" aria-hidden="true" />
            </div>
          </div>
          {/* ts footer area end*/}
          {/* Javascript Files
            ================================================== */}
          {/* initialize jQuery Library */}
          {/* Bootstrap jQuery */}
          {/* Counter */}
          {/* Countdown */}
          {/* magnific-popup */}
          {/* carousel */}
          {/* Waypoints */}
          {/* isotop */}
          {/* Template custom */}
        </div>
        {/* Body inner end */}
        {/* contact17:37*/}
      </div>
    );
  }
}

export default BanquetContactComponent;
