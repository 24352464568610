import React, { Component } from 'react';

class ManpowerFooterComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>

    
       
        <div className="container-fluid bg-dark text-secondary p-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Quick Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-secondary mb-2" href="/cetrin-home"><i className="bi bi-arrow-right text-primary me-2" />Catering Services</a>
                <a className="text-secondary mb-2" href="/banquet-home"><i className="bi bi-arrow-right text-primary me-2" />Banquet Hall</a>
                <a className="text-secondary mb-2" href="/manpower-home"><i className="bi bi-arrow-right text-primary me-2" />Hospitality Services</a>
                <a className="text-secondary mb-2" href="/productionHouse-home"><i className="bi bi-arrow-right text-primary me-2" />Production Hall</a>
             
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Popular Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-secondary mb-2" href="manpower-home"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-secondary mb-2" href="manpower-aboutus"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-secondary mb-2" href="manpower-service"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
               
                <a className="text-secondary" href="manpower-contact"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Get In Touch</h3>
              <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2" /> Head Office No. A55, First Floor, Virvani Plaza, East Street, Camp Pune 411001.</p>
              <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2" />providenceinternational180@gmail.com</p>
              <p className="mb-0"><i className="bi bi-telephone text-primary me-2" />9595264988</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Follow Us</h3>
              <div className="d-flex">
                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="https://www.facebook.com/profile.php?id=100090355260753&mibextid=ZbWKwL"><i className="fab fa-facebook-f fw-normal" /></a>

                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="https://instagram.com/providenceinternational.co?igshid=YmMyMTA2M2Y="><i className="fab fa-instagram fw-normal" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ManpowerFooterComponent;

