import React, { Component } from 'react'
import BanquetFooterComponent from './BanquetFooterComponent';

import NavBar from './NavBar';
import FooterComponent from './FooterComponent';
import NavBarBanquet from './NavBarBanquet';



class BanquetAboutComponent extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();

    
  }


  render() {
    return (
      <div>
      {/* about-us15:53*/}
      {/* Basic Page Needs ================================================== */}
      <meta charSet="utf-8" />
      {/* Mobile Specific Metas ================================================== */}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />
      {/* Site Title */}
      <title>Exhibit - Conference &amp; Event HTML Template</title>
      {/* CSS
       ================================================== */}
      {/* Bootstrap */}
      <link rel="stylesheet" href="css/bootstrap.min.css" />
      {/* FontAwesome */}
      <link rel="stylesheet" href="css/font-awesome.min.css" />
      {/* Animation */}
      <link rel="stylesheet" href="css/animate.css" />
      {/* magnific */}
      <link rel="stylesheet" href="css/magnific-popup.css" />
      {/* carousel */}
      <link rel="stylesheet" href="css/owl.carousel.min.css" />
      {/* isotop */}
      <link rel="stylesheet" href="css/isotop.css" />
      {/* ico fonts */}
      <link rel="stylesheet" href="css/xsIcon.css" />
      {/* Template styles*/}
      <link rel="stylesheet" href="css/style.css" />
      {/* Responsive styles*/}
      <link rel="stylesheet" href="css/responsive.css" />
      {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
      {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
      {/*[if lt IE 9]>
    
    
    <![endif]*/}
      <div className="body-inner">
        {/* Header start */}
        <NavBarBanquet></NavBarBanquet>

        {/*/ Header end */}
        <div id="page-banner-area" className="page-banner-area" style={{ backgroundImage: 'url(allimage/hero_area/banner_bg.jpg)' }}>
          {/* Subpage title start */}
          <div className="page-banner-title">
            <div className="text-center">
              <h2 style={{textAlign:'center'}}>About us</h2>
              <ol className="breadcrumb">
                <li>
                  <a href="#" />
                </li>
                <li>
                  About
                </li>
              </ol>
            </div>
          </div>{/* Subpage title end */}
        </div>{/* Page Banner end */}
        {/* ts intro start */}
        <section className="ts-about-intro section-bg">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <h2 className="section-title">
                  Why Choose Providence Divine Banquet ?
                </h2>
              </div>{/* section title end*/}
            </div>{/* col end*/}
            <div className="row">
              <div className="col-lg-4">
                <div className="about-intro-text text-right mb-60 mr-70">
                  <h3 className="ts-title">👑 Exceptional Service</h3>
                  <p style={{color:'black'}}>
                    We go beyond the ordinary, infusing elegance into every corner of our hall. From the grand entrance to the smallest detail, our venue is a work of art.
                  </p>
                </div>{/* single intro text end*/}
                <div className="about-intro-text text-right mr-70">
                  <h3 className="ts-title">🎨 Creative Ambiance</h3>
                  <p style={{color:'black'}}>
                    Our team of event maestros is dedicated to bringing your ideas to life. From planning to execution, we're here to ensure your event is seamless and stress-free.
                  </p>
                </div>{/* single intro text end*/}
              </div>{/* col end*/}
              <div className="col-lg-4 align-self-center">
                <div className="about-video">
                  <img src="allimage/banquethall.jpg" alt="" />
                </div>{/* entro video end*/}
              </div>{/* col end*/}
              <div className="col-lg-4">
                <div className="about-intro-text mb-60 ml-70">
                  <h3 className="ts-title">🌈 Versatility in Design</h3>
                  <p style={{color:'black'}}>
                    Your vision is our inspiration. Our versatile spaces adapt to your theme, whether it's a fairytale wedding, a futuristic corporate event, or a themed party that defies convention.
                  </p>
                </div>{/* single intro text end*/}
                <div className="about-intro-text ml-70">
                  <h3 className="ts-title">🍽️ Culinary Extravaganza</h3>
                  <p style={{color:'black'}}>
                    Prepare your taste buds for an extraordinary journey. Our culinary artists craft a menu that's not just a meal but a delightful experience, leaving a lasting impression.
                  </p>
                </div>{/* single intro text end*/}
                <div className="border-shap left" />
              </div>{/* col end*/}
            </div>{/* row end*/}
          </div>{/* container end*/}
        </section>
        {/* ts intro end*/}
        {/* ts speaker start*/}
        {/* ts speaker end*/}
        <section className="ts-funfact" style={{backgroundImage: 'url(allimage/eventback.jpg)'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={43}>900</span>+</h3>
                  <h4 className="funfact-title">Our Happy Customers
                  </h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={62}>100+</span></h3>
                  <h4 className="funfact-title">Events</h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={28}>50+</span>+</h3>
                  <h4 className="funfact-title">5 Star Rating
                  </h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={950}>750</span>+</h3>
                  <h4 className="funfact-title">Feedbacks
                  </h4>
                </div>
              </div>{/* col end*/}
            </div>{/* row end*/}
          </div>{/* container end*/}
        </section>
        {/*/ Content row 1 end */}
        {/*/ Content row 2 end */}
        {/* ts map direction start*/}
        <BanquetFooterComponent></BanquetFooterComponent>

        {/* ts map direction end*/}
        {/* ts footer area start*/}
       
        {/* ts footer area end*/}
        {/* Javascript Files
          ================================================== */}
        {/* initialize jQuery Library */}
        {/* Bootstrap jQuery */}
        {/* Counter */}
        {/* Countdown */}
        {/* magnific-popup */}
        {/* carousel */}
        {/* Waypoints */}
        {/* isotop */}
        {/* Template custom */}
      </div>
      {/* Body inner end */}
      {/* about-us15:54*/}
    </div>
  );
}
}
export default BanquetAboutComponent