// // App.js
// import React from 'react';
// import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
// import HomePageComponent from './components/HomePageComponent';
// import AboutComponent from './components/AboutComponent';
// import ContactComponent from './components/ContactComponent'
// const App = () => {
//   return (
//     <Router>
//       <div>
        

//           <Routes>
//           <Route path="/home" element={<HomePageComponent />} />
//           <Route path="/" element={<AboutComponent />} />
//           <Route path="/contact" element={<ContactComponent/>}/>
//         </Routes>
//       </div>
//     </Router>
//   );
// };











import logo from './logo.svg';
import './App.css';
import AboutComponent from './components/AboutComponent';

import { Routes, Route} from 'react-router-dom'
import HomePageComponent from './components/HomePageComponent';
 import ContactComponent from './components/ContactComponent'
 import ServiceComponent from './components/ServiceComponent';
 import TestimonialComponent from './components/TestimonialComponent';
 import EventComponent from './components/EventComponent';
 import TeamComponent  from './components/TeamComponent '
 import MenuComponent from './components/MenuComponent';
 import BlogComponent from './components/BlogComponent';
 import BookComponent from './components/BookComponent'
import GalleryComponent from './components/GalleryComponent'
import BanquetContactComponent from './components/BanquetContactComponent';
import BanquetAboutComponent from './components/BanquetAboutComponent';
import BanquetGallaryComponent from './components/BanquetGallaryComponent';
import BanquetHomeComponent from './components/BanquetHomeComponent';
import BanquetServiesComponent from './components/BanquetServiesComponent';
import BanquetBlogComponent from './components/BanquetBlogComponent'
import ManpowerAboutUsComponent from './components/ManpowerAboutUsComponent'
import ManpowerServicesComponent from './components/ManpowerServicesComponent'
import ManpowerContactComponent from './components/ManpowerContactComponent'
import ManpowerHomeComponent from './components/ManpowerHomeComponent'
import ManpowerBlogComponent from './components/ManpowerBlogComponent'
import ManpowerGalleryComponent from './components/ManpowerGalleryComponent'
import MainHomeComponent from './components/MainHomeComponent'
import ProductionHouseHomeComponent from './components/ProductionHouseHomeComponent'
import ProductionHouseAboutComponent from './components/ProductionHouseAboutComponent'
import ProductionHouseBlogComponent from './components/ProductionHouseBlogComponent'
import ProductionHouseContactComponent from './components/ProductionHouseContactComponent'
import ProductionHouseGalleryComponent from './components/ProductionHouseGalleryComponent'
import ProductionHouseTeamComponent from './components/ProductionHouseTeamComponent'
import ProductionHouseServiesComponent from './components/ProductionHouseServiesComponent'



function App() {
  return (
   <Routes>
     <Route path='/' element={<MainHomeComponent/>}></Route>
    <Route path='/cetrin-home' element={<HomePageComponent/>}></Route>
    <Route path='/cetrin-about' element={<AboutComponent/>}></Route>
    <Route path='/cetrin-contact' element={<ContactComponent/>}></Route>
    <Route path='/cetrin-event' element={<EventComponent/>}></Route>
    <Route path='/cetrin-service' element={<ServiceComponent/>}></Route>
    
    <Route path='/cetrin-booknow' element={<BookComponent/>}></Route>
    <Route path='/cetrin-blog' element={<BlogComponent/>}></Route>
    <Route path='/cetrin-menu' element={<MenuComponent/>}></Route>
    <Route path='/cetrin-team' element={<TeamComponent/>}></Route>
    <Route path='/cetrin-testimonial' element={<TestimonialComponent/>}></Route>
    <Route path='/cetrin-image' element={<GalleryComponent/>}></Route>
    <Route path='/banquet-contactUs' element={<BanquetContactComponent/>}></Route>
    <Route path='/banquet-aboutUs' element={<BanquetAboutComponent/>}></Route>
    <Route path='/banquet-gallary' element={<BanquetGallaryComponent/>}></Route>
    <Route path='/banquet-home' element={<BanquetHomeComponent/>}></Route>
    <Route path='/banquet-blog' element={<BanquetBlogComponent/>}></Route>
    <Route path='/banquet-servies' element={<BanquetServiesComponent/>}></Route>


    <Route path='/manpower-home' element={<ManpowerHomeComponent/>}></Route>
    <Route path='/manpower-aboutus' element={<ManpowerAboutUsComponent/>}></Route>
    <Route path='/manpower-contact' element={<ManpowerContactComponent/>}></Route>
    <Route path='/manpower-service' element={<ManpowerServicesComponent/>}></Route>
    <Route path='/manpower-blog' element={<ManpowerBlogComponent/>}></Route>
    <Route path='/manpower-gallery' element={<ManpowerGalleryComponent/>}></Route>



    <Route path='/productionHouse-home' element={<ProductionHouseHomeComponent/>}></Route>
    <Route path='/productionHouse-servies' element={<ProductionHouseServiesComponent/>}></Route>
    <Route path='/productionHouse-about-us' element={<ProductionHouseAboutComponent/>}></Route>
    <Route path='/productionHouse-contact-us' element={<ProductionHouseContactComponent/>}></Route>
    <Route path='/productionHouse-blog' element={<ProductionHouseBlogComponent/>}></Route>
    <Route path='/productionHouse-teams' element={<ProductionHouseTeamComponent/>}></Route>
    <Route path='/productionHouse-gallery' element={<ProductionHouseGalleryComponent/>}></Route>


   </Routes>
  );
}

export default App;
