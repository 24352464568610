import React, { Component } from 'react'





class ManpowerNavBar extends Component {
  constructor(props) {
    super(props);


    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }



  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();


  }


  render() {
    return (
<div>

      <div className="container-fluid bg-secondary ps-5 pe-0 d-none d-lg-block">
        <div className="row gx-0">
          <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
            <div className="d-inline-flex align-items-center">
              {/* <a className="text-body py-2 pe-3 border-end" href><small>FAQs</small></a>
              <a className="text-body py-2 px-3 border-end" href><small>Support</small></a>
              <a className="text-body py-2 px-3 border-end" href><small>Privacy</small></a>
              <a className="text-body py-2 px-3 border-end" href><small>Policy</small></a>
              <a className="text-body py-2 ps-3" href><small>Career</small></a> */}
            </div>
          </div>
          <div className="col-md-6 text-center text-lg-end">
            <div className="position-relative d-inline-flex align-items-center bg-primary text-white top-shape px-5">
              <div className="me-3 pe-3 border-end py-2">
                <p className="m-0"  style={{color:'black'}} ><i className="fa fa-envelope-open me-2"  style={{color:'black'}}  />providenceinternational180@gmail.com</p>
              </div>
              <div className="py-2">
                <p className="m-0" style={{color:'black'}} ><i className="fa fa-phone-alt me-2"  style={{color:'black'}}  />9595264988</p>
              </div>
            </div>
          </div>
        </div>
      </div>
        
        {/* Navbar Start */ }
    <nav className="navbar navbar-expand-lg bg-white navbar-light shadow-sm px-5 py-3 py-lg-0">
      <a href="manpower-home" className="navbar-brand p-0">
        <h3 className="m-0 text-uppercase text-primary"><img src="/allimage/providene-logo.png" style={{width:'90px'}} ></img>Providence International</h3>
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        <span className="navbar-toggler-icon" />
      </button>
      <div className="collapse navbar-collapse" id="navbarCollapse">
        <div className="navbar-nav ms-auto py-0 me-n3">
          <a href="manpower-home" className="nav-item nav-link active">Home</a>
          <a href="manpower-aboutus" className="nav-item nav-link">About</a>
          <a href="manpower-service" className="nav-item nav-link">Service</a>
          {/* <a href="manpower-blog" className="nav-item nav-link" >Blog</a> */}
          <a href="manpower-gallery" className="nav-item nav-link" >Gallery</a>
          <a href="manpower-contact" className="nav-item nav-link">Contact</a>
        </div>
      </div>
    </nav>
</div>
    );

  }



}

export default ManpowerNavBar