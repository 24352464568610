import React, { Component } from 'react';
import NavBar from './NavBar';
import FooterComponent from './FooterComponent';
import NavBarBanquet from './NavBarBanquet';
import BanquetFooterComponent from './BanquetFooterComponent';
import emailjs from 'emailjs-com';
class BanquetHomeComponent extends Component {
  state= {
    name: '',
    email: '',
    mobileNumber: '',
    event:'',
    alertMessage: ''
  };

  componentDidMount() {
    // Initialize EmailJS
    emailjs.init('2LkXttzKAVGsn4L25');
  }

  handleSubmit = (event) => {
    event.preventDefault();
    const btn = document.getElementById('button');
    btn.value = 'Sending...';

    const serviceID = 'service_o02f64a';
    const templateID = 'template_zlkvznm';

    emailjs.sendForm(serviceID, templateID, event.target)
      .then(() => {
        btn.value = 'Send Email';
        alert('Sent!');
      })
      .catch((err) => {
        btn.value = 'Send Email';
        alert(JSON.stringify(err));
      });
  };
    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  

  render() {
    return (






      <div>
      {/* index-515:19*/}
      {/* Basic Page Needs ================================================== */}
      <meta charSet="utf-8" />
      {/* Mobile Specific Metas ================================================== */}
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />
      {/* Site Title */}
      <title>Exhibz - Conference &amp; Event HTML Template</title>
      {/* CSS
       ================================================== */}
      {/* Bootstrap */}
      <link rel="stylesheet" href="css/bootstrap.min.css" />
      {/* FontAwesome */}
      <link rel="stylesheet" href="css/font-awesome.min.css" />
      {/* Animation */}
      <link rel="stylesheet" href="css/animate.css" />
      {/* magnific */}
      <link rel="stylesheet" href="css/magnific-popup.css" />
      {/* carousel */}
      <link rel="stylesheet" href="css/owl.carousel.min.css" />
      {/* isotop */}
      <link rel="stylesheet" href="css/isotop.css" />
      {/* ico fonts */}
      <link rel="stylesheet" href="css/xsIcon.css" />
      {/* Template styles*/}
      <link rel="stylesheet" href="css/style.css" />
      {/* Responsive styles*/}
      <link rel="stylesheet" href="css/responsive.css" />
      {/* HTML5 shim and Respond.js IE8 support of HTML5 elements and media queries */}
      {/* WARNING: Respond.js doesn't work if you view the page via file:// */}
      {/*[if lt IE 9]>
    
    
    <![endif]*/}
      <div className="body-inner">
        {/* Header start */}
       <NavBarBanquet></NavBarBanquet>
        {/*/ Header end */}
        {/* banner start*/}
        <section className="hero-area hero-speakers">
          <div className="banner-item overlay" style={{backgroundImage: 'url(allimage/eventhall.jpg)'}}>
            <div className="container">
              <div className="row">
                <div className="col-lg-7">
                  <div className="banner-content-wrap">
                    <p className="banner-info wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms">
                    </p>
                    <h1 className="banner-title wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="700ms">Providence Divine Banquet Hall: Where Dreams Become Celebrations</h1>
                  </div>
                  {/* Banner content wrap end */}
                </div>{/* col end*/}
                <div className="col-lg-4 offset-lg-1">
                  <div className="hero-form-content">
                    <h2>Book Now</h2>
                    <p style={{color:'black'}}>
                      Book Your Dream Events Now
                    </p>
                    <form id="form" onSubmit={this.handleSubmit} className="hero-form">
                      <input className="form-control form-control-name" placeholder="Name" name="name" id="name"  required type="text"  />
                      <input className="form-control form-control-phone" placeholder="Phone" name="mobileNumber" id="mobileNumber"  required type="number" />
                      <input className="form-control form-control-email" placeholder="Email" name="email" id="email"  required type="email"/>
                      <select name="event" id="event" required>
                        <option value="Event Type">Event Type</option>
                        <option value="Birthday Party">Birthday Party</option>
                        <option value="Ring Ceremony">Ring Ceremony</option>
                        <option value="Corporate Party">Corporate Party</option>
                        <option value="Cocktail Party">Cocktail Party</option>
                        <option value="Kitty Party">Kitty Party</option>
                        <option value="Wedding Ceremony">Wedding Ceremony</option>
                      </select>
                      <button id="button" className="btn" type="submit"> Book Now</button>
                    </form>{/* form end*/}
                  </div>{/* hero content end*/}
                </div>{/* col end*/}
              </div>{/* row end*/}
            </div>
            {/* Container end */}
          </div>
        </section>
        {/* banner end*/}
        {/* ts intro start */}
        <section className="ts-event-outcome event-intro">
          <div className="container">
            <div className="row">
              <div className="col-lg-4">
                <div className>
                  <div className="outcome-content ts-exp-content">
                    <h2 className="column-title">
                      <span>Event Outcomes</span>
                      Elevate Your Celebrations...!!!
                    </h2>
                    <p  style={{color:'black'}}>
                      Discover the magic of hosting your events at our banquet hall. Whether it's a wedding, corporate gathering,
                      or milestone celebration, we ensure a seamless and memorable experience for you and your guests.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <style dangerouslySetInnerHTML={{__html: "\n                     .outcome-img {\n                        height: 300px; /* Set the desired fixed height */\n                        overflow: hidden;\n                     }\n                  \n                     .outcome-img img {\n                        width: auto;\n                        height: 100%;\n                        object-fit: cover;\n                        display: block;\n                     }\n                  " }} />
                <div className="outcome-content">
                  <div className="outcome-img overlay">
                    <img className src="allimage/408124581_7119952231402927_2781605281728290471_n.jpg" alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="outcome-content">
                  <div className="outcome-img overlay">
                    <img className src="allimage/408184348_7119952491402901_4196788688121796127_n.jpg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* ts intro end*/}
        {/* ts funfact start*/}
        <section className="ts-funfact" style={{backgroundImage: 'url(allimage/eventback.jpg)'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={43}>900</span>+</h3>
                  <h4 className="funfact-title">Our Happy Customers
                  </h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={62}>100+</span></h3>
                  <h4 className="funfact-title">Events</h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={28}>50+</span>+</h3>
                  <h4 className="funfact-title">5 Star Rating
                  </h4>
                </div>
              </div>{/* col end*/}
              <div className="col-lg-3 col-md-6">
                <div className="ts-single-funfact">
                  <h3 className="funfact-num"><span className="counterUp" data-counter={950}>750</span>+</h3>
                  <h4 className="funfact-title">Feedbacks
                  </h4>
                </div>
              </div>{/* col end*/} 
            </div>{/* row end*/}
          </div>{/* container end*/}
        </section>
        {/* ts funfact end*/}
        {/* ts speaker start*/}
        <section id="ts-speakers" className="ts-speakers" style={{backgroundImage: 'url(allimage/speakers/speaker_bg.png)'}}>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 mx-auto">
                <h2 className="section-title text-center">
                  <span>Our Services
                  </span></h2>
              </div>{/* col end*/}
            </div>{/* row end*/}
            <style dangerouslySetInnerHTML={{__html: "\n               .ts-speaker .speaker-img {\n                 position: relative;\n                 overflow: hidden;\n                 width: 150px; /* Set your desired size */\n                 height: 150px; /* Set your desired size */\n                 border-radius: 100%; /* This creates a circular shape */\n               }\n             \n               .ts-speaker .speaker-img img {\n                 width: 100%;\n                 height: 100%;\n                 border-radius: inherit; /* Ensure the image follows the circular shape */\n               }\n             " }} />
            <div className="row">
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="400ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/cater.jpg" alt="" />
                    <a href="#popup_1" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">All Kinds of Catering Services</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_1" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/cater.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">All Kinds of Catering Services Available
                        </h3>
                        <p>
                          "Indulge your senses in a culinary experience like no other at our event hall, where exceptional catering meets exquisite ambiance. Our dedicated team is passionate about creating memorable moments through the art of gastronomy. From intimate gatherings to grand celebrations, our event hall offers a versatile space to cater to your unique needs. Immerse yourself in a world of delectable flavors, personalized menus, and impeccable service, making your event a feast for both the senses and the soul.
                          Elevate your occasions with the perfect blend of culinary mastery and welcoming hospitality."
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="500ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/birthday.jpg" alt="" />
                    <a href="#popup_2" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Birthday Party</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_2" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/birthday.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Birthday Party
                        </h3>
                        <p>
                          "Discover the perfect venue for an unforgettable birthday celebration at our event hall. Our space is thoughtfully designed to host joyous occasions, providing a vibrant and welcoming atmosphere for your birthday party. With flexible seating arrangements, state-of-the-art amenities, and a dedicated team ready to assist, we ensure that every detail is tailored to your preferences. 
                          Make your special day even more memorable with our versatile event hall, creating lasting memories for you and your guests."
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="600ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/ring.jpg" alt="" />
                    <a href="#popup_3" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Ring Ceremony</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_3" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/ring.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Ring Ceremony</h3>
                        <p>
                          Celebrate the union of love and commitment at our enchanting event hall designed for the perfect ring ceremony. Nestled in an ambiance of elegance and warmth, our venue sets the stage for a magical celebration of your love story. With meticulous attention to detail and a touch of sophistication, we create an intimate and memorable atmosphere for this special occasion. Our dedicated team ensures that every moment is adorned with grace, making your ring ceremony an unforgettable step in your journey together.
                          Cherish the beauty of this significant milestone surrounded by the love and joy of your closest friends and family.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="700ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/corpora.jpg" alt="" />
                    <a href="#popup_4" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Corporate Party</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_4" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/corpora.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Corporate Party</h3>
                        <span className="speakder-designation">Cheif Architecture</span>
                        <p>
                          Elevate your corporate events to new heights at our sophisticated event hall. Ideal for hosting corporate parties, our venue provides a refined and professional setting for your business gatherings. With spacious and customizable facilities, we cater to your specific needs, ensuring a seamless and successful corporate event. Our dedicated team is committed to delivering top-notch service, from impeccable event planning to flawless execution. Impress your colleagues and clients with a stylish and upscale ambiance, fostering a conducive environment for networking, collaboration, and celebration. 
                          Experience the perfect blend of professionalism and elegance at our corporate party venue.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="800ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/cocktaqil.jpg" alt="" />
                    <a href="#popup_5" className="view-speaker  ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Cocktail Party</a></h3>
                    &gt;
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_5" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/cocktaqil.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Cocktail Party</h3>
                        <p>
                          Sip on expertly crafted cocktails and savor delectable hors d'oeuvres in an ambiance designed to enhance socializing and enjoyment. Our professional staff ensures seamless service, allowing you to relax and mingle with your guests. Elevate your cocktail party experience with us, where sophistication meets celebration.
                          Cheers to unforgettable moments and a night of indulgence in our stylish event hall!
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/kitty.jpg" alt="" />
                    <a href="#popup_6" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Kitty Party</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_6" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/kitty.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Kitty Party</h3>
                        <p>
                          Elevate your kitty party experience in our exquisite event hall designed for the perfect blend of elegance and enjoyment. Our venue offers a charming and vibrant setting, ideal for hosting lively kitty parties. Whether you're catching up with friends, playing games, or indulging in delicious treats, our versatile space caters to all your kitty party needs.
                          Immerse yourself in the inviting ambiance, adorned with stylish decor and comfortable seating arrangements. Our dedicated staff ensures that your kitty party is a seamless and enjoyable affair. From themed decorations to delectable refreshments, we strive to create a memorable experience for you and your guests.
                          Celebrate the joy of togetherness and create lasting memories with your kitty party at our event hall. Unwind, socialize, and make every moment count in a space tailored for a delightful kitty party celebration
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1000ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/wedding.jpg" alt="" />
                    <a href="#popup_7" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Wedding Ceremony</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_7" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/wedding.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Wedding Ceremony</h3>
                        <p>
                          Embark on your journey of love and celebrate the beginning of a beautiful chapter at our enchanting event hall, meticulously designed for the perfect wedding ceremony. Immerse yourself in the romantic ambiance of our venue, where every detail is crafted to make your special day truly unforgettable.
                          Exchange your vows in a setting adorned with timeless elegance, surrounded by the love and warmth of your dear ones. Our event hall provides a versatile and captivating backdrop for both intimate ceremonies and grand celebrations. From stunning decor to personalized services, we are committed to turning your wedding dreams into a breathtaking reality.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="1100ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src=" allimage/dj.jpg" alt="" />
                    <a href="#popup_8" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Best DJ &amp; Sounds</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_8" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src=" allimage/dj.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Best DJ &amp; Sounds</h3>
                        <p>
                          Elevate your event with the best DJ and sound services that will set the perfect tone for an unforgettable experience. Our professional DJ services are tailored to cater to your musical preferences, ensuring a dynamic and energetic atmosphere for your celebration.
                          Here's what sets our DJ and sound services apart:
                          Versatility: Our DJs are well-versed in a wide range of music genres, accommodating diverse tastes and preferences. Whether you're into the latest hits, timeless classics, or specific genres, our DJs have you covered.
                          State-of-the-Art Equipment: We invest in top-notch sound and lighting equipment to deliver a premium audio-visual experience. Expect crystal-clear sound and vibrant lighting that enhances the overall ambiance of your event.
                          Customized Playlists: Tailor the soundtrack of your event to reflect your style and the mood you want to create. Our DJs work closely with you to curate playlists that resonate with your vision, ensuring a personalized and memorable musical journey.</p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/baby.jpg" alt="" />
                    <a href="#popup_baby_shower" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Baby Shower</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_baby_shower" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/baby.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Baby Shower</h3>
                        <p>
                          Celebrate the joyous occasion of welcoming a new life with our exquisite event hall specially designed for unforgettable baby showers. Our venue offers a charming and comfortable setting, perfect for creating lasting memories with family and friends.
                          Immerse yourself in the delightful ambiance, adorned with lovely decorations and cozy seating arrangements. Our dedicated staff ensures that your baby shower is a seamless and enjoyable affair. From themed decor to delightful refreshments, we strive to make your baby shower a truly special and memorable event.
                          Share laughter, joy, and anticipation in a space tailored to host heartwarming baby shower celebrations. Let us be a part of your journey as you welcome the newest member of your family.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/retieme.jpg" alt="" />
                    <a href="#popup_retirement_party" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Retirement Party</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_retirement_party" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/retieme.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Retirement Party</h3>
                        <p>
                          Celebrate a well-deserved retirement in our elegant event hall designed for memorable retirement parties. Our venue provides a sophisticated and welcoming atmosphere, perfect for honoring years of dedication and hard work.
                          Immerse yourself in the celebratory ambiance, adorned with tasteful decorations and comfortable seating arrangements. Our dedicated staff ensures that your retirement party is a seamless and enjoyable affair. From personalized touches to delicious refreshments, we aim to make your retirement celebration truly special.
                          Share stories, laughter, and best wishes in a space tailored to host meaningful retirement parties. Let us be a part of your milestone as you embark on a new chapter of relaxation and fulfillment.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/awar cerem.jpg" alt="" />
                    <a href="#popup_award_ceremony" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Award Ceremony</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_award_ceremony" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/awar cerem.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Award Ceremony</h3>
                        <p>
                          Elevate your awards ceremony experience in our grand event hall designed to honor achievements and celebrate excellence. Our venue provides a prestigious and glamorous setting, perfect for recognizing and applauding accomplishments.
                          Immerse yourself in the celebratory ambiance, adorned with luxurious decorations and VIP seating arrangements. Our dedicated staff ensures that your award ceremony is a seamless and distinguished affair. From red carpet entrances to gourmet refreshments, we aim to make your awards night truly memorable.
                          Recognize outstanding contributions, achievements, and success in a space tailored to host sophisticated award ceremonies. Let us be a part of your special occasion as you acknowledge and honor excellence.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/cultura.jpg" alt="" />
                    <a href="#popup_cultural_religious_event" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Cultural &amp; Religious Event</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_cultural_religious_event" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/cultura.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Cultural &amp; Religious Event</h3>
                        <p>
                          Immerse yourself in the rich traditions and spirituality of cultural and religious events hosted in our versatile event hall. Our venue is designed to provide a sacred and inclusive space for cultural celebrations, religious ceremonies, and spiritual gatherings.
                          Experience the vibrancy of cultural events with themed decorations, traditional music, and engaging activities. For religious ceremonies, our serene ambiance and respectful setting create an atmosphere for meaningful worship and reflection.
                          Our dedicated staff ensures that your cultural and religious events are conducted with reverence and attention to detail. From cultural performances to religious rituals, our event hall is adaptable to various traditions and practices.
                          Celebrate diversity, spirituality, and community in a space tailored for cultural and religious events. Let us be a part of your sacred moments and cultural festivities.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src=" allimage/holi.jpg" alt="" />
                    <a href="#popup_holiday_party" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Holiday Party</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_holiday_party" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src=" allimage/holi.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Holiday Party</h3>
                        <p>
                          Celebrate the festive season in style at our event hall designed for unforgettable holiday parties. Our venue provides a joyful and warm atmosphere, perfect for bringing together friends, family, and colleagues to celebrate the holidays.
                          Immerse yourself in the holiday spirit with festive decorations, seasonal music, and a cheerful ambiance. Our dedicated staff ensures that your holiday party is filled with merriment and delightful moments. From themed decor to delectable treats, we aim to make your holiday celebration truly special.
                          Share laughter, exchange gifts, and enjoy the magic of the holidays in a space tailored for joyful holiday parties. Let us be a part of your festive traditions and create memories that last a lifetime.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/work'.jpg" alt="" />
                    <a href="#popup_training_workshop" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Training Workshop</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_training_workshop" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/work'.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Training Workshop</h3>
                        <p>
                          Elevate your training sessions and workshops in our well-equipped event hall designed to facilitate interactive and productive learning experiences. Our venue provides a conducive environment for professional development, skill enhancement, and knowledge sharing.
                          Immerse yourself in the focused ambiance, equipped with modern amenities and comfortable seating arrangements. Our dedicated staff ensures that your training workshop is conducted seamlessly, with all the necessary support for a successful learning event.
                          From audio-visual equipment to customizable setups, our event hall is adaptable to various training needs. Host engaging workshops, enhance skills, and foster collaboration in a space tailored for impactful training sessions. Let us be a part of your journey towards continuous learning and development.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/exihition.webp" alt="" />
                    <a href="#popup_exhibition_expo" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Exhibition &amp; Expo</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_exhibition_expo" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/exihition.webp" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Exhibition &amp; Expo</h3>
                        <p>
                          Showcase your products and innovations in our expansive event hall designed for successful exhibitions and expos. Our venue provides a dynamic and flexible space for businesses and organizations to engage with their target audience, network, and make lasting impressions.
                          Immerse yourself in the vibrant ambiance, equipped with modern facilities and customizable booth setups. Our dedicated staff ensures that your exhibition or expo is executed seamlessly, offering support for logistics and event coordination.
                          From product launches to networking opportunities, our event hall is tailored to host impactful exhibitions. Elevate your brand presence, connect with potential clients, and create memorable experiences in a space designed for successful expos. Let us be a part of your showcase and bring your vision to life.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
              <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-duration="1.5s" data-wow-delay="900ms">
                <div className="ts-speaker">
                  <div className="speaker-img">
                    <img className="img-fluid" src="allimage/dance.jpg" alt="" />
                    <a href="#popup_dance_entertainment" className="view-speaker ts-image-popup" data-effect="mfp-zoom-in">
                      <i className="icon icon-plus" />
                    </a>
                  </div>
                  <div className="ts-speaker-info">
                    <h3 className="ts-title"><a href="#">Dance &amp; Entertainment</a></h3>
                  </div>
                </div>
                {/* popup start*/}
                <div id="popup_dance_entertainment" className="container ts-speaker-popup mfp-hide">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-img">
                        <img src="allimage/dance.jpg" alt="" />
                      </div>
                    </div>{/* col end*/}
                    <div className="col-lg-6">
                      <div className="ts-speaker-popup-content">
                        <h3 className="ts-title">Dance &amp; Entertainment</h3>
                        <p>
                          Immerse yourself in the world of dance and entertainment in our lively event hall designed for electrifying performances and memorable experiences. Our venue provides a dynamic and versatile space for dance shows, concerts, and entertainment events.
                          Experience the vibrant ambiance, equipped with state-of-the-art sound and lighting systems to enhance your entertainment experience. Our dedicated staff ensures that your dance and entertainment event is filled with energy and excitement, offering support for seamless performances.
                          From dance competitions to live concerts, our event hall is tailored to host thrilling entertainment experiences. Bring your artistic vision to life, captivate your audience, and create lasting memories in a space designed for electrifying performances. Let us be a part of your entertainment journey.
                        </p>
                      </div>{/* ts-speaker-popup-content end*/}
                    </div>{/* col end*/}
                  </div>{/* row end*/}
                </div>{/* popup end*/}
              </div> {/* col end*/}
            </div>{/* row end*/}
          </div>{/* container end*/}
        </section>
        {/* ts experience end*/}
        {/* ts sponsors end*/}
        {/* ts map direction start*/}
        <BanquetFooterComponent></BanquetFooterComponent>

        {/* ts map direction end*/}
        {/* ts footer area start*/}
        
        {/* ts footer area end*/}
        {/* Javascript Files
          ================================================== */}
        {/* initialize jQuery Library */}
        {/* Bootstrap jQuery */}
        {/* Counter */}
        {/* Countdown */}
        {/* magnific-popup */}
        {/* carousel */}
        {/* Waypoints */}
        {/* isotop */}
        {/* Template custom */}
      </div>
      {/* Body inner end */}
      {/* index-515:53*/}
    </div>
  );
}
}
export default BanquetHomeComponent;
