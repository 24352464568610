import React, { Component } from 'react'





class NavBarBanquet extends Component {
  constructor(props) {
    super(props);


    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }



  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    this.form.validateAll();


  }


  render() {
    return (
 

        // <div className="container-fluid nav-bar" style={{ marginLeft: '-5%' }}>
        //   <div className="container">
        //     <nav className="navbar navbar-light navbar-expand-lg py-4">
        //       <li class="nav-item nav-link">
        //         <a href="/index." className="navbar-brand" >
        //           <h1 className="text-primary fw-bold mb-0" >Providence<span className="text-dark">International</span> </h1>

        //         </a></li>
        //       <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
        //         <span className="fa fa-bars text-primary" />
        //       </button>
        //       <div className="collapse navbar-collapse" id="navbarCollapse">
        //         <div className="navbar-nav mx-auto">
        //           <li class="nav-item nav-link">
        //             <a href="/" className="nav-item nav-link active">Home</a></li>
        //           <li className="nav-item nav-link">
        //             <a className="nav-item nav-link" href="/about">About</a>
        //           </li>
        //           <li class="nav-item nav-link">
        //             <a href="/service" className="nav-item nav-link">Services</a>  </li>
        //           <li class="nav-item nav-link">
        //             <a href="/event" className="nav-item nav-link">Events</a>  </li>
        //           <li class="nav-item nav-link">
        //             <a href="/menu" className="nav-item nav-link">Menu</a>  </li>
        //           <div className="nav-item dropdown">
        //             <li class="nav-item nav-link">
        //               <a href="/#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>  </li>
        //             <div className="dropdown-menu bg-light">
        //             <li class="nav-item nav-link">
        //             <a href="/image" className="dropdown-item">Gallery</a>  </li>
                
        //               <li class="nav-item nav-link">
        //                 <a href="/booknow" className="dropdown-item">Booking</a>  </li>
        //               <li class="nav-item nav-link">
        //                 <a href="/blog" className="dropdown-item">Our Blog</a>  </li>
        //               <li class="nav-item nav-link">
        //                 <a href="/team" className="dropdown-item">Our Team</a>  </li>
        //               <li class="nav-item nav-link">
        //                 <a href="/testimonial" className="dropdown-item">Testimonial</a>  </li>
        //               <li class="nav-item nav-link">
        //                 <a href="/404" className="dropdown-item">404 Page</a>  </li>
        //             </div>
        //           </div>
        //           <li class="nav-item nav-link">
        //             <a href="/contact" className="nav-item nav-link">Contact</a>  </li>
                   
        //         </div>
        //         <button className="btn-search btn btn-primary btn-md-square me-4 rounded-circle d-none d-lg-inline-flex" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fas fa-search" /></button>
        //         <a href="/booknow"className="btn btn-primary py-2 px-4 d-none d-xl-inline-block rounded-pill">Book Now</a>
        //       </div>
        //     </nav>
        //   </div>
        // </div>

        <header id="header" className="header header-transparent">
        <div className="container">
          <nav className="navbar navbar-expand-lg navbar-light">
            {/* logo*/}
            <a className="navbar-brand" href="/banquet-home">
              <img src="/allimage/providene-logo-with-name copy.png"  style={{width:'200px'}}  alt="" />
            </a>
            <button className="navbar-toggler py-2 px-3" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="fa fa-bars text-primary" />
            </button>
            <div className="collapse navbar-collapse"  id="navbarCollapse">
              <ul className="navbar-nav ml-auto">
                <li className="dropdown nav-item active">
                  <a href="/banquet-home">Home </a>
                 
                </li>
                <li className="dropdown nav-item">
                  <a href="/banquet-aboutUs" >About Us </a>
                  {/* <ul className="dropdown-menu" role="menu">
                    <li><a href="about-us.html">About Us</a></li>
                    <li><a href="gallery.html">Gallery</a></li>
                    <li><a href="faq.html">FAQ</a></li>
                    <li><a href="pricing.html">Pricing Table</a></li>
                    <li><a href="sponsors-1.html">Sponsors</a></li>
                    <li><a href="venue.html">Venue</a></li>
                    <li><a href="404.html">Erro Page</a></li>
                  </ul> */}
                </li>
                <li className="nav-item dropdown">
                  <a href="/banquet-gallary" >Gallery</a>
                  
                </li>
                <li className="nav-item dropdown">
                  <a href="/banquet-servies" >Service </a>
                  
                </li>
            
                <li className="nav-item">
                  <a href="/banquet-contactUs">Contact</a>
                </li>
                <li className="header-ticket nav-item">
                  <a className="ticket-btn btn" href="/banquet-contactUs"> Book Now
                  </a>
                </li>
              </ul>
            </div>
          </nav>
        </div>{/* container end*/}
      </header>
  
    );
  }



}

export default NavBarBanquet