import React, { Component } from 'react';
import ManpowerNavBar from './ManpowerNavBar';

class ManpowerBlogComponent  extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: "",
      email: "",
      password: "",
      role: "ROLE_DEALER",
      successful: false,
      message: ""
    };
  }

  onChangeUsername(e) {
    this.setState({
      username: e.target.value
    });
  }

  onChangeEmail(e) {
    this.setState({
      email: e.target.value
    });
  }

  onChangePassword(e) {
    this.setState({
      password: e.target.value
    });
  }

  handleRegister(e) {
    e.preventDefault();

    this.setState({
      message: "",
      successful: false
    });

    // Assuming you have a form reference named 'form'
    // this.form.validateAll();
  }

  render() {
    return (
      <div>
      <meta charSet="utf-8" />
      <title>CONSULT - Consultancy Website Template</title>
      <meta content="width=device-width, initial-scale=1.0" name="viewport" />
      <meta content="Free HTML Templates" name="keywords" />
      <meta content="Free HTML Templates" name="description" />
      {/* Favicon */}
      <link href="img/favicon.ico" rel="icon" />
      {/* Google Web Fonts */}
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin />
      <link href="https://fonts.googleapis.com/css2?family=Barlow:wght@500;600;700&family=Open+Sans:wght@400;600&display=swap" rel="stylesheet" /> 
      {/* Icon Font Stylesheet */}
      <link href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.10.0/css/all.min.css" rel="stylesheet" />
      <link href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.4.1/font/bootstrap-icons.css" rel="stylesheet" />
      {/* Libraries Stylesheet */}
      <link href="lib/owlcarousel/assets/owl.carousel.min.css" rel="stylesheet" />
      {/* Customized Bootstrap Stylesheet */}
      <link href="css/bootstrap.min.css" rel="stylesheet" />
      {/* Template Stylesheet */}
      <link href="css/style.css" rel="stylesheet" />
      <div>
     <ManpowerNavBar></ManpowerNavBar>
        {/* Navbar End */}
        {/* Page Header Start */}
        <div className="container-fluid bg-dark p-5">
          <div className="row">
            <div className="col-12 text-center">
              <h1 className="display-4 text-white">Blog Grid</h1>
              <a href>Home</a>
              <i className="far fa-square text-primary px-2" />
              <a href>Blog Grid</a>
            </div>
          </div>
        </div>
        {/* Page Header End */}
        {/* Blog Start */}
        <div className="container-fluid py-6 px-5">
          <div className="row g-5">
            {/* Blog list Start */}
            <div className="col-lg-8">
              <div className="row g-5">
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-12 col-md-6">
                  <div className="blog-item">
                    <div className="position-relative overflow-hidden">
                      <img className="img-fluid" src="img/blog-1.jpg" alt="" />
                    </div>
                    <div className="bg-secondary d-flex">
                      <div className="flex-shrink-0 d-flex flex-column justify-content-center text-center bg-primary text-white px-4">
                        <span>01</span>
                        <h5 className="text-uppercase m-0">Jan</h5>
                        <span>2045</span>
                      </div>
                      <div className="d-flex flex-column justify-content-center py-3 px-4">
                        <div className="d-flex mb-2">
                          <small className="text-uppercase me-3"><i className="bi bi-person me-2" />Admin</small>
                          <small className="text-uppercase me-3"><i className="bi bi-bookmarks me-2" />Web Design</small>
                        </div>
                        <a className="h4" href>Magna sea dolor ipsum amet lorem eos</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <nav aria-label="Page navigation">
                    <ul className="pagination pagination-lg m-0">
                      <li className="page-item disabled">
                        <a className="page-link rounded-0" href="#" aria-label="Previous">
                          <span aria-hidden="true"><i className="bi bi-arrow-left" /></span>
                        </a>
                      </li>
                      <li className="page-item active"><a className="page-link" href="#">1</a></li>
                      <li className="page-item"><a className="page-link" href="#">2</a></li>
                      <li className="page-item"><a className="page-link" href="#">3</a></li>
                      <li className="page-item">
                        <a className="page-link rounded-0" href="#" aria-label="Next">
                          <span aria-hidden="true"><i className="bi bi-arrow-right" /></span>
                        </a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            {/* Blog list End */}
            {/* Sidebar Start */}
            <div className="col-lg-4">
              {/* Search Form Start */}
              <div className="mb-5">
                <div className="input-group">
                  <input type="text" className="form-control p-3" placeholder="Keyword" />
                  <button className="btn btn-primary px-4"><i className="bi bi-search" /></button>
                </div>
              </div>
              {/* Search Form End */}
              {/* Category Start */}
              
              {/* Category End */}
              {/* Recent Post Start */}
             
              {/* Recent Post End */}
              {/* Image Start */}
             
              {/* Image End */}
              {/* Tags Start */}
            
              {/* Tags End */}
              {/* Plain Text Start */}
              
              {/* Plain Text End */}
            </div>
            {/* Sidebar End */}
          </div>
        </div>
        {/* Blog End */}
        {/* Footer Start */}
        <div className="container-fluid bg-primary text-secondary p-5">
          <div className="row g-5">
            <div className="col-12 text-center">
              <h1 className="display-5 mb-4">Stay Update!!!</h1>
              <form className="mx-auto" style={{maxWidth: '600px'}}>
                <div className="input-group">
                  <input type="text" className="form-control border-white p-3" placeholder="Your Email" />
                  <button className="btn btn-dark px-4">Sign Up</button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-dark text-secondary p-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Quick Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog Post</a>
                <a className="text-secondary" href="#"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Popular Links</h3>
              <div className="d-flex flex-column justify-content-start">
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Home</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />About Us</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Our Services</a>
                <a className="text-secondary mb-2" href="#"><i className="bi bi-arrow-right text-primary me-2" />Latest Blog Post</a>
                <a className="text-secondary" href="#"><i className="bi bi-arrow-right text-primary me-2" />Contact Us</a>
              </div>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Get In Touch</h3>
              <p className="mb-2"><i className="bi bi-geo-alt text-primary me-2" />123 Street, New York, USA</p>
              <p className="mb-2"><i className="bi bi-envelope-open text-primary me-2" />info@example.com</p>
              <p className="mb-0"><i className="bi bi-telephone text-primary me-2" />+012 345 67890</p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h3 className="text-white mb-4">Follow Us</h3>
              <div className="d-flex">
                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#"><i className="fab fa-twitter fw-normal" /></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#"><i className="fab fa-facebook-f fw-normal" /></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle me-2" href="#"><i className="fab fa-linkedin-in fw-normal" /></a>
                <a className="btn btn-lg btn-primary btn-lg-square rounded-circle" href="#"><i className="fab fa-instagram fw-normal" /></a>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid bg-dark text-secondary text-center border-top py-4 px-5" style={{borderColor: 'rgba(256, 256, 256, .1) !important'}}>
          <p className="m-0">© <a className="text-secondary border-bottom" href="#">Your Site Name</a>. All Rights Reserved. Designed by <a className="text-secondary border-bottom" href="https://htmlcodex.com">HTML Codex</a></p>
        </div>
        {/* Footer End */}
        {/* Back to Top */}
        <a href="#" className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"><i className="bi bi-arrow-up" /></a>
        {/* JavaScript Libraries */}
        {/* Template Javascript */}
      </div></div>
  );
}
}
export default ManpowerBlogComponent ;
